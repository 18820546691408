import React, {Component} from 'react'
import DownloadFileButton from './DownloadFileButton'
import { connect } from 'react-redux'
import { Popup, Icon, Segment, Input } from 'semantic-ui-react'
import join from "../Functions/join";
import {modifyJoinPreview} from "../../Actions/Join/ModifyJoinPreview";
import {modifyJoinAddFileNames} from "../../Actions/Join/ModifyJoinAddFileNames";
import {modifyJoinRemoveDuplicates} from "../../Actions/Join/ModifyJoinRemoveDuplicates";
import totalFileSize from "../../Constants/totalFileSize";
import bytesToMB from "../../Constants/bytesToMB";
import pricing from "../../Constants/Pricing";
import ProBlockMessage from '../../SharedComponents/ProBlockMessage'
import {hasUserMergedWithinTimeframe} from "../../Constants/hasUserMergedWithinTimeframe";

class DownloadFileIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileName: '',
            downloadStatus: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateDownloadStatus = this.updateDownloadStatus.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "removeDuplicates"){
            join(this.props.joinFilePK,
                this.props.joinPkId,
                this.props.joinSheetRead,
                this.props.joinType,
                this.props.joinColumns,
                value,
                this.props.joinAddFileNames,
                this.props.joinEditOptions,
                true)

            this.props.modifyJoinRemoveDuplicates(value);
        }

        else if (name === "addFileNames"){
            join(this.props.joinFilePK,
                this.props.joinPkId,
                this.props.joinSheetRead,
                this.props.joinType,
                this.props.joinColumns,
                this.props.joinRemoveDuplicates,
                value,
                this.props.joinEditOptions,
                true)

            this.props.modifyJoinAddFileNames(value);
        }
        else{
            this.setState({
                [name]: value
            });
        }
    }

    updateDownloadStatus(downloadStatus){
        this.setState({
            downloadStatus: downloadStatus
        })
    }

    render() {
        if (this.props.joinPreview !== null) {
            const fileNameError = this.state.fileName.trim() === "";
            const fileNameErrorDesc = this.state.downloadStatus === 'fileNameError' && this.state.fileName.trim() === "" ? <span><small style={{color: 'red'}}>Please Enter a File Name</small><br/></span>: null
            const proBlock = (!this.props.proBlock && bytesToMB(totalFileSize(this.props.joinFileInformation)) > pricing.mbMax) || (!this.props.proBlock && hasUserMergedWithinTimeframe());

            let proBlockType = null;

            if (proBlock){
                if (bytesToMB(totalFileSize(this.props.joinFileInformation)) > pricing.mbMax && hasUserMergedWithinTimeframe()){
                    proBlockType = "both"
                }
                else if (bytesToMB(totalFileSize(this.props.joinFileInformation)) && !hasUserMergedWithinTimeframe()){
                    proBlockType = "fileSize"
                }
                else{
                    proBlockType = "timeFrame"
                }
            }

            return (
                <Segment raised>
                <div className="downloadFileIndex">
                    <h3 className="thinHeading downloadHeader">Download File</h3>
                    <div className="mergedFileNameDiv">
                        <h5 className="thinHeading">Merged File Name</h5>
                        <Input placeholder="Enter File Name"
                               onChange={this.handleInputChange}
                               value={this.state.fileName}
                               name="fileName"
                               error={fileNameError}
                               id="downloadFileName"
                        />
                        <br/>
                        {fileNameErrorDesc}
                    </div>
                    <div className="removeDuplicatesDiv">
                        <input type="checkbox"
                               name="removeDuplicates"
                               id="removeDuplicates"
                               onChange={this.handleInputChange}
                               checked={this.props.joinRemoveDuplicates}
                               style={{cursor: 'pointer'}}
                               className="downloadFileInputs"
                        />
                        <label htmlFor="removeDuplicates" style={{cursor: 'pointer'}}>
                            <span style={{paddingLeft: '7px', marginTop: '5px'}}>Remove Duplicates</span>
                            <Popup
                                trigger={<span style={{paddingLeft: '5px'}}><Icon name="info circle" /></span>}
                                content='Keep only the first record of duplicates after merge. This will show up in the downloaded file'
                                size='mini'
                            />
                        </label>
                        <br/>
                    </div>
                    <div className="downloadButtonDiv" style={{textAlign: 'center'}}>
                        <DownloadFileButton fileName={this.state.fileName}
                                            removeDuplicates={this.props.joinRemoveDuplicates}
                                            updateDownloadStatus={this.updateDownloadStatus}
                                            downloadStatus={this.state.downloadStatus}
                                            addFileNames={this.props.joinAddFileNames}
                                            proBlock={proBlock}
                        />
                        <small>Files are downloaded in .xlsx format</small>
                    </div>
                    <div className="proBlockMessage">
                        {proBlock ? <ProBlockMessage proBlockType={proBlockType}/> : null}
                    </div>
                </div>
                </Segment>
            )

        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    joinPreview: state.joinState.joinPreview,
    joinPkId: state.joinState.joinPkId,
    joinType: state.joinState.joinType,
    joinColumns: state.joinState.joinColumns,
    joinFilePK: state.joinState.joinFilePK,
    joinSheetRead: state.joinState.joinSheetRead,
    joinEditOptions: state.joinState.joinEditOptions,
    joinFileInformation: state.joinState.joinFileInformation,
    joinRemoveDuplicates: state.joinState.joinRemoveDuplicates,
    joinAddFileNames: state.joinState.joinAddFileNames,
    proBlock: state.mainState.proBlock
});

const mapActionsToProps = {
    modifyJoinPreview: modifyJoinPreview,
    modifyJoinRemoveDuplicates: modifyJoinRemoveDuplicates,
    modifyJoinAddFileNames: modifyJoinAddFileNames
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadFileIndex)