import React, {Component} from 'react'
import InfoPagesHero from '../../SharedComponents/InfoPagesHero'
import SupportForm from "./SupportForm";

class SupportIndex extends Component {
    componentDidMount(){
        document.title = "Support - Merge Spreadsheets"
    }

    render() {
        return (
            <div className="appSupportPage">
                <div id="aboutHero">
                    <h1>App Support</h1>
                </div>
                <SupportForm/>
            </div>
        )
    }
}

export default SupportIndex