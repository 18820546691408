import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'
import { connect } from 'react-redux'
import ModalApp from '../../../SharedComponents/Modal'

class ImageModal extends Component {
    render() {
        const headerQuestion = 'question' in this.props.helpFAQ ? this.props.helpFAQ.question : null;
        const headerAnswer = 'answer' in this.props.helpFAQ ? this.props.helpFAQ.answer : null;

        const img = <Image src={this.props.imageURL} size="large" />

        return (
            <ModalApp name="imageHelpModal"
                      header={headerQuestion}
                      size="medium"
                      body={
                          <div>
                          <br/>
                          <p>{headerAnswer}</p>
                          <br/>
                          {img}
                          </div>
                      }
            />
        )
    }
}

const mapStateToProps = (state) => ({
    imageURL: state.mainState.helpVideoURL,
    helpFAQ: state.mainState.helpFAQ
})

export default connect(mapStateToProps, )(ImageModal)