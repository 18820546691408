import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table } from 'semantic-ui-react'
import ColumnNameEdit from './ColumnNameEdit'

class ColumnName extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: false
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle(e){
        this.setState({
            edit: !this.state.edit
        })
    }

    render() {

        if (this.state.edit){
            return(
                <Table.HeaderCell>
                    <ColumnNameEdit idx={this.props.idx} toggle={this.toggle} />
                </Table.HeaderCell>
            )
        }
        else{
            return(
                <Table.HeaderCell onClick={this.toggle}>
                    {this.props.finalColumnNames[this.props.idx]['colName']}
                </Table.HeaderCell>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames
});

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(ColumnName)