import React, {Component} from 'react'
import {connect} from 'react-redux'
import EditOptions from './EditOptions'
import getExtension from "../../Constants/getExtension";

class EditFileBody extends Component {
    render() {
        const editID = this.props.editID;
        const fileInformation = this.props.fileInformation;

        let isCSV;
        let fileName;

        if (editID !== "all"){
            fileName = fileInformation[editID].name;
            isCSV = getExtension(fileName) === "csv" || getExtension(fileName) === "txt";
        }
        else{
            for (let pkID in fileInformation){
                fileName = fileInformation[pkID].name;
                isCSV = getExtension(fileName) === "csv" || getExtension(fileName) === "txt";
                break;
            }
        }

        return (
            <div className="editFileBody">
                <br/>
                <EditOptions isCSV={isCSV} />
                <br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.joinState.joinEditID,
    fileInformation: state.joinState.joinFileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EditFileBody)