import {MODIFY_JOIN_STATUS} from "../Actions/Join/ModifyJoinStatus";
import {MODIFY_JOIN_FILE_PK} from "../Actions/Join/ModifyJoinFilePK";
import {MODIFY_JOIN_FILE_INFORMATION} from "../Actions/Join/ModifyJoinFileInformation";
import {MODIFY_JOIN_SHEET_READ} from "../Actions/Join/ModifyJoinSheetRead";
import {MODIFY_ALL_JOIN_INFORMATION} from "../Actions/Join/ModifyAllJoinInformation";
import {MODIFY_JOIN_EDIT_ID} from "../Actions/Join/ModifyJoinEditID";
import {MODIFY_JOIN_EDIT_OPTIONS} from "../Actions/Join/ModifyJoinEditOptions";
import {MODIFY_JOIN_PK_ID} from "../Actions/Join/ModifyJoinPkId";
import {MODIFY_JOIN_TYPE} from "../Actions/Join/ModifyJoinType";
import {MODIFY_JOIN_COLUMNS} from "../Actions/Join/ModifyJoinColumns";
import {MODIFY_JOIN_PREVIEW} from "../Actions/Join/ModifyJoinPreview";
import {MODIFY_JOIN_FINAL_COLUMN_NAMES} from "../Actions/Join/ModifyJoinFinalColumnNames";
import {MODIFY_JOIN_FILE_MESSAGE} from "../Actions/Join/ModifyJoinFileMessage";
import {MODIFY_JOIN_REMOVE_DUPLICATES} from "../Actions/Join/ModifyJoinRemoveDuplicates";
import {MODIFY_JOIN_ADD_FILE_NAMES} from "../Actions/Join/ModifyJoinAddFileNames";
import {TOGGLE_IS_JOINING} from "../Actions/Join/ToggleIsJoining";
import {INIT_JOIN_DOWNLOAD_URL} from "../Actions/Join/InitJoinDownloadURL";
import {RESET_JOIN_STATE} from "../Actions/Join/ResetJoinState";
import {MODIFY_JOIN_TUTORIAL_INFO} from "../Actions/Join/ModifyJoinTutorialInfo";
import {MODIFY_JOIN_TUTORIAL_STEP} from "../Actions/Join/ModifyJoinTutorialStep";
import {TOGGLE_SHOW_JOIN_TUTORIAL} from "../Actions/Join/ToggleShowJoinTutorial";


function joinState(
    state = {
        joinFilePK: [],
        joinFileInformation: {},
        joinEditOptions: {},
        joinStatus: "upload",
        joinEditID: null,
        joinSheetRead: {},
        joinPkId: ["-1","-2"],
        joinType: '',
        joinColumns: {"file1": [-1], "file2": [-1]},
        joinPreview: null,
        joinFinalColumnNames: [],
        joinFileMessage: null,
        joinRemoveDuplicates: false,
        joinAddFileNames: false,
        isJoining: false,
        joinDownloadURL: null,
        showJoinTutorial: false,
        joinTutorialStep: 0,
        joinTutorialInfo: {},
    }, action
) {
    switch (action.type) {
        case MODIFY_JOIN_STATUS:
            return Object.assign({}, state, {
                joinStatus: action.mainState.joinStatus
            });
        case MODIFY_JOIN_FILE_PK:
            return Object.assign({}, state, {
                joinFilePK: action.mainState.joinFilePK
            });
        case MODIFY_JOIN_FILE_INFORMATION:
            return Object.assign({}, state, {
                joinFileInformation: action.mainState.joinFileInformation
            });
        case MODIFY_JOIN_SHEET_READ:
            return Object.assign({}, state, {
                joinSheetRead: action.mainState.joinSheetRead
            });
        case MODIFY_ALL_JOIN_INFORMATION:
            return Object.assign({}, state, {
                joinFileInformation: action.mainState.joinFileInformation,
                joinSheetRead: action.mainState.joinSheetRead
            });
        case MODIFY_JOIN_EDIT_ID:
            return Object.assign({}, state, {
                joinEditID: action.mainState.joinEditID
            });
        case MODIFY_JOIN_EDIT_OPTIONS:
            return Object.assign({}, state, {
                joinEditOptions: action.mainState.joinEditOptions
            });
        case MODIFY_JOIN_PK_ID:
            return Object.assign({}, state, {
                joinPkId: action.mainState.joinPkId
            });
        case MODIFY_JOIN_TYPE:
            return Object.assign({}, state, {
                joinType: action.mainState.joinType
            });
        case MODIFY_JOIN_COLUMNS:
            return Object.assign({}, state, {
                joinColumns: action.mainState.joinColumns
            });
        case MODIFY_JOIN_PREVIEW:
            return Object.assign({}, state, {
                joinPreview: action.mainState.joinPreview
            });
        case MODIFY_JOIN_FINAL_COLUMN_NAMES:
            return Object.assign({}, state, {
                joinFinalColumnNames: action.mainState.joinFinalColumnNames
            });
        case MODIFY_JOIN_FILE_MESSAGE:
            return Object.assign({}, state, {
                joinFileMessage: action.mainState.joinFileMessage
            });
        case MODIFY_JOIN_REMOVE_DUPLICATES:
            return Object.assign({}, state, {
                joinRemoveDuplicates: action.mainState.joinRemoveDuplicates
            });
        case MODIFY_JOIN_ADD_FILE_NAMES:
            return Object.assign({}, state, {
                joinAddFileNames: action.mainState.joinAddFileNames
            });
        case TOGGLE_IS_JOINING:
            return Object.assign({}, state, {
                isJoining: action.mainState.isJoining
            });
        case INIT_JOIN_DOWNLOAD_URL:
            return Object.assign({}, state, {
                joinDownloadURL: action.mainState.joinDownloadURL
            });
        case TOGGLE_SHOW_JOIN_TUTORIAL:
            return Object.assign({}, state, {
                showJoinTutorial: action.mainState.showJoinTutorial
            });
        case MODIFY_JOIN_TUTORIAL_STEP:
            return Object.assign({}, state, {
                joinTutorialStep: action.mainState.joinTutorialStep
            });
        case MODIFY_JOIN_TUTORIAL_INFO:
            return Object.assign({}, state, {
                joinTutorialInfo: action.mainState.joinTutorialInfo
            });
        case RESET_JOIN_STATE:
            return Object.assign({}, state, {
                joinFilePK: [],
                joinFileInformation: {},
                joinEditOptions: {},
                joinStatus: "upload",
                joinEditID: null,
                joinSheetRead: {},
                joinPkId: ["-1","-2"],
                joinType: '',
                joinColumns: {"file1": [0], "file2": [0]},
                joinPreview: null,
                joinFinalColumnNames: [],
                joinFileMessage: null,
                joinRemoveDuplicates: false,
                joinAddFileNames: false,
                isJoining: false,
                joinDownloadURL: null,
                showJoinTutorial: false,
                joinTutorialStep: 0,
                joinTutorialInfo: {}
            });
        default:
            return state
    }
}

export default joinState;

