import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Table} from 'semantic-ui-react'


class PreviewModalBody extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const editID = this.props.joinEditID
        const joinFileInformation = this.props.joinFileInformation
        const firstFileInformation = joinFileInformation[editID]
        const columnNames = firstFileInformation.columnNames
        const samples = firstFileInformation.samples

        let columnNameHeader = []
        for (let i=0;i<columnNames.length;i++){
            let columnName = columnNames[i]
            columnNameHeader.push(<Table.HeaderCell key={i}>{columnName}</Table.HeaderCell>)
        }

        let rows = []
        for (let i=0;i<samples.length;i++){
            let rowValues = samples[i]
            let rowValuesDisplay = []

            for (let j=0;j<rowValues.length;j++){
                let value = rowValues[j]
                rowValuesDisplay.push(<Table.Cell key={j}> {value}</Table.Cell>)
            }

            rows.push(<Table.Row key={i}>{rowValuesDisplay}</Table.Row>)
        }

        return (
            <div id="uploadFilesSampleTableContainer">
                <div id="uploadFilesSampleTable">
                <h5 style={{paddingTop:'2px'}}> <i>  Showing first {samples.length} rows </i></h5>
                <Table celled compact striped color={"blue"}>
                    <Table.Header>
                        <Table.Row>
                            {columnNameHeader}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {rows}
                    </Table.Body>
                </Table>
                </div>

            </div>

        )
    }
}



const mapStateToProps = (state) => ({
    joinEditID:state.joinState.joinEditID,
    joinFileInformation:state.joinState.joinFileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(PreviewModalBody)
