import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import copyArray from "../../Constants/copyArray";
import copyObject from "../../Constants/copyObject";
import {modifyJoinFilePK} from "../../Actions/Join/ModifyJoinFilePK";
import {modifyJoinStatus} from "../../Actions/Join/ModifyJoinStatus";
import {modifyJoinFileInformation} from "../../Actions/Join/ModifyJoinFileInformation";
import {modifyJoinEditOptions} from "../../Actions/Join/ModifyJoinEditOptions";
import {modifyJoinSheetRead} from "../../Actions/Join/ModifyJoinSheetRead";
import {modifyAllJoinInformation} from "../../Actions/Join/ModifyAllJoinInformation";
import API_Root from "../../Constants/API_Root";

class ImportSheetsButton extends Component {
    constructor(props) {
        super(props);

        this.importSheets = this.importSheets.bind(this);
    }

    importSheets(){
        // Check if there is any error
        let importSheets = false;
        let idxImport = [];

        for (let sheetIdx in this.props.sheetsChecked){
            if (this.props.sheetsChecked[sheetIdx]){
                importSheets = true;
                idxImport.push(parseInt(sheetIdx))
            }
        }

        if (importSheets){
            // Close the Import Sheets Modal
            this.props.toggleModal(null);
            // Open up the import sheets loading modal
            this.props.toggleModal('loadingModalImportSheets');

            // Open up WebSockets
            const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
            const socket = new WebSocket(websocketRoute + "ws/api/join-files/");

            let filePK = [];
            const sheetRead = this.props.sheetRead;

            // Create PKs for these sheets
            for (let i=0; i<idxImport.length; i++){
                const idx = idxImport[i];
                const id = this.props.pk.toString() + "_" + this.props.sheetNames[idx];

                filePK.push({id: id, pk: this.props.pk})
                sheetRead[id] = this.props.sheetNames[idx];
            }

            // Update the PKs for these sheets
            const filePKExtend = copyArray(this.props.filePK, filePK);

            this.props.modifyFilePK(filePKExtend);

            // Send the PKs and if any column names
            socket.onopen = (event) => {
                socket.send(JSON.stringify({
                    filePK: filePK,
                    sheetRead: this.props.sheetRead,
                }));
            };

            socket.onmessage = (event) => {
                const reply = JSON.parse(event.data);
                const status = reply['status'];

                this.props.modifyStatus(status);

                if (status === "merged"){
                    let fileInformation = JSON.parse(JSON.stringify(reply.fileInformation));
                    let sheetRead = JSON.parse(JSON.stringify(reply.sheetRead));

                    if (typeof this.props.fileInformation !== 'undefined'){
                        if (Object.entries(this.props.fileInformation).length !== 0 && this.props.fileInformation.constructor === Object){
                            fileInformation = copyObject(fileInformation, this.props.fileInformation);
                            sheetRead = copyObject(this.props.sheetRead, reply.sheetRead);
                        }
                    }

                    this.props.modifyAllInformation(fileInformation, sheetRead)

                    socket.close()
                    this.props.toggleModal(null);

                }
                else if (status === "error"){
                    socket.close()
                    this.props.toggleModal(null);
                    alert("There was a problem importing your sheets. Please try again")
                }

            }

        }
    }

    render() {
        return (
            <Button disabled={this.props.btnDisabled} color="blue" icon="plus" content="Import Sheets" onClick={this.importSheets}/>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.joinState.joinFileInformation,
    filePK: state.joinState.joinFilePK,
    editID: state.joinState.joinEditID,
    sheetRead: state.joinState.joinSheetRead,
    editOptions: state.joinState.joinEditOptions
})

const mapActionsToProps = {
    modifyFilePK: modifyJoinFilePK,
    modifyStatus: modifyJoinStatus,
    modifyFileInformation: modifyJoinFileInformation,
    modifyEditOptions: modifyJoinEditOptions,
    modifySheetRead: modifyJoinSheetRead,
    toggleModal: toggleModal,
    modifyAllInformation: modifyAllJoinInformation
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheetsButton)