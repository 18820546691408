import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import join from '../Functions/join'
import {modifyJoinPreview} from "../../Actions/Join/ModifyJoinPreview";
import {modifyJoinFinalColumnNames} from "../../Actions/Join/ModifyJoinFinalColumnNames";
import {modifyJoinFileMessage} from "../../Actions/Join/ModifyJoinFileMessage";
import {modifyJoinTutorialStep} from "../../Actions/Join/ModifyJoinTutorialStep";


class JoinButton extends Component {
    constructor(props) {
        super(props);

        this.joinFiles = this.joinFiles.bind(this);
        this.canJoin = this.canJoin.bind(this);
        this.errorMessage = this.errorMessage.bind(this);
        this.createMessage = this.createMessage.bind(this);
        this.dedupeAndFormatJoinColumns = this.dedupeAndFormatJoinColumns.bind(this);
        this.scrollDown = this.scrollDown.bind(this);
    }

    createMessage(){
        const filePK1 = this.props.joinPkId[0] === "-1" ? null : this.props.joinPkId[0];
        const filePK2 = this.props.joinPkId[1] === "-2" ? null : this.props.joinPkId[1];

        let fileName1 = "";
        let fileName2 = "";

        for (let i=0; i<this.props.joinFilePK.length; i++){
            if (this.props.joinFilePK[i].id === filePK1){
                fileName1 = this.props.joinFileInformation[filePK1].name;

                const isExcel1 = fileName1.split('.').pop() !== "csv" && fileName1.split('.').pop() !== "txt";

                if (isExcel1){
                    fileName1 += ": " + this.props.joinSheetRead[filePK1]
                }
            }

            if (this.props.joinFilePK[i].id === filePK2){
                fileName2 = this.props.joinFileInformation[filePK2].name;

                const isExcel2 = fileName2.split('.').pop() !== "csv" && fileName2.split('.').pop() !== "txt";

                if (isExcel2){
                    fileName2 += ":" + this.props.joinSheetRead[filePK2]
                }

            }
        }

        const joinTypeOptions = {
            'inner': 'INNER JOIN',
            'left': 'LEFT JOIN',
            'right': 'RIGHT JOIN',
            'outer': 'FULL JOIN'
        };

        const joinType = this.props.joinType === "" ? "" : joinTypeOptions[this.props.joinType];

        const dedupedColumns = this.dedupeAndFormatJoinColumns();

        const file1ColumnNames = this.props.joinFileInformation[filePK1].columnNames;
        const file2ColumnNames = this.props.joinFileInformation[filePK2].columnNames;

        let columnJoins = dedupedColumns.map(x => file1ColumnNames[x[0]] + "---" + file2ColumnNames[x[1]])

        return <div>
            <p style={{fontSize: '110%', marginBottom: '-2px'}}><i>{fileName1}</i> {joinType} <i>{fileName2}</i></p>
            <span style={{fontSize: '105%'}}>on <b>{columnJoins.join(", ")}</b></span></div>
    }

    dedupeAndFormatJoinColumns(){
        let checkPairs = [];
        let dedupedPairs = [];

        const joinLeftColumns = this.props.joinColumns['file1'];
        const joinRightColumns = this.props.joinColumns['file2'];

        for (let i=0; i<joinLeftColumns.length; i++){
            let joinLeftCol = joinLeftColumns[i];
            let joinRightCol = joinRightColumns[i];
            let pair = joinLeftCol.toString() + joinRightCol.toString();

            if (!(checkPairs.includes(pair))){
                dedupedPairs.push([joinLeftCol, joinRightCol]);
                checkPairs.push(pair)
            }
        }

        return dedupedPairs
    }

    canJoin(){
        let canJoin = false;
        if (this.props.joinPkId[0] !== "-1" && this.props.joinPkId[1] !== "-2" && this.props.joinType !== ""){
            canJoin = true;
        }

        return canJoin
    }
    scrollDown(){
        this.node.scrollIntoView({behavior: 'smooth'})

    }

    joinFiles(e){
        const joinFileMessage = this.createMessage();
        this.props.modifyJoinFileMessage(joinFileMessage);

        join(this.props.joinFilePK,
            this.props.joinPkId,
            this.props.joinSheetRead,
            this.props.joinType,
            this.props.joinColumns,
            this.props.joinRemoveDuplicates,
            this.props.joinAddFileNames,
            this.props.joinEditOptions,
            true)

        this.props.modifyJoinTutorialStep(6)
        // this.props.scrollDown()

    }

    errorMessage(){
        let error = null;

        if (!this.canJoin()){
            // error = "Select the following: ";
            let selectDrops = [];

            if (this.props.joinPkId[0] === "-1"){
                selectDrops.push("Left File")
            }

            if (this.props.joinPkId[1] === "-2"){
                selectDrops.push("Right File")
            }

            if (this.props.joinType === ""){
                selectDrops.push("Join Type")
            }

            const errorText = "Select the following before joining: " + selectDrops.join(", ")

            error = <small style={{color: 'red'}}><i>{errorText}</i></small>
        }

        return error
    }

    render(){
        return(
            <div style={{textAlign: 'center'}}>
                <br/><br/>
                <Button id="joinFilesPreviewButton" loading={this.props.isJoining} style={{height: '75%', width: '75%', fontSize: '125%'}} icon="th list" color='blue' content='Join Files'  disabled={!this.canJoin()} onClick={this.joinFiles} />
                <br/><br/>
                {this.errorMessage()}
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    joinPkId: state.joinState.joinPkId,
    joinType: state.joinState.joinType,
    joinColumns: state.joinState.joinColumns,
    joinFilePK: state.joinState.joinFilePK,
    joinSheetRead: state.joinState.joinSheetRead,
    joinEditOptions: state.joinState.joinEditOptions,
    joinFileInformation: state.joinState.joinFileInformation,
    joinRemoveDuplicates: state.joinState.joinRemoveDuplicates,
    joinAddFileNames: state.joinState.joinAddFileNames,
    isJoining: state.joinState.isJoining
})

const mapActionsToProps = {
    modifyJoinPreview: modifyJoinPreview,
    modifyJoinFinalColumnNames: modifyJoinFinalColumnNames,
    modifyJoinFileMessage: modifyJoinFileMessage,
    modifyJoinTutorialStep: modifyJoinTutorialStep
}

export default connect(mapStateToProps, mapActionsToProps)(JoinButton)