import React, {Component} from 'react'
import SignUpForm from './SignUpForm'

class SignUpIndex extends Component {
    componentDidMount(){
        document.title = "Sign Up - Merge Spreadsheets"
    }
    render() {
        return (
            <div className="row" style={{backgroundColor: '#0E4D92', minHeight: '100vh'}}>
                <div className="col-sm-3" />
                <div className="col-sm-6">
                    <br/><br/><br/><br/>
                    <SignUpForm />
                </div>
                <div className="col-sm-3" />
            </div>

        )
    }
}

export default SignUpIndex