import React, {Component} from 'react'
import {isEmbed} from "../Constants/isEmbed";

class LovePromoBanner extends Component {
    render() {
        if (isEmbed(window.location.href)){
            return null
        } else {
            return (
                <div style={{ backgroundColor: "#EE685F",
                    color: "white", padding: "8px", fontWeight: "700", textAlign: "center" }}>
                    {/*Want to chat with your spreadsheet to do Data Analysis? */}
                    <a href="https://www.lovespreadsheets.com/" target="_blank" rel="noopener noreferrer" style={{ color: "whitesmoke" }}>Click here to get data from all your data sources using AI (for free with our new tool)</a>
                </div>
            )
        }
    }
}

export default LovePromoBanner