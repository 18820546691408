import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import ExplainFeature from "./ExplainFeature";
import WhatIsJoin from "./Join/WhatIsJoin";
import SlideCounter from './SlideCounter';
import HowToJoin from './Join/HowToJoin'
import OtherFileTypes from "./Join/OtherFileTypes";
import FullTutorial from "./Join/FullTutorial";

const totalNumber = 4;

class JoinLandingBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            featureNumber: 0
        }

        this.changeFeatureNumber = this.changeFeatureNumber.bind(this);
    }

    changeFeatureNumber(e, changeFunc){
        const currFeatureNumber = this.state.featureNumber;
        let changedFeature = changeFunc(currFeatureNumber) % totalNumber;

        if (changedFeature < 0){
            changedFeature = totalNumber + changedFeature
        }

        this.setState({
            featureNumber: changedFeature
        })

    }

    render() {
        const currFeature = Math.abs(this.state.featureNumber);

        const features = {
            0: <ExplainFeature header="What is Join?" desc={<WhatIsJoin/>} mergeType="join"/>,
            1: <ExplainFeature header="How do I Join Files?" desc={<HowToJoin />} mergeType="join"/>,
            2: <ExplainFeature header="What if my Files are not Standard?" desc={<OtherFileTypes/>} mergeType="join"/>,
            3: <ExplainFeature header="I still don't understand how this works" desc={<FullTutorial/>} mergeType="join"/>,
        }

        const iconStyle = {
            fontSize: '250%',
        }

        return (
            <div className="row">
                <div className="col-sm-2">
                    <div className="linkStyle" style={{float: 'right', padding: '250px 0', marginRight: '25%'}}>
                        <Icon name="angle left"
                              onClick={(e) => this.changeFeatureNumber(e, x =>  x-1 )}
                              style={iconStyle}
                        />
                    </div>
                </div>
                <div className="col-sm-8">
                    {features[currFeature]}
                    <SlideCounter activeFeature={this.state.featureNumber} totalNumber={totalNumber}/>
                </div>
                <div className="col-sm-2">
                    <div className="linkStyle" style={{float: 'left', padding: '250px 0', marginLeft: '25%'}}>
                        <Icon name="angle right"
                              onClick={(e) => this.changeFeatureNumber(e, x => x+1 )}
                              style={iconStyle}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default JoinLandingBody