import React, {Component} from 'react'
import { Table, Radio, Icon } from 'semantic-ui-react'
import {connect} from "react-redux";
import getFileNameNoExtension from "../Constants/getFileNameNoExtension";


class StartRowOptions extends Component {
    constructor(props) {
        super(props);

        this.changeStartRow = this.changeStartRow.bind(this);
        this.clearRow = this.clearRow.bind(this);
    }


    changeStartRow(e, {value}){
        this.props.handleStartRowChange(value)
    }

    clearRow(e){
        this.props.handleStartRowChange("")
    }

    render() {
        const fileSample = this.props.tableSample;
        const columnNames = this.props.columnNames;

        const tableColumns = columnNames.map(x => <Table.HeaderCell>{x}</Table.HeaderCell>)

        const tableRows = fileSample.map((x, idx) =>
        <Table.Row>
            <Table.Cell>
                <Radio
                    slider
                    name={"startRow" + this.props.editID}
                    value={(idx + 1).toString()}
                    onChange={this.changeStartRow}
                    checked={this.props.startRow === (idx + 1).toString()}
                />
            </Table.Cell>
            {x.map(y => <Table.Cell>{y}</Table.Cell>)}
        </Table.Row>)

        return (
            <div id="editOptionsTable">
                <Table celled compact definition>
                    <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>
                                Start at this row
                                <span className="linkStyle" onClick={this.clearRow}>Clear</span>
                            </Table.HeaderCell>
                            {tableColumns}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tableRows}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation,
    editOptions: state.mainState.editOptions
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(StartRowOptions)