import React, {Component} from 'react'
import ModalApp from '../Modal'
import { Form, TextArea } from 'semantic-ui-react'
import FeedbackSubmitButton from './FeedbackSubmitButton'
import Rating from 'react-rating'
// const Rating = require('react-rating');

class FeedbackModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            feedback: '',
            rating: 0
        };

        this.onChange = this.onChange.bind(this);
        this.updateRating = this.updateRating.bind(this);
    }

    onChange(e){
        this.setState({
            feedback: e.target.value
        })
    }

    updateRating(e){
        this.setState({
            rating: e
        })
    }

    clearFeedback = () => this.setState({ feedback: '', rating: 0 })

    render() {
        const modalBody =
            <div className="row" style={{ paddingTop: "24px" }}>
                <div className="col-sm-1" />
                <div className="col-sm-10">
                    <h3 className="thinHeading">How did you find your Merge Spreadsheet experience?</h3>
                    <br/>
                    <div style={{color: 'purple'}}>
                        <Rating
                            emptySymbol="fa fa-star-o fa-2x"
                            fullSymbol="fa fa-star fa-2x"
                            initialRating={this.state.rating}
                            onClick={this.updateRating}
                        />
                    </div>
                    <i>Rate us from 1-5 stars</i>
                    <br/><br/><br/>
                    <Form>
                        <TextArea value={this.state.feedback} placeholder="What I liked/disliked about Merge Spreadsheets..." onChange={this.onChange}/>
                    </Form>
                    <br/><br/>
                    <div>
                        <FeedbackSubmitButton rating={this.state.rating} feedback={this.state.feedback} clearFeedback={this.clearFeedback}/>
                    </div>
                    <br/>
                    {
                        this.state.rating === 0 ? <div style={{ fontSize: "12px", color: "red" }}>
                                Please select a star rating
                        </div> : null
                    }
                    <br/><br/><br/><br/>
                </div>
                <div className="col-sm-1" />
            </div>;

        return (
            <div>
                <ModalApp name="feedbackModal"
                          header={null}
                          body={modalBody}
                          size="small"
                          noClose
                />
            </div>
        )
    }
}

export default FeedbackModal