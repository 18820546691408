import React, {Component} from 'react'
import {connect} from 'react-redux'
import ImportSheetsButton from './ImportSheetsButton'
import { Checkbox } from 'semantic-ui-react'
import isObjectEmpty from "../Constants/isObjectEmpty";

class ImportSheetsBody extends Component {
    constructor(props) {
        super(props);

        const checks = {};
        const sheetNames = this.props.fileInformation[this.props.editID]['sheetNames'];

        const sheetReadPK = this.props.sheetRead[this.props.pk.toString()];

        for (let i = 0; i < sheetNames.length; i++) {
            let sheetName = sheetNames[i];

            if (!sheetReadPK.includes(sheetName)) {
                checks[i.toString()] = false
            }
        }

        this.state = checks;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleCheckAll = this.toggleCheckAll.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    toggleCheckAll(e, { checked }){
        if (!isObjectEmpty(this.state)){
            let changedState = {};
            if (checked){
                for (let sheetName in this.state){
                    changedState[sheetName] = true
                }
            }
            else{
                for (let sheetName in this.state){
                    changedState[sheetName] = false
                }
            }

            this.setState(changedState)
        }

        this.props.toggleCheckAll(checked);
    }

    render() {
        const sheetNames = this.props.fileInformation[this.props.editID]['sheetNames'];
        const sheetReadPK = this.props.sheetRead[this.props.pk.toString()];

        let sheetOptions = [];
        let numReadSheets = 0;

        for (let i=0; i<sheetNames.length; i++){
            let sheetName = sheetNames[i];
            let name = i.toString();

            if (sheetReadPK.includes(sheetName)) {
                sheetOptions.push(
                    <div className="importSheetOptions" key={name}>
                        <h5 className="thinHeading" style={{color: 'green'}}><b>READ: </b><i>{sheetName}</i></h5>
                    </div>
                )

                numReadSheets += 1;
            }
            else {
                sheetOptions.push(
                    <div className="importSheetOptions" key={name}>
                        <input style={{fontSize: '150%'}} type="checkbox" name={name} id={name} onChange={this.handleInputChange} checked={this.state[name]} />
                        <label htmlFor={name}>
                        <span style={{cursor: 'pointer', fontSize: '125%', paddingTop: '2px', paddingLeft: '7px'}}>{sheetName}</span>
                        </label>
                    </div>
                )
            }
        }

        return (
            <div className="row editFileBody">
                <div className="col-sm-9">
                    <br/>
                    <Checkbox toggle onChange={this.toggleCheckAll} label="Select All" value={this.props.checkAll} />
                    {sheetOptions}
                    <br/>
                </div>
                <div className="col-sm-3">
                    <br/>
                    <ImportSheetsButton btnDisabled={numReadSheets===sheetNames.length}
                                        pk={this.props.pk} sheetNames={sheetNames} sheetsChecked={this.state}/>
                    <br/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation,
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheetsBody)