import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'

class TutorialStep extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let style = {fontSize: "120%", padding: "7px 5px 7px 10px"};
        let icon = <span><Icon name="circle outline" /> </span>
        let tutorialStep
        let joinTutorialStep

        if (this.props.type === "append"){
            tutorialStep = this.props.tutorialStep
        }else {
            tutorialStep = this.props.joinTutorialStep
        }

        if (this.props.num <= tutorialStep){
            style["textDecoration"] = "line-through"
            style['color'] = "grey"
            icon = <span style={{color: "#3256a8"}}><Icon name="check circle outline" /> </span>
        }

        if (this.props.num === tutorialStep + 1){
            style['backgroundColor'] = "#E0E0E0"
            icon = <span style={{color: "#3256a8"}}><Icon name="circle outline" /> </span>
        }

        return (
            <div style={style}>
                <p>{icon} {this.props.title}</p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tutorialStep: state.mainState.tutorialStep,
    joinTutorialStep: state.joinState.joinTutorialStep
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(TutorialStep)