import React, {Component} from 'react'
import { connect } from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";

class MergeTypeExplainRow extends Component {
    constructor(props) {
        super(props);

        this.openExplainModal = this.openExplainModal.bind(this);
    }

    openExplainModal(mergeType, e){
        const modalName = mergeType === "append" ? "appendExplainModal" : "joinExplainModal";
        this.props.toggleModal(modalName);
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-5">
                    <div className="row">
                        <div className="col-sm-3" />
                        <div className="col-sm-8">
                            <p style={{float: 'right'}} id="explainText" className="linkStyle" onClick={(e) => this.openExplainModal("append", e)}>What is Append and how does it work?</p>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                </div>
                <div className="col-sm-5">
                    <div>
                        <p style={{float: 'left'}} id="explainText" className="linkStyle" onClick={(e) => this.openExplainModal("join", e)}>What is Join and how does it work?</p>
                    </div>
                </div>
                <div className="col-sm-1" />
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(MergeTypeExplainRow)