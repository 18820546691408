import React, {Component} from 'react'
import { Input } from 'semantic-ui-react'
import DownloadFileButton from './DownloadFileButton'
import { connect } from 'react-redux'
import { Popup, Icon, Segment } from 'semantic-ui-react'
import {modifyAddFileNames} from "../Actions/ModifyAddFileNames";
import {modifyRemoveDuplicates} from "../Actions/ModifyRemoveDuplicates";
import totalFileSize from "../Constants/totalFileSize";
import bytesToMB from "../Constants/bytesToMB";
import pricing from "../Constants/Pricing";
import ProBlockMessage from '../SharedComponents/ProBlockMessage'
import {hasUserMergedWithinTimeframe} from "../Constants/hasUserMergedWithinTimeframe";

class DownloadFileIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileName: '',
            downloadStatus: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateDownloadStatus = this.updateDownloadStatus.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "removeDuplicates"){
            this.props.modifyRemoveDuplicates(value)
        }

        else if (name === "addFileNames"){
            this.props.modifyAddFileNames(value);
        }
        else{
            this.setState({
                [name]: value
            });
        }
    }

    updateDownloadStatus(downloadStatus){
        this.setState({
            downloadStatus: downloadStatus
        })
    }

    render() {
        const fileNameError = this.state.fileName.trim() === "";
        const fileNameErrorDesc = this.state.downloadStatus === 'fileNameError' && this.state.fileName.trim() === "" ? <span><small style={{color: 'red'}}>Please Enter a File Name</small><br/></span>: null

        if (Object.entries(this.props.fileInformation).length !== 0 && this.props.fileInformation.constructor === Object) {
            const proBlock = (!this.props.proBlock && bytesToMB(totalFileSize(this.props.fileInformation)) > pricing.mbMax) || (!this.props.proBlock && hasUserMergedWithinTimeframe());
            let proBlockType = null;

            if (proBlock){
                if (bytesToMB(totalFileSize(this.props.fileInformation)) > pricing.mbMax && hasUserMergedWithinTimeframe()){
                    proBlockType = "both"
                }
                else if (bytesToMB(totalFileSize(this.props.fileInformation)) > pricing.mbMax && !hasUserMergedWithinTimeframe()){
                    proBlockType = "fileSize"
                }
                else if (!(bytesToMB(totalFileSize(this.props.fileInformation)) > pricing.mbMax) && hasUserMergedWithinTimeframe()) {
                    proBlockType = "timeFrame"
                }
            }

            return (
                <Segment raised>
                <div className="downloadFileIndex">
                    <h4 className="thinHeading downloadHeader">Download File</h4>
                    <div className="mergedFileNameDiv">
                        <h5 className="thinHeading">Merged File Name</h5>
                        <Input placeholder="Enter File Name"
                               onChange={this.handleInputChange}
                               value={this.state.fileName}
                               name="fileName"
                               error={fileNameError}
                               id="downloadFileName"
                        />
                        <br/>
                        {fileNameErrorDesc}
                    </div>
                    <div className="removeDuplicatesDiv">
                        <input type="checkbox"
                               name="removeDuplicates"
                               id="removeDuplicates"
                               onChange={this.handleInputChange}
                               checked={this.props.removeDuplicates}
                               style={{cursor: 'pointer'}}
                        />
                        <label htmlFor="removeDuplicates" style={{cursor: 'pointer'}}>
                            <span style={{paddingLeft: '7px', marginTop: '5px'}}>Remove Duplicates</span>
                            <Popup
                                trigger={<span style={{paddingLeft: '5px'}}><Icon name="info circle" /></span>}
                                content='Keep only the first record of duplicates after merge'
                                size='mini'
                            />
                        </label>
                        <br/>
                        <input type="checkbox"
                               name="addFileNames"
                               id="addFileNames"
                               onChange={this.handleInputChange}
                               checked={this.props.addFileNames}
                               style={{cursor: 'pointer'}}
                        />
                        <label htmlFor="addFileNames" style={{cursor: 'pointer'}}>
                            <span style={{paddingLeft: '7px', marginTop: '5px'}}>Add Source File Names</span>
                            <Popup
                                trigger={<span style={{paddingLeft: '5px'}}><Icon name="info circle" /></span>}
                                content='Add new column named File_Names with the source file name'
                                size='mini'
                            />
                        </label>
                    </div>
                    <div className="downloadButtonDiv" style={{textAlign: 'center'}}>
                        <DownloadFileButton fileName={this.state.fileName}
                                            removeDuplicates={this.props.removeDuplicates}
                                            updateDownloadStatus={this.updateDownloadStatus}
                                            downloadStatus={this.state.downloadStatus}
                                            addFileNames={this.props.addFileNames}
                                            proBlock={proBlock}
                        />
                        <small>Files are downloaded in .xlsx format</small>
                    </div>
                    <div className="proBlockMessage">
                        {proBlock ? <ProBlockMessage proBlockType={proBlockType}/> : null}
                    </div>
                </div>
                </Segment>
            )

        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    removeDuplicates: state.mainState.removeDuplicates,
    addFileNames: state.mainState.addFileNames,
    proBlock: state.mainState.proBlock,
});

const mapActionsToProps = {
    modifyAddFileNames: modifyAddFileNames,
    modifyRemoveDuplicates: modifyRemoveDuplicates
}


export default connect(mapStateToProps, mapActionsToProps)(DownloadFileIndex)