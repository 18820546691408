import React, {Component} from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleMergeType} from "../Actions/ToggleMergeType";
import {toggleModal} from "../Actions/ToggleModal";
import {isMobile} from 'react-device-detect'

class ToggleMergeButton extends Component {
    constructor(props) {
        super(props);

        this.toggleMerge = this.toggleMerge.bind(this);
        this.openExplainModal = this.openExplainModal.bind(this);
    }

    toggleMerge(e){
        // if (e.target.id !== "explainText"){
        this.props.toggleMergeType(this.props.mergeType)
        // }
    }

    openExplainModal(mergeType, e){
        const modalName = mergeType === "append" ? "appendExplainModal" : "joinExplainModal";
        this.props.toggleModal(modalName);
    }

    render() {
        const mergeType = this.props.mergeType;

        const style = {cursor: 'pointer', textAlign: 'center', width: '250px'};
        let icon;

        if (mergeType === "append"){
            if (!isMobile){
                style['float'] = "right"
            }

            icon = "th"
        }
        else{
            // style['float'] = "left"
            icon = "th list"
        }

        if (this.props.currentMerge === this.props.mergeType){
            style['backgroundColor'] = "lightgray"
        }


        return (
            <div>
                <Segment raised onClick={this.toggleMerge} style={style} id={"toggleMerge" + mergeType.charAt(0).toUpperCase() + mergeType.slice(1)}  className="toggleMerge">
                    <h3 className="thinHeading"><Icon name={icon} /> {mergeType.charAt(0).toUpperCase() + mergeType.slice(1)}</h3>
                </Segment>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentMerge: state.mainState.mergeType
})

const mapActionsToProps = {
    toggleMergeType: toggleMergeType,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ToggleMergeButton)