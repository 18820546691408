import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleShowTutorial} from "../Actions/ToggleShowTutorial";
import TutorialStep from "./TutorialStep";
import {Icon} from "semantic-ui-react"
import TutorialProgressBar from "./TutorialProgressBar";
import ProBlockStepMessage from "./ProBlockStepMessage";

class TutorialPopup extends Component {
    toggleOpen = () => {
        const currIsOpen = this.props.showTutorial;
        this.props.toggleShowTutorial(!currIsOpen)
    }

    render() {
        const stepsBoxStyle = {
            // height: "300px",
            backgroundColor: "white",
            display: this.props.showTutorial ? "block" : "none",
            boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
            paddingBottom: "30px"
        }
        return (
            <div className="tutorialPopup">
                <div style={stepsBoxStyle}>
                    <div style={{textAlign: "right", padding: "5px 5px 0 0", fontSize: "125%"}}>
                        <span style={{cursor: "pointer"}}><Icon name={this.props.showTutorial ? "angle down" : "angle up"} onClick={this.toggleOpen}/></span>
                    </div>
                    <h4 style={{fontWeight: "800", padding: "5px 20px 0 20px"}}>Append Your Spreadsheets</h4>
                    <p className="text-muted" style={{padding: "0 20px 0 20px"}}>Follow these steps to append</p>
                    <TutorialProgressBar />
                    <TutorialStep type="append" num={1} title="Upload your files"/>
                    {/*<ProBlockStepMessage type="append"/>*/}
                    <TutorialStep type="append" num={2} title="Check if append sample is correct"/>
                    <TutorialStep type="append" num={3} title="Name & Download Your File"/>
                </div>
                <div className="tutorialPopupButton" onClick={this.toggleOpen}>
                    <h4><Icon name="numbered list" /> Steps to Get Started <Icon name={this.props.showTutorial ? "angle down" : "angle up"} /></h4>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    showTutorial: state.mainState.showTutorial
})

const mapActionsToProps = {
    toggleShowTutorial: toggleShowTutorial
}

export default connect(mapStateToProps, mapActionsToProps)(TutorialPopup)