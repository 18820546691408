import React, { Component } from 'react';
import { auth } from '../../firebase';
import { Input, Button, Message } from 'semantic-ui-react';

class PasswordForget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            error: null,
            loading: false,
            message: null
        };

        this.dismissMessage = this.dismissMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.forgetPassword = this.forgetPassword.bind(this);
    }

    handleInputChange(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    forgetPassword(e){
        this.setState({
            loading: true
        });

        const { email } = this.state;

        auth.doPasswordReset(email)
            .then(() => {
                this.setState(() => ({
                    email: '',
                    error: null,
                    loading: false,
                    message:
                    <Message positive
                             onDismiss={this.dismissMessage}
                             header={"Please check your email @ " + email}
                             content="Follow the instructions in your email"
                />
                }));
            })
            .catch(error => {
                this.setState({
                    error: error,
                    loading: false,
                    message:
                    <Message error
                             onDismiss={this.dismissMessage}
                             header="There was an error. Please try again"
                          />
                });
            });

    }

    dismissMessage(e){
        this.setState({ message: null })
    }

    render() {
        const {
            email,
            error,
        } = this.state;

        const isInvalid = email === '';

        return (
            <div>
                <h4 className="thinHeading">Reset Password</h4>
                    <Input
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        name="email"
                        placeholder="Email Address"
                        fluid
                    />
                    <br/>
                    <Button loading={this.state.loading} color="orange" onClick={this.forgetPassword} disabled={isInvalid} >
                        Reset Password
                    </Button>
                    { error && <p>{error.message}</p> }
                    {this.state.message}
            </div>
        );
    }
}


export default PasswordForget;