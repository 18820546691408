import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table } from 'semantic-ui-react'
import {modifySelectedDetailCell} from "../../Actions/ModifySelectedDetailCell";
import {modifyFinalColumnNames} from "../../Actions/ModifyFinalColumnNames";
import {modifyFinalColumnPK} from "../../Actions/ModifyFinalColumnPK";

class DetailedViewCell extends Component {
    constructor(props) {
        super(props);

        this.updateSelectedCell = this.updateSelectedCell.bind(this);
        this.bgColor = this.bgColor.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.modifyColumnPlacement = this.modifyColumnPlacement.bind(this);
        // this.removeSelectedColumn = this.removeSelectedColumn.bind(this);
        // this.addToClickedColumn = this.addToClickedColumn.bind(this);
        this.canPlace = this.canPlace.bind(this);
    }

    updateSelectedCell(e){
        const cellInfo = {
            finalColIdx: this.props.finalColIdx,
            filePK: this.props.filePK,
            fileColIdx: this.props.fileColIdx
        }

        this.props.modifySelectedDetailCell(cellInfo);
    }

    modifyColumnPlacement(e){
        // Get the data from the selected info
        const selectedInfo = JSON.parse(JSON.stringify(this.props.selectedDetailCell));

        const finalColIdx = selectedInfo.finalColIdx;
        const filePK = selectedInfo.filePK;
        const fileColIdx = selectedInfo.fileColIdx;

        if (finalColIdx !== null && filePK !== null && fileColIdx !== null && filePK === this.props.filePK){
            // Get the Final Column Names and their associated structure
            const finalColumnNames = JSON.parse(JSON.stringify(this.props.finalColumnNames));
            const finalColumnPK = JSON.parse(JSON.stringify(this.props.finalColumnPK));

            // Get the Column ID of the Column
            const finalColID = finalColumnNames[finalColIdx].id;
            // Get the FilePK and File Columns Associated to the Final Columns
            const finalColPK = finalColumnPK[finalColID];

            // Get ALL the FilePKs and ALL File Col Indices related to the final column
            const finalColPKFilePK = finalColPK.filePK;
            const finalColPKColIdx = finalColPK.fileColIdx;

            // Get the index of the File Selected
            const finalColPKFilePKIdx = finalColPKFilePK.indexOf(filePK);

            // Remove it from both the FilePKS and File Col Indices
            finalColPKFilePK.splice(finalColPKFilePKIdx, 1);
            finalColPKColIdx.splice(finalColPKFilePKIdx, 1);

            // Set the removed values back
            finalColumnPK[finalColID].filePK = finalColPKFilePK;
            finalColumnPK[finalColID].fileColIdx = finalColPKColIdx;

            // Add it to the clicked column
            const clickedFinalColIdx = this.props.finalColIdx;

            // Get the Column ID of the Clicked Column
            const clickedFinalColID = finalColumnNames[clickedFinalColIdx].id;
            // Get the FilePK and File Columns Associated to the Final Columns
            const clickedFinalColPK = finalColumnPK[clickedFinalColID];

            // Get ALL the FilePKs and ALL File Col Indices related to the final column
            let clickedFinalColPKFilePK = clickedFinalColPK.filePK;
            let clickedFinalColPKColIdx = clickedFinalColPK.fileColIdx;

            // Add the clicked column props to the values
            clickedFinalColPKFilePK = clickedFinalColPKFilePK.concat([filePK]);
            clickedFinalColPKColIdx = clickedFinalColPKColIdx.concat([fileColIdx]);

            // Set the added values back
            finalColumnPK[clickedFinalColID].filePK = clickedFinalColPKFilePK
            finalColumnPK[clickedFinalColID].fileColIdx = clickedFinalColPKColIdx

            this.props.modifyFinalColumnPK(finalColumnPK);

            // Change the values modified
            const cellInfo = {
                finalColIdx: null,
                filePK: null,
                fileColIdx: null
            }

            this.props.modifySelectedDetailCell(cellInfo);
        }
    }

    bgColor(){
        const selectedInfo = this.props.selectedDetailCell;

        if (selectedInfo.finalColIdx === this.props.finalColIdx &&
            selectedInfo.filePK === this.props.filePK &&
            selectedInfo.fileColIdx === this.props.fileColIdx
        ){
            return {backgroundColor: "#ccbadc", cursor: 'pointer'}
        }
        else{
            return {cursor: 'pointer'}
        }
    }

    canPlace(){
        const selectedInfo = JSON.parse(JSON.stringify(this.props.selectedDetailCell));

        const filePK = selectedInfo.filePK;

        let cursorStyle;

        if (filePK === this.props.filePK){
            cursorStyle = "copy"
        }
        else{
            cursorStyle = "not-allowed"
        }

        return {'cursor': cursorStyle}
    }

    // componentWillMount(){
    //     document.addEventListener('mousedown', this.handleClick, false)
    // }
    //
    // componentWillUnmount(){
    //     document.removeEventListener('mousedown', this.handleClick, false)
    // }
    // //
    // handleClick(e){
    //     console.log(this.node);
    //     if (!this.node.contains(e.target)){
    //         const cellInfo = {
    //             finalColIdx: null,
    //             filePK: null,
    //             fileColIdx: null
    //         }
    //
    //         this.props.modifySelectedDetailCell(cellInfo);
    //     }
    //     else{
    //         this.updateSelectedCell(e);
    //     }
    // }

    render() {
        if ('show' in this.props){
            const tableColumn = this.props.fileName + ": " + this.props.colName;

            let tableRows = [];

            if (this.props.fileSamples.length > 0){
                for (let i=0; i<this.props.fileSamples.length; i++){
                    const row = this.props.fileSamples[i];

                    tableRows.push(
                        <Table.Row key={i}><Table.Cell>{row[this.props.fileColIdx]}</Table.Cell></Table.Row>
                    )
                }
            }
            else{
                tableRows = null
            }


            return (
                <Table.Cell onClick={this.updateSelectedCell} style={this.bgColor()}>
                    <Table collapsing>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    {tableColumn}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tableRows}
                        </Table.Body>
                    </Table>
                </Table.Cell>
            )
        }
        else{
            return(
                <Table.Cell onClick={this.modifyColumnPlacement} style={this.canPlace()}>

                </Table.Cell>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    selectedDetailCell: state.mainState.selectedDetailCell,
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK
})

const mapActionsToProps = {
    modifySelectedDetailCell: modifySelectedDetailCell,
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFinalColumnPK: modifyFinalColumnPK
}

export default connect(mapStateToProps, mapActionsToProps)(DetailedViewCell)