import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'

class RearrangeColumns extends Component {
    render() {
        return (
            <div>
                <p className="landingExplainDesc">There are many features in the tool to help you rearrange the columns and file order</p>
                <br/>
                <h3 className="thinHeading">1. Move the Order of the Files </h3>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MoveRows.gif" centered />*/}
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MoveRows.gif" style={{width: '100%'}} alt="Append Move File Order"/>
                <br/><br/>
                <h3 className="thinHeading">2. Move Merged Columns </h3>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MoveColumns.gif" centered />*/}
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MoveColumns.gif" style={{width: '100%'}} alt="Append Move File Columns" />
                <br/><br/>
                <h3 className="thinHeading">3. Move Column Placement Using Detailed View</h3>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/DetailedColumn.gif" centered />*/}
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/DetailedColumn.gif" style={{width: '100%'}} alt="Append Move File Columns" />
                <br/>
            </div>
        )
    }
}

export default RearrangeColumns