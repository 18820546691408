import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import EditFileBody from './EditFileBody'
import {connect} from 'react-redux'

class JoinEditModal extends Component {
    render() {
        const header = this.props.editID === "all" ? "Re-Read All Files With These Options" : "Re-Read File With These Options";

        return (
            <ModalApp name="joinEditModal"
                      header={header}
                      size="small"
                      body={<EditFileBody/>}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.joinState.joinEditID
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(JoinEditModal)