import React, {Component} from 'react'
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon } from "react-share";

class TutorialShare extends Component {
    render() {
        const shareUrl = "http://mergespreadsheets.com/guides/" + this.props.url;

        return (
            <div className="tutorialShare">
                <h4>Share</h4>
                <TwitterShareButton
                    url={shareUrl}
                    title={this.props.header + " by @LoveSpreadsheet"}>
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
                <br/>
                <FacebookShareButton
                    url={shareUrl}
                    quote={this.props.header}
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>

            </div>
        )
    }
}

export default TutorialShare