import React, {Component} from 'react'
import { Segment, Icon } from 'semantic-ui-react'

class ToggleMergeButton extends Component {
    constructor(props) {
        super(props);

        this.toggleMerge = this.toggleMerge.bind(this);
    }

    toggleMerge(e){
        this.props.toggleMergeType(this.props.mergeType)
    }

    render() {
        const mergeType = this.props.mergeType;

        const style = {cursor: 'pointer', textAlign: 'center', width: '250px'};
        let icon;

        if (mergeType === "append"){
            style['float'] = "right"

            icon = "th"
        }
        else{
            // style['float'] = "left"
            icon = "th list"
        }

        if (this.props.currentMerge === this.props.mergeType){
            style['backgroundColor'] = "lightgray"
        }


        return (
            <div>
                <Segment raised onClick={this.toggleMerge} style={style} id="toggleMerge" className="toggleMerge">
                    <h3 className="thinHeading"><Icon name={icon} /> {mergeType.charAt(0).toUpperCase() + mergeType.slice(1)}</h3>
                </Segment>
            </div>
        )
    }
}

export default ToggleMergeButton