import React, {Component} from 'react'
import { Button, Popup } from 'semantic-ui-react'

class DownloadAppButtons extends Component {
    constructor(props){
        super(props);

        this.state = {
            downloadURL: ''
        }

        this.setDownloadURL = this.setDownloadURL.bind(this);
    }
    setDownloadURL(e, downloadType){
        const downloadURLs = {
            'mac': 'https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/DesktopApps/Merge+Spreadsheets-1.0.4.dmg',
            'windows': 'https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/DesktopApps/Merge+Spreadsheets.exe',
            'linux': 'https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/DesktopApps/Merge+Spreadsheets'
        };

        this.setState(
            { downloadURL: downloadURLs[downloadType]},
            () => this.downloadLink.click()
        )
    }
    render() {
        const downloadURL = this.state.downloadURL;
        const osLabel = {'fontWeight': '700'}

        let minOS = <div className={{textAlign: 'center'}}>
            <Popup
                trigger={
                    <p className="linkStyle">What are the minimum OS requirements?</p>
                }
                content={<div>
                    <p><span style={osLabel}>Mac: </span>> 10.10</p>
                    <p><span style={osLabel}>Windows: </span>> 7</p>
                    <p><span style={osLabel}>Ubuntu</span> > 10.4</p>
                </div>}
                on='click'
            />
        </div>;

        if ('noOS' in this.props){
            minOS = null
        }

        let btnDisabled = false;

        if ('disabled' in this.props){
            btnDisabled = true;
        }

        return (
            <div>
                <a
                    style={{display: 'none'}}
                    ref={downloadLink => this.downloadLink = downloadLink}
                    href={downloadURL}
                >Download hidden</a>
                <Button disabled={btnDisabled} icon="apple" secondary content="Mac" onClick={(e) => this.setDownloadURL(e, "mac")}/>
                <Button disabled={btnDisabled} icon="windows" secondary content="Windows" onClick={(e) => this.setDownloadURL(e, "windows")}/>
                <Button disabled={btnDisabled} icon="linux" secondary content="Linux" onClick={(e) => this.setDownloadURL(e, "linux")}/>
                {minOS}
            </div>
        )
    }
}

export default DownloadAppButtons