import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import SocialMediaButtons from "./SocialMediaButtons";
import { isBrowser } from 'react-device-detect'
import {isEmbed} from "../../Constants/isEmbed";

class FooterIndex extends Component {
    constructor(props){
        super(props);

        this.openModal = this.openModal.bind(this);

    }

    openModal(){
        this.props.toggleModal("priceModal")
    }

    render() {
        if (isEmbed(window.location.href)){
            return null
        } else {
            if (isBrowser){
                return (
                    <div style={{backgroundColor: '#F5F5F5', color: 'black'}}>
                        <br/>
                        <div className="row">
                            <div className="col-sm-4">
                                <div style={{paddingLeft: '5%'}}>
                                    <p> <a href="https://www.woyera.com/" target="_blank" rel="noopener noreferrer"><Icon name="copyright outline"/> {new Date().getFullYear()} Woyera Inc </a>
                                        | <a href="/terms" target="_blank" rel="noopener noreferrer"> Terms & Conditions</a> | <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div style={{textAlign: 'center'}}>
                                    <a
                                        href="http://www.lovespreadsheets.com/" target="_blank" rel="noopener noreferrer">
                                        Part of <span role="img" aria-label="heart">❤️</span> spreadsheets!
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div style={{paddingRight: '5%', float: 'right'}}>
                                    <SocialMediaButtons />
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                )
            } else{
                return(
                    <div style={{backgroundColor: '#F5F5F5', color: 'black', textAlign: 'center'}}>
                        <br/><br/>
                        <a
                            style={{fontSize: '125%'}} href="http://www.lovespreadsheets.com/" target="_blank" rel="noopener noreferrer">
                            Part of <span role="img" aria-label="heart">❤️</span> spreadsheets!
                        </a>
                        <br/><br/>
                        <div style={{width: '50%', margin: '0 auto'}}>
                            <SocialMediaButtons />
                        </div>
                        <br/><br/>
                        <a href="https://www.woyera.com/" target="_blank" rel="noopener noreferrer"><Icon name="copyright outline"/> {new Date().getFullYear()} Woyera Inc </a>
                        <br/>
                        <a href="/terms" target="_blank" rel="noopener noreferrer"> Terms & Conditions</a>
                        <br/>
                        <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        <br/><br/>
                    </div>
                )
            }
        }

    }
}

export default FooterIndex