import React, {Component} from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import API_Root from "../Constants/API_Root";
import {initMergedColumnNames} from "../Actions/InitMergedColumnNames";
import {initMergedValues} from "../Actions/InitMergedValues";
import {toggleModal} from "../Actions/ToggleModal";

import totalFileSize from "../Constants/totalFileSize";
import bytesToMB from "../Constants/bytesToMB";
import pricing from "../Constants/Pricing";
import {modifyTutorialStep} from "../Actions/ModifyTutorialStep";

class DownloadFileButton extends Component {
    constructor(props) {
        super(props);

        this.downloadFile = this.downloadFile.bind(this);
    }

    downloadFile(e){
        if (this.props.fileName.trim() === ""){
            this.props.updateDownloadStatus("fileNameError")
        }
        else{
            const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
            const socket = new WebSocket(websocketRoute + "ws/api/download-files/");

            const props = this.props;
            const downloadLink = this.downloadLink;

            this.props.toggleModal("loadingModalFile");

            socket.onopen = function(event) {
                props.updateDownloadStatus("preparing");

                socket.send(JSON.stringify({
                    downloadStatus: props.downloadStatus,
                    filePK: props.filePK,
                    finalColumnNames: props.finalColumnNames,
                    finalColumnPK: props.finalColumnPK,
                    fileName: props.fileName.trim(),
                    addFileNames: props.addFileNames,
                    removeDuplicates: props.removeDuplicates,
                    sheetRead: props.sheetRead,
                    editOptions: props.editOptions
                }));

                socket.onmessage = function(event) {
                    const reply = JSON.parse(event.data);

                    const status = reply['status'];

                    if (status === "merged"){
                        props.modifyTutorialStep(3)
                        localStorage.setItem("lastMerged", new Date())
                        props.initMergedValues(reply['url']);
                        downloadLink.click();
                        props.toggleModal(null);
                        if (!props.isUserPro){
                            props.toggleModal("feedbackModal")
                        }
                        socket.close()
                    }
                    else{
                        props.toggleModal(null);
                        socket.close()
                        alert("Download Failed. Try again")
                    }
                }
            };
        }
    }

    render() {
        const url = this.props.mergedValues;

        return (
            <div>
                <a
                    style={{display: 'none'}}
                    ref={downloadLink => this.downloadLink = downloadLink}
                    href={url}
                >Download hidden</a>
                <Button style={{height: '75%', width: '75%', fontSize: '125%'}}
                        color="green"
                        id="downloadFileButton"
                        className="roundedButton"
                        onClick={this.downloadFile}
                        disabled={this.props.proBlock}>
                    <Icon name="download" />Download File
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK,
    mergedColumnNames: state.mainState.mergedColumnNames,
    mergedValues: state.mainState.mergedValues,
    sheetRead: state.mainState.sheetRead,
    editOptions: state.mainState.editOptions,
    fileInformation: state.mainState.fileInformation,
    isUserPro: state.mainState.proBlock,
});


const mapActionsToProps = {
    initMergedColumnNames: initMergedColumnNames,
    initMergedValues: initMergedValues,
    toggleModal: toggleModal,
    modifyTutorialStep: modifyTutorialStep
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadFileButton)