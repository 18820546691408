import React, {Component} from 'react'
import {connect} from 'react-redux'
import ColumnName from './ColumnName'
import { Table } from 'semantic-ui-react'
import ColumnEdit from './ColumnEdit'

class FinalColumnNames extends Component {
    render() {
        let columnNames;
        let addFileNames;

        if (!this.props.edit){
            columnNames = this.props.finalColumnNames.map((x, idx) => <ColumnName key={idx} idx={idx} />);

            addFileNames = this.props.addFileNames ?  <Table.HeaderCell> File_Names </Table.HeaderCell> : null;
        }
        else{
            columnNames = this.props.finalColumnNames.map((x, idx) => <ColumnEdit key={idx} idx={idx} />);
            addFileNames = this.props.addFileNames ? <Table.HeaderCell> </Table.HeaderCell> : null;
        }


        return (
            <Table.Row>
                <Table.HeaderCell />
                {columnNames}
                {addFileNames}
            </Table.Row>
        )
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames,
    addFileNames: state.mainState.addFileNames
});

export default connect(mapStateToProps, )(FinalColumnNames)