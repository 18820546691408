import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyJoinStatus} from "../../Actions/Join/ModifyJoinStatus";
import {modifyJoinFileInformation} from "../../Actions/Join/ModifyJoinFileInformation";
import {modifyJoinEditOptions} from "../../Actions/Join/ModifyJoinEditOptions";
import {modifyAllJoinInformation} from "../../Actions/Join/ModifyAllJoinInformation";
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";

class EditFileReadButton extends Component {
    constructor(props) {
        super(props);

        this.editFile = this.editFile.bind(this)
    }

    editFile(){
        // Check if there is any error
        const skipRowsError = this.props.editOptions.skipRowsError;
        const startRowError = this.props.editOptions.startRowError;

        if (!skipRowsError && !startRowError){
            const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
            const socket = new WebSocket(websocketRoute + "ws/api/join-files/");

            let filePK;

            // Get the PK of the file(s)
            if (this.props.editID !== "all"){
                for (let i=0; i<this.props.filePK.length; i++){
                    const filePKObj = this.props.filePK[i];
                    const fileID = filePKObj['id'];

                    if (fileID === this.props.editID){
                        filePK = [filePKObj];
                        break
                    }
                }
            }
            else{
                filePK = this.props.filePK
            }

            // Send the PKs and if any column names
            socket.onopen = (event) => {
                socket.send(JSON.stringify({
                    filePK: filePK,
                    sheetRead: this.props.sheetRead,
                    editOptions: this.props.editOptions
                }));
            };

            this.props.toggleModal(null);
            this.props.toggleModal("loadingModalEditFile");

            // const fileReadData = readJoinFiles(filePath, this.props.sheetRead, this.props.editOptions);

            socket.onmessage = (event) => {
                const reply = JSON.parse(event.data);
                const status = reply['status'];

                this.props.modifyJoinStatus(status);

                if (status === "merged"){
                    let fileInformation = JSON.parse(JSON.stringify(reply.fileInformation));
                    let propsFileInformation = JSON.parse(JSON.stringify(this.props.fileInformation));

                    if (typeof propsFileInformation !== 'undefined'){
                        if (Object.entries(propsFileInformation).length !== 0 && propsFileInformation.constructor === Object){
                            for (let pkID in fileInformation){
                                const fileInfo = fileInformation[pkID];
                                propsFileInformation[pkID] = fileInfo
                            }
                        }
                    }

                    this.props.modifyAllJoinInformation(propsFileInformation, this.props.sheetRead)

                    const reduxEditOptions = JSON.parse(JSON.stringify(this.props.reduxEditOptions));

                    if (this.props.editID !== "all"){
                        reduxEditOptions[this.props.editID] = this.props.editOptions;
                    }
                    else{
                        for (let i=0; i<this.props.filePK.length; i++){
                            const filePKObj = this.props.filePK[i];
                            const fileID = filePKObj['id'];

                            reduxEditOptions[fileID] = this.props.editOptions;
                        }

                        reduxEditOptions["all"] = this.props.editOptions;
                    }

                    this.props.modifyEditOptions(reduxEditOptions);
                    socket.close()

                    this.props.toggleModal(null);
                }
                else if (status === "error"){
                    socket.close()
                    this.props.toggleModal(null);
                    alert("There was a problem reading your file. Please try again")
                }
            };


        }
    }

    render() {
        const text = this.props.editID === "all" ? "Re-Read All Files" : "Re-Read File";
        const skipRowsError = this.props.editOptions.skipRowsError;
        const startRowError = this.props.editOptions.startRowError;

        return (
            <Button color="blue" icon="edit outline" content={text} disabled={skipRowsError || startRowError} onClick={this.editFile}/>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.joinState.joinFileInformation,
    filePK: state.joinState.joinFilePK,
    editID: state.joinState.joinEditID,
    reduxEditOptions: state.joinState.joinEditOptions,
    sheetRead: state.joinState.joinSheetRead
})

const mapActionsToProps = {
    modifyStatus: modifyJoinStatus,
    modifyFileInformation: modifyJoinFileInformation,
    modifyEditOptions: modifyJoinEditOptions,
    toggleModal: toggleModal,
    modifyAllJoinInformation: modifyAllJoinInformation,
    modifyJoinStatus: modifyJoinStatus
}

export default connect(mapStateToProps, mapActionsToProps)(EditFileReadButton)