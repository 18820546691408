import React, {Component} from 'react'
import ModalApp from '../../../SharedComponents/Modal'
import LoginForm from './LoginForm'
import {Segment} from 'semantic-ui-react'

class LoginModal extends Component {
    render() {
        const body =
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <br/>
                    <Segment raised>
                        <LoginForm/>
                    </Segment>
                    <br/><br/>
                </div>
                <div className="col-sm-2" />
            </div>

        // const bodyColor = "#7fd3fd";
        const bodyColor = "#ccbadc";

        return (
            <ModalApp name="loginModal" bodyColor={bodyColor} header={null} body={body} size="medium"/>
        )
    }
}

export default LoginModal