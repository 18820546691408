import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import ImportSheetsBody from './ImportSheetsBody'
import {connect} from 'react-redux';

class JoinImportSheetsModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            checkAll: false
        }

        this.toggleCheckAll = this.toggleCheckAll.bind(this);
    }

    toggleCheckAll(checked){
        this.setState({
            checkAll: checked
        })
    }

    render() {
        let filePK;

        // Get the PK of the file
        for (let i=0; i<this.props.filePK.length; i++){
            const filePKObj = this.props.filePK[i];
            const fileID = filePKObj['id'];

            if (fileID === this.props.editID){
                filePK = filePKObj['pk'];
                break
            }
        }

        return (
            <ModalApp name="joinImportSheets"
                      header="Import Other Sheets"
                      body={<ImportSheetsBody   checkAll={this.state.checkAll}
                                                toggleCheckAll={this.toggleCheckAll}
                                                pk={filePK}/>}
                      size="small"
            />
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.joinState.joinEditID,
    filePK: state.joinState.joinFilePK
})

export default connect(mapStateToProps,)(JoinImportSheetsModal)