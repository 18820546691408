import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Message, Segment, Button, Icon } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import DownloadAppButtons from './DownloadAppButtons'
import getCurrencyAmount from "../../Constants/getCurrencyAmount";

class DownloadAppMessage extends Component {
    constructor(props) {
        super(props);

        this.payAndDownload = this.payAndDownload.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
    }

    payAndDownload(){
        this.props.toggleModal("signUpModal");
    }

    closeMessage(){
        this.props.modifyOneMBMessage(false);
    }

    render() {
        const messageColor = this.props.mergeType === "append" ? "purple" : "blue";
        let downloadHeader = "You can freely merge files with no size restrictions using our Desktop app";
        let content;

        // False means they are not a Pro user
        if (!this.props.proBlock){
            downloadHeader += " for just " + getCurrencyAmount(this.props.currency) +"!"
            content =
                <div style={{margin: '0 auto', width: '75%'}}>
                    <br/>
                    <Button color="green" onClick={this.payAndDownload}>
                        {"Pay " + getCurrencyAmount(this.props.currency) + " and Download Now"}
                        <Icon name='right arrow' />
                    </Button>
                    <br/>
                </div>

        }
        else{
            content = <div>
                <br/>
                <DownloadAppButtons/>
            </div>
        }

        return (
            <Segment raised>
                <div className="closeIconClass" id="closeMessageIcon" onClick={this.closeMessage}>
                    <Icon name="remove" />
                </div>
                <Message color={messageColor} icon='file alternate outline'
                         header={'Your total file size was bigger than 2MB due to these files: ' + this.props.fileNames.join(", ")}
                         content='The website only supports a total file size of 2MB' />
                <Message color="green" icon="download"
                         header={downloadHeader} content={content} />
            </Segment>
        )
    }
}

const mapStateToProps = (state) => ({
    proBlock: state.mainState.proBlock,
    mergeType: state.mainState.mergeType,
    currency: state.mainState.currency
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadAppMessage)