import React, {Component} from 'react'
import {connect} from 'react-redux'
import bytesToMB from "../Constants/bytesToMB";
import totalFileSize from "../Constants/totalFileSize";
import pricing from "../Constants/Pricing";
import {hasUserMergedWithinTimeframe} from "../Constants/hasUserMergedWithinTimeframe";
import {Button} from "semantic-ui-react";
import {toggleModal} from "../Actions/ToggleModal";

class ProBlockStepMessage extends Component {
    constructor(props) {
        super(props);
        this.signUpAndPay = this.signUpAndPay.bind(this);
    }

    signUpAndPay(e){
        this.props.toggleModal("signUpModal");
    }

    render() {

        let proBlock
        if (this.props.type === "join"){
            proBlock = (!this.props.proBlock && bytesToMB(totalFileSize(this.props.joinFileInformation)) > pricing.mbMax) || (!this.props.proBlock && hasUserMergedWithinTimeframe());
        }else{
            proBlock = (!this.props.proBlock && bytesToMB(totalFileSize(this.props.fileInformation)) > pricing.mbMax) || (!this.props.proBlock && hasUserMergedWithinTimeframe());
        }

        if (proBlock){
            return (
                <div style={{textAlign:'center',color:'white',padding:'5x'}}>
                    <Button id="proBlockMessageButton" color="purple" onClick={this.signUpAndPay}
                            style={{height: '80%'}}>
                        <h5> Upgrade to Pro</h5> </Button>
                </div>


            )
        }else{
            return null
        }
    }
}

const mapStateToProps = (state) => ({
    proBlock: state.mainState.proBlock,
    joinFileInformation: state.joinState.joinFileInformation,
    fileInformation: state.mainState.fileInformation,


})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ProBlockStepMessage)