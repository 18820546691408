import React, {Component} from 'react'
import {connect} from 'react-redux'
import PasswordForget from '../../Authentication/UserPages/Password/PasswordForget'
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";
import CancelAccount from "./CancelAccount"
import formatDateToDisplay from "../../Constants/formatDateToDisplay";
import Loader from 'react-loader-spinner'
import UpdatePaymentForm from "../../SharedComponents/UpdatePayment/UpdatePaymentForm";
import DownloadReceiptsAccordion from "./DownloadReceiptsAccordion";
import isObjectEmpty from "../../Constants/isObjectEmpty";

class ProfileModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateJoined: '',
            datePro: '',
            pricing: null,
            fetching: false
        }

        this.signUpToPro = this.signUpToPro.bind(this);
    }

    signUpToPro(){
        this.props.toggleModal(null);
        this.props.toggleModal("signUpModal")
    }

    componentWillMount(){
        this.setState({ fetching: true })

        const stopFetching = () => this.setState({ fetching: false });

        const current = new Date();
        const utcOffset = current.getTimezoneOffset();

        fetch(API_Root + "api/get-profile-information/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                utcOffset: utcOffset,
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            })
        }).then(res => res.json())
            .then((data) => {
                console.log(data)
                this.setState({
                    dateJoined: data.dateJoined,
                    datePro: data.datePro,
                    pricing: data
                }, () => stopFetching())
            }).catch(err => stopFetching()).catch(err => stopFetching());
    }

    render() {
        const pro = this.state.datePro === "" ?
            <span className="linkStyle" onClick={this.signUpToPro}>Sign Up for Pro</span> :
            <span>{this.state.datePro}</span>;

        const accordion = this.state.datePro === "" ? null :
            <div className="row">
                <div className="col-sm-1"/>
                <div className="col-sm-10">
                    <DownloadReceiptsAccordion/>
                </div>
                <div className="col-sm-1"/>
            </div>

        let dates = null;
        let accountType = null;
        let canCancel = true;

        if (this.state.pricing !== null){
            if ('currentPeriodEnd' in this.state.pricing && 'currentPeriodStart' in this.state.pricing){
                dates = <div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-1"/>
                        <div className="col-sm-5">
                            <h4 className="thinHeading">Current Month Start</h4>
                        </div>
                        <div className="col-sm-5">
                            <h5 className="thinHeading text-muted">{formatDateToDisplay(new Date(parseInt(this.state.pricing.currentPeriodStart, 10) * 1000))}</h5>
                        </div>
                        <div className="col-sm-1"/>
                    </div>
                    <br/>
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-1"/>*/}
                        {/*<div className="col-sm-5">*/}
                            {/*<h4 className="thinHeading">Current Period End</h4>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-5">*/}
                            {/*<h5 className="thinHeading text-muted">{formatDateToDisplay(new Date(parseInt(this.state.pricing.currentPeriodEnd, 10) * 1000))}</h5>*/}
                        {/*</div>*/}
                        {/*<div className="col-sm-1"/>*/}
                    {/*</div>*/}
                </div>
            }

            if ('subscription_id' in this.state.pricing){
                accountType = "Subscription"

                if ('cancel' in this.state.pricing){
                    if (this.state.pricing.cancel){
                        accountType = <span style={{color: 'red'}}><b>CANCELED</b></span>
                        canCancel = false
                    }
                }
            } else if ("subscriptionInfo" in this.state.pricing){
                accountType = "Subscription"

                if (!isObjectEmpty(this.state.pricing.subscriptionInfo)){
                    try{
                        if (this.state.pricing.subscriptionInfo.cancel_at !== null){
                            canCancel = false
                            const cancelDt = new Date(this.state.pricing.subscriptionInfo.cancel_at * 1000)

                            if (cancelDt <= new Date()){
                                accountType = <span style={{color: 'red'}}><b>CANCELED at {cancelDt.toLocaleDateString()}</b></span>
                            } else {
                                accountType = <span style={{color: 'red'}}><b>WILL CANCEL at {cancelDt.toLocaleDateString()}</b></span>
                            }
                        }
                    } catch (e){

                    }
                }
                if ('cancel' in this.state.pricing){
                    if (this.state.pricing.cancel){
                        accountType = <span style={{color: 'red'}}><b>CANCELED</b></span>
                        canCancel = false
                    }
                }
            } else {
                accountType = "Lifetime"
            }
        }

        if (this.state.fetching){
            return(
                <div>
                    <Loader
                        type="TailSpin"
                        color="black"
                        height="50"
                        width="50"
                    />
                    <h4 className="thinHeading">Getting your profile information...</h4>
                </div>
            )
        }
        else{
            return (
                <div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-1"/>
                        <div className="col-sm-5">
                            <h4 className="thinHeading">Account Type</h4>
                        </div>
                        <div className="col-sm-5">
                            <h5 className="thinHeading text-muted">{accountType}</h5>
                        </div>
                        <div className="col-sm-1"/>
                    </div>
                    {accordion}
                    <br/>
                    <div className="row">
                        <div className="col-sm-1"/>
                        <div className="col-sm-5">
                            <h4 className="thinHeading">Email</h4>
                        </div>
                        <div className="col-sm-5">
                            <h5 className="thinHeading text-muted">{localStorage.getItem("email")}</h5>
                        </div>
                        <div className="col-sm-1"/>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-1"/>
                        <div className="col-sm-5">
                            <h4 className="thinHeading">Date Upgraded to Pro</h4>
                        </div>
                        <div className="col-sm-5">
                            <h5 className="thinHeading text-muted">{pro}</h5>
                        </div>
                        <div className="col-sm-1"/>
                    </div>
                    {dates}
                    <br/>
                    <div className="row">
                        <div className="col-sm-1"/>
                        <div className="col-sm-10">
                            <PasswordForget/>
                        </div>
                        <div className="col-sm-1"/>
                    </div>
                    {
                        canCancel ? <div>
                            <br/><br/>
                            <div className="row">
                                <div className="col-sm-1"/>
                                <div className="col-sm-10">
                                    <h4 className="thinHeading" style={{color: 'red'}}>Cancel Your Account</h4>
                                    <CancelAccount canCancel={canCancel}/>
                                </div>
                                <div className="col-sm-1"/>
                            </div>
                        </div> : null
                    }
                    <br/><br/>
                    {
                        accountType === "Subscription" ?
                            <div className="row">
                                <div className="col-sm-1"/>
                                <div className="col-sm-10">
                                    <h4 className="thinHeading" style={{color: "orange"}}>Change Credit Card</h4>
                                    <UpdatePaymentForm/>
                                </div>
                                <div className="col-sm-1"/>
                            </div> : null
                    }
                    <br/><br/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {
    toggleModal: toggleModal
};

export default connect(mapStateToProps, mapActionsToProps)(ProfileModalBody)