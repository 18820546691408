import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'

class OtherFileTypes extends Component {
    render() {
        return (
            <div>
                <p className="landingExplainDesc">You can import all your Excel sheets in the File Options and the append will happen auto-magically</p>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/ImportSheets.gif" centered style={{border: '1px lightgray solid'}}/>*/}
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/ImportSheets.gif" style={{border: '1px lightgray solid', width: '100%'}} alt="Append Import Sheets"/>
                <br/><br/>
                <p className="landingExplainDesc">CSV files are read by default as a Comma delimiter. You can change the delimiter in the File Options</p>
            </div>
        )
    }
}

export default OtherFileTypes