import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleShowJoinTutorial} from "../../Actions/Join/ToggleShowJoinTutorial";
import TutorialStep from "../../TutorialSteps/TutorialStep"
import {Icon} from "semantic-ui-react"
import JoinTutorialProgressBar from "./JoinTutorialProgressBar"
import ProBlockStepMessage from "../../TutorialSteps/ProBlockStepMessage";


class JoinTutorialPopup extends Component {
    toggleOpen = () => {
        const currIsOpen = this.props.showJoinTutorial;
        this.props.toggleShowJoinTutorial(!currIsOpen)
    }

    render() {
        const stepsBoxStyle = {
            // height: "300px",
            backgroundColor: "white",
            display: this.props.showJoinTutorial ? "block" : "none",
            boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
            paddingBottom: "30px"
        }
        return (
            <div className="tutorialPopup">
                <div style={stepsBoxStyle}>
                    <div style={{textAlign: "right", padding: "5px 5px 0 0", fontSize: "125%"}}>
                        <span style={{cursor: "pointer"}}><Icon name={this.props.showJoinTutorial ? "angle down" : "angle up"} onClick={this.toggleOpen}/></span>
                    </div>
                    <h4 style={{fontWeight: "800", padding: "5px 20px 0 20px"}}>Join Your Spreadsheets</h4>
                    <p className="text-muted" style={{padding: "0 20px 0 20px"}}>Follow these steps to join</p>
                    <JoinTutorialProgressBar />
                    <TutorialStep type="join" num={1} title="Upload at least 2 files"/>
                    <TutorialStep type="join" num={2} title="Select Left File"/>
                    <TutorialStep type="join" num={3} title="Select Right File"/>
                    <TutorialStep type="join" num={4} title="Select Join Type"/>
                    <TutorialStep type="join" num={5} title="Select Columns to Join"/>
                    <TutorialStep type="join" num={6} title="Join Files"/>
                    <TutorialStep type="join" num={7} title="Download Joined Files"/>
                </div>
                <div className="tutorialPopupButton" onClick={this.toggleOpen}>
                    <h4><Icon name="numbered list" /> Steps to Get Started <Icon name={this.props.showJoinTutorial ? "angle down" : "angle up"} /></h4>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    showJoinTutorial: state.joinState.showJoinTutorial
})

const mapActionsToProps = {
    toggleShowJoinTutorial: toggleShowJoinTutorial
}

export default connect(mapStateToProps, mapActionsToProps)(JoinTutorialPopup)