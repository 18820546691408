import {TOGGLE_MODAL} from "../Actions/ToggleModal";
import {MODIFY_FILE_PK} from "../Actions/ModifyFilePK";
import {MODIFY_FINAL_COLUMN_NAMES} from "../Actions/ModifyFinalColumnNames";
import {MODIFY_FILE_INFORMATION} from "../Actions/ModifyFileInformation";
import {TOGGLE_PRO_BLOCK} from "../Actions/ToggleProBlock";
import {MODIFY_STATUS} from "../Actions/ModifyStatus";
import {MODIFY_EDIT_OPTIONS} from "../Actions/ModifyEditOptions";
import {MODIFY_FINAL_COLUMN_PK} from "../Actions/ModifyFinalColumnPK";
import {INIT_MERGED_VALUES} from "../Actions/InitMergedValues";
import {INIT_MERGED_COLUMN_NAMES} from "../Actions/InitMergedColumnNames";
import {MODIFY_EDIT_ID} from "../Actions/ModifyEditID";
import {MODIFY_SHEET_READ} from "../Actions/ModifySheetRead";
import {MODIFY_EDIT_FILE_RESPONSE} from "../Actions/ModifyEditFileResponse";
import {MODIFY_ADD_FILE_NAMES} from "../Actions/ModifyAddFileNames";
import {TOGGLE_TABLE_VIEW} from "../Actions/ToggleTableView";
import {MODIFY_SELECTED_DETAIL_CELL} from "../Actions/ModifySelectedDetailCell";
import {RESET_ALL_STATES} from "../Actions/ResetAllStates";
import {MODIFY_HELP_VIDEO_URL} from "../Actions/ModifyHelpVideoURL";
import {MODIFY_HELP_FAQ} from "../Actions/ModifyHelpFAQ";
import {MODIFY_ALL_INFORMATION} from "../Actions/ModifyAllInformation";
import {TOGGLE_MERGE_TYPE} from "../Actions/ToggleMergeType";
import {MODIFY_REMOVE_DUPLICATES} from "../Actions/ModifyRemoveDuplicates";
import {MODIFY_CURRENCY} from "../Actions/ModifyCurrency";
import {GET_IP_ADDRESS} from "../Actions/GetIPAddress";
import {TOGGLE_SHOW_TUTORIAL} from "../Actions/ToggleShowTutorial";
import {MODIFY_TUTORIAL_STEP} from "../Actions/ModifyTutorialStep";
import {MODIFY_TUTORIAL_INFO} from "../Actions/ModifyTutorialInfo";

function mainState(
    state = {
        filePK: [],
        finalColumnNames: [],
        finalColumnPK: {},
        fileInformation: {},
        editOptions: {},
        modalName: null,
        proBlock: false,
        status: "upload",
        mergedValues: '',
        mergedColumnNames: [],
        editID: null,
        sheetRead: {},
        addFileNames: false,
        removeDuplicates: false,
        tableView: "general",
        selectedDetailCell: {
            finalColIdx: null,
            filePK: null,
            fileColIdx: null
        },
        helpVideoURL: "",
        helpFAQ: {},
        mergeType: "append",
        currency: 'usd',
        showTutorial: false,
        tutorialStep: 0,
        tutorialInfo: {},
        getIPAddress: true
    }, action
) {
    switch (action.type) {
        case MODIFY_FILE_PK:
            return Object.assign({}, state, {
                filePK: action.mainState.filePK
            });
        case MODIFY_FINAL_COLUMN_NAMES:
            return Object.assign({}, state, {
                finalColumnNames: action.mainState.finalColumnNames
            });
        case MODIFY_FILE_INFORMATION:
            return Object.assign({}, state, {
                fileInformation: action.mainState.fileInformation
            });
        case TOGGLE_MODAL:
            return Object.assign({}, state, {
                modalName: action.mainState.modalName
            });
        case TOGGLE_PRO_BLOCK:
            return Object.assign({}, state, {
                proBlock: action.mainState.proBlock
            });
        case TOGGLE_SHOW_TUTORIAL:
            return Object.assign({}, state, {
                showTutorial: action.mainState.showTutorial
            });
        case MODIFY_TUTORIAL_STEP:
            return Object.assign({}, state, {
                tutorialStep: action.mainState.tutorialStep
            });
        case MODIFY_TUTORIAL_INFO:
            return Object.assign({}, state, {
                tutorialInfo: action.mainState.tutorialInfo
            });
        case MODIFY_STATUS:
            return Object.assign({}, state, {
                status: action.mainState.status
            });
        case MODIFY_EDIT_OPTIONS:
            return Object.assign({}, state, {
                editOptions: action.mainState.editOptions
            });
        case MODIFY_FINAL_COLUMN_PK:
            return Object.assign({}, state, {
                finalColumnPK: action.mainState.finalColumnPK
            });
        case INIT_MERGED_VALUES:
            return Object.assign({}, state, {
                mergedValues: action.mainState.mergedValues
            });
        case INIT_MERGED_COLUMN_NAMES:
            return Object.assign({}, state, {
                mergedColumnNames: action.mainState.mergedColumnNames
            });
        case MODIFY_EDIT_ID:
            return Object.assign({}, state, {
                editID: action.mainState.editID
            });
        case MODIFY_SHEET_READ:
            return Object.assign({}, state, {
                sheetRead: action.mainState.sheetRead
            });
        case MODIFY_EDIT_FILE_RESPONSE:
            return Object.assign({}, state, {
                finalColumnNames: action.mainState.finalColumnNames,
                finalColumnPK: action.mainState.finalColumnPK,
                fileInformation: action.mainState.fileInformation
            });
        case MODIFY_ALL_INFORMATION:
            return Object.assign({}, state, {
                finalColumnNames: action.mainState.finalColumnNames,
                finalColumnPK: action.mainState.finalColumnPK,
                fileInformation: action.mainState.fileInformation,
                sheetRead: action.mainState.sheetRead
            })
        case MODIFY_ADD_FILE_NAMES:
            return Object.assign({}, state, {
                addFileNames: action.mainState.addFileNames
            });
        case TOGGLE_TABLE_VIEW:
            return Object.assign({}, state, {
                tableView: action.mainState.tableView
            });
        case MODIFY_SELECTED_DETAIL_CELL:
            return Object.assign({}, state, {
                selectedDetailCell: action.mainState.selectedDetailCell
            });
        case RESET_ALL_STATES:
            return Object.assign({}, state, {
                filePK: [],
                finalColumnNames: [],
                finalColumnPK: {},
                fileInformation: {},
                editOptions: {},
                modalName: null,
                proBlock: false,
                status: "upload",
                mergedValues: '',
                mergedColumnNames: [],
                editID: null,
                sheetRead: {},
                addFileNames: false,
                removeDuplicates: false,
                tableView: "general",
                selectedDetailCell: {
                    finalColIdx: null,
                    filePK: null,
                    fileColIdx: null
                },
                helpVideoURL: "",
                helpFAQ: {},
                mergeType: "append",
            });
        case MODIFY_HELP_VIDEO_URL:
            return Object.assign({}, state, {
                helpVideoURL: action.mainState.helpVideoURL
            });
        case MODIFY_HELP_FAQ:
            return Object.assign({}, state, {
                helpFAQ: action.mainState.helpFAQ
            });
        case TOGGLE_MERGE_TYPE:
            return Object.assign({}, state, {
                mergeType: action.mainState.mergeType
            });
        case MODIFY_REMOVE_DUPLICATES:
            return Object.assign({}, state, {
                removeDuplicates: action.mainState.removeDuplicates
            });
        case MODIFY_CURRENCY:
            return Object.assign({}, state, {
                currency: action.mainState.currency
            })
        case GET_IP_ADDRESS:
            return Object.assign({}, state, {
                getIPAddress: action.mainState.getIPAddress
            })
        default:
            return state
    }
}

export default mainState;

