import React, {Component} from 'react'

class InfoPagesHero extends Component {
    render() {
        const subtitle = 'subtitle' in this.props ?
        <div>
            <h3 className="thinHeading"> {this.props.subtitle}</h3>
            <br/><br/>
        </div> : null;

        return (
            <div id={this.props.id}>
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        <br/><br/>
                        <h1>{this.props.header}</h1>
                        <br/><br/>
                        {subtitle}
                    </div>
                    <div className="col-sm-1" />
                </div>
            </div>
        )
    }
}

export default InfoPagesHero