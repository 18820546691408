import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import ModalApp from '../SharedComponents/Modal'
import SupportForm from "./SupportForm";

class SupportModal extends Component {
    render() {
        return (
            <ModalApp name="supportModal"
                      header={<span><Icon name="help circle" /> Support</span>}
                      body={<div><br/><SupportForm/></div>}
                      size="small"
            />
        )
    }
}

export default SupportModal