import React, {Component} from 'react'
import { Menu, Image } from 'semantic-ui-react'
import images from '../../Constants/Images'
import {connect} from "react-redux";
import {push} from "react-router-redux";

class NavbarMobile extends Component {
    constructor(props){
        super(props);

        this.goToURL = this.goToURL.bind(this);
    }

    goToURL(e){
        e.preventDefault();

        this.props.navigateTo("/")
    }

    render() {
        return (
            <div>
                <Menu secondary stackable>
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                <Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL} size="medium"/>
                            </div>
                        </Menu.Item>
                        {/*<Menu.Item>*/}
                            {/*<NavbarItem to="pricing" name="Pricing" color="black" />*/}
                        {/*</Menu.Item>*/}
                        {/*<Menu.Item>*/}
                            {/*<NavbarItem to="help" name="Help" color="black" />*/}
                        {/*</Menu.Item>*/}
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarMobile)