import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class Accordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }

        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen(){
        const toggleState = !this.state.open;

        this.setState({
            open: toggleState
        })
    }

    render() {
        let body = null;
        let iconName = "plus"

        if (this.state.open){
            body =
                <div className="accordionBody">
                    {this.props.body}
                </div>
            iconName = "minus"
        }

        let iconSize = 'size' in this.props ? this.props.size : 'medium';
        let color = "color" in this.props ? this.props.color : 'black';

        const iconSizeMap = {
            small: <h6 className="thinHeading"><Icon name={iconName} /></h6>,
            medium: <h4 className="thinHeading"><Icon name={iconName} /></h4>,
            large: <h2 className="thinHeading"><Icon name={iconName} /></h2>
        };

        const headingSize = {
            small: <h6 className="thinHeading">{this.props.header}</h6>,
            medium: <h4 className="thinHeading">{this.props.header}</h4>,
            large: <h2 className="thinHeading">{this.props.header}</h2>
        }

        return (
            <div className="accordion" style={this.props.style}>
                <div className="accordionHeader" style={{color: color}} onClick={this.toggleOpen}>
                    <div className="accordionHeaderText">
                        {headingSize[iconSize]}
                    </div>
                    <div className="accordionHeaderIcon">
                        {iconSizeMap[iconSize]}
                    </div>
                </div>
                {body}
            </div>
        )
    }
}

export default Accordion