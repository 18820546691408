import React, {Component} from 'react'
import SignOutButton from '../../Authentication/UserPages/SignOut/SignOutButton'
import { Menu, Image } from 'semantic-ui-react'
import images from "../../Constants/Images";
import NavbarItem from './NavbarItem'
import { push } from 'react-router-redux'
import {connect} from "react-redux";

class NavbarAuth extends Component {
    constructor(props){
        super(props);

        this.goToURL = this.goToURL.bind(this);
    }

    goToURL(e){
        e.preventDefault();

        this.props.navigateTo("/")
    }

    render() {
        return (
            <div>
                <Menu secondary stackable>
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                <Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL} size="small"/>
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="guides" name="Guides" color="black" />
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="FAQ" name="FAQ" color="black" />
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        <Menu.Item>
                            <NavbarItem to="profileModal" name={localStorage.getItem("email")} color="gray" modal/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="supportModal" name="Support" color="gray" modal/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="feedbackModal" name="Feedback" color="gray" modal/>
                        </Menu.Item>
                        <Menu.Item>
                            <SignOutButton style={{marginTop: '5px'}} />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarAuth)