import React, {Component} from 'react'
import ModalApp from "./Modal"
import { connect } from 'react-redux'
import images from "../Constants/Images";
import { Image, Button } from 'semantic-ui-react';
import {toggleModal} from "../Actions/ToggleModal";

class LovePromoModal extends Component {
    goToLoveSpreadsheets = () => window.open("https://www.lovespreadsheets.com/");

    closeModal = () => this.props.toggleModal(null)

    render() {
        return (
            <ModalApp
                name="lovePromoModal"
                header={null}
                body={<div style={{ padding: "24px" }}>
                    <div style={{ marginBottom: "24px" }}>
                        <Image src={images.loveLogo} size="small"/>
                    </div>
                    <div style={{ marginBottom: "24px" }}>
                        <div style={{ fontSize: "18px", color: "#5E645E", marginBottom: "24px"}}>
                            Thanks for your feedback!
                        </div>
                    </div>
                    <div style={{ fontSize: "24px", fontWeight: "700", marginBottom: "16px" }}>
                        Want to get data from databases & APIs in natural language?
                    </div>
                    <div style={{ fontSize: "18px", color: "#5E645E", marginBottom: "24px"}}>
                        Try our new AI data extraction tool for free!
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <Button
                                content="Sure, I would love that 😀"
                                color="green"
                                size="medium"
                                onClick={this.goToLoveSpreadsheets}
                                fluid
                            />
                        </div>
                        <div className="col-sm-6">
                            <Button
                                content="No, I don't like AI 😥"
                                color="red"
                                size="medium"
                                onClick={this.closeModal}
                                fluid
                            />
                        </div>
                    </div>
                </div>}
                size="small"
                noClose
            />
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(LovePromoModal)