import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import {modifyJoinTutorialStep} from "../../Actions/Join/ModifyJoinTutorialStep";

class JoinFileHeader extends Component {
    constructor(props){
        super(props);

        this.goToDownload = this.goToDownload.bind(this);
    }

    goToDownload(){
        window.scrollTo({top: 0, behavior: 'smooth'})
        this.props.modifyJoinTutorialStep(7)
    }

    render() {

        return (
            <div className="row">
                <div className="col-sm-2"/>
                <div className="col-sm-3">
                    <div style={{backgroundColor:'white',padding:'5%',margin:'3%',borderRadius:'10px',textAlign: 'center'}} class="ui raised segment">
                        <h5 className="thinHeading">Join File Sample</h5>
                        <p>{this.props.joinFileMessage}</p>
                        <small><i>*Sample uses first 25 rows of each file</i></small>
                    </div>
                </div>
                <div className="col-sm-1"/>
                <div className="col-sm-4">
                    <div style={{textAlign:'center',padding:'15%',margin:'auto',width:'50$'}}>
                        <Button
                            color="facebook"
                            className="roundedButton"
                            id="goToDownloadBtn"
                            onClick={this.goToDownload}
                            style={{textAlign:'center',width:'125%',fontSize: '125%'}}>
                            <Icon name="download" />Download File
                        </Button>
                    </div>
                </div>
                <div className="col-sm-2"/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    joinPkId: state.joinState.joinPkId,
    joinColumns: state.joinState.joinColumns,
    joinType: state.joinState.joinType,
    joinFilePK: state.joinState.joinFilePK,
    joinFileInformation: state.joinState.joinFileInformation,
    joinFileMessage: state.joinState.joinFileMessage
})

const mapActionsToProps = {
    modifyJoinTutorialStep: modifyJoinTutorialStep
}

export default connect(mapStateToProps, mapActionsToProps)(JoinFileHeader)