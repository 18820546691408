import React, {Component} from 'react'
import { Icon, Button } from 'semantic-ui-react'
import {toggleModal} from "../Actions/ToggleModal";
import { connect } from 'react-redux'
import getCurrencyAmount from "../Constants/getCurrencyAmount";
import pricing from "../Constants/Pricing";

class ProBlockMessage extends Component {
    constructor(props){
        super(props);

        this.state = {
            showMessage: true
        }

        this.dismissMessage = this.dismissMessage.bind(this);
        this.signUpAndPay = this.signUpAndPay.bind(this);
        this.logIn = this.logIn.bind(this);
    }

    signUpAndPay(e){
        this.props.toggleModal("signUpModal");
    }

    logIn(e){
        this.props.toggleModal("loginModal");
    }

    dismissMessage(e){
        this.setState({
            showMessage: false
        })
    }

    render() {
        if (this.state.showMessage){
            let heading = "";

            if (this.props.proBlockType !== null){
                if (this.props.proBlockType === "both"){
                    heading = "Your files > 1MB & You have merged within the last " + Math.ceil(pricing.minutesElapsed / 60).toString() + " hour"
                } else if (this.props.proBlockType === "fileSize"){
                    heading = "Your files are more than 1 MB"
                } else if (this.props.proBlockType === "timeFrame"){
                    heading = "You have merged within the last " + Math.ceil(pricing.minutesElapsed / 60).toString() + " hour"
                }
            }

            return (
                <div className="proBlockMessageBox">
                    <div className="proBlockMessageWarning">
                        <div id="proBlockMessageHeader">
                            <h3><Icon name="warning sign" /> {heading}</h3>
                        </div>
                    </div>
                    <div className="proBlockMessageButton">
                        <Button id="proBlockMessageButton" color="purple" onClick={this.signUpAndPay}
                                style={{height: '90%'}}>
                            <span id="proBlockButtonText">
                                {"Upgrade to Pro for just " + getCurrencyAmount(this.props.currency) + " per month"}
                                <span role="img" aria-label="smile" style={{paddingLeft: '7px'}}>😀</span>
                            </span>
                        </Button>
                    </div>
                    <div className="proBlockSignIn">
                        <h6>You also get FREE access to our other tool <a href="https://www.splitspreadsheets.com" target="_blank" rel="noopener noreferrer">Split Spreadsheets</a></h6>
                        <p className="linkStyle" onClick={this.logIn}>Already a Pro Member? Log In</p>
                    </div>
                </div>
            )
        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ProBlockMessage)