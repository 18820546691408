import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table, Button, Popup } from 'semantic-ui-react'
import {modifyFinalColumnNames} from "../../Actions/ModifyFinalColumnNames";
import {modifyFinalColumnPK} from "../../Actions/ModifyFinalColumnPK";

class ColumnEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deleteOpen: false
        };

        this.moveColumn = this.moveColumn.bind(this);
        this.deleteColumn = this.deleteColumn.bind(this);

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleOpen(e){
        this.setState({ deleteOpen: true })
    }

    handleClose(e){
        this.setState({ deleteOpen: false })
    }

    moveColumn(e, move){
        const copiedFinalNames = JSON.parse(JSON.stringify(this.props.finalColumnNames));
        const currCol = this.props.finalColumnNames[this.props.idx];

        let moveIdx;

        if (move === "right"){
            moveIdx = this.props.idx + 1;
        }
        else{
            moveIdx = this.props.idx - 1;
        }

        const moveCol = this.props.finalColumnNames[moveIdx];

        copiedFinalNames[moveIdx] = currCol;
        copiedFinalNames[this.props.idx] = moveCol;

        this.props.modifyFinalColumnNames(copiedFinalNames);
    }

    deleteColumn(e){
        const copiedFinalNames = JSON.parse(JSON.stringify(this.props.finalColumnNames));
        const copiedColumnPK = JSON.parse(JSON.stringify(this.props.finalColumnPK));
        const columnID = copiedFinalNames[this.props.idx]['id'];

        copiedFinalNames.splice(this.props.idx, 1);
        delete copiedColumnPK[columnID];


        this.props.modifyFinalColumnNames(copiedFinalNames);
        this.props.modifyFinalColumnPK(copiedColumnPK);

        this.handleClose(e);

    }

    render() {
        const leftButton = this.props.idx === 0 ? null :
            <Button basic color='orange' icon='left arrow' onClick={(e) => this.moveColumn(e, "left")} />;
        const rightButton = this.props.idx + 1 === this.props.finalColumnNames.length ? null :
            <Button basic color='green' icon='right arrow' onClick={(e) => this.moveColumn(e, "right")} />;

        return (
            <Table.HeaderCell>
                <div style={{textAlign: 'center'}}>
                <Button.Group>
                    {leftButton}
                    {rightButton}
                    <Popup trigger={<Button basic color="red" style={{color: 'red'}} icon="remove" />}
                           content={
                           <span>
                               <Button color="red" content="Delete Column" onClick={this.deleteColumn} />
                               <Button color="green" content="Keep" onClick={this.handleClose} />
                           </span>}
                           on='click'
                           position='top right'
                           open={this.state.deleteOpen}
                           onClose={this.handleClose}
                           onOpen={this.handleOpen}
                    />
                </Button.Group>
                </div>
            </Table.HeaderCell>
        )
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK
})

const mapActionsToProps = {
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFinalColumnPK: modifyFinalColumnPK
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnEdit)