import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table, Dropdown } from 'semantic-ui-react'
import EditFileReadButton from './EditFileReadButton'
import StartRowOptions from "./StartRowOptions";
import getFileNameNoExtension from "../Constants/getFileNameNoExtension";


class EditTableOptions extends Component {
    constructor(props) {
        super(props);

        if (this.props.editID in this.props.editOptions) {
            const options = this.props.editOptions[this.props.editID];
            const delimiter = this.props.isCSV ? options.delimiter : null;

            this.state = {
                // header: options.header,
                header: true,
                skipRows: "",
                startRow: "",
                // skipRows: options.skipRows,
                // startRow: options.startRow,
                skipRowsError: false,
                startRowError: false,
                delimiter: delimiter,
            }
        }
        else{
            this.state = {
                header: true,
                skipRows: "",
                startRow: "",
                skipRowsError: false,
                startRowError: false,
                delimiter: ",",
            }
        }

        this.changeDelimiter = this.changeDelimiter.bind(this);
        this.handleStartRowChange = this.handleStartRowChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    changeDelimiter(event, {value}){
        this.setState({
            delimiter: value
        })
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleStartRowChange(startRow){
        this.setState({
            startRow: startRow
        })
    }

    createColumnNames(header, startRow){
        let colNames;

        if (!header){
            const colLength = (this.props.fileInformation[this.props.editID].columnNames).length;
            const fileName = getFileNameNoExtension(this.props.fileInformation[this.props.editID].name)

            colNames = [];

            for (let i=1; i<colLength + 1; i++){
                colNames.push(fileName + "_" + i.toString())
            }

        }
        else{
            if (startRow !== "") {
                const startRowInt = parseInt(startRow) - 1;
                let fileSample = this.props.fileInformation[this.props.editID].samples;

                colNames = fileSample[startRowInt];
            }
            else{
                colNames = this.props.fileInformation[this.props.editID].columnNames;
            }
        }

        return colNames
    }


    createTableSample(){
        let fileSample = this.props.fileInformation[this.props.editID].samples;
        if (!this.state.header){
            const colNames = this.props.fileInformation[this.props.editID].columnNames;
            fileSample =  [colNames].concat(fileSample)
        }

        return fileSample
    }

    render() {
        const columnNames = this.createColumnNames(this.state.header, this.state.startRow);
        const tableSample = this.createTableSample();

        const delimiterOptions = [
            {key: 1, text: 'Comma (,)', value: ','},
            {key: 2, text: 'Semi-Colon (;)', value: ';'},
            {key: 3, text: 'Pipe (|)', value: '|'},
            {key: 4, text: 'Tab', value: 'tab'},
            {key: 5, text: 'Space ( )', value: 'space'},
        ];

        const delimiterInput = this.props.isCSV ?
            <div>
                <span className="thinHeading editFileInput">Delimiter {' '}
                <Dropdown
                    inline
                    placeholder="Select Delimiter"
                    name="delimiter"
                    value={this.state.delimiter}
                    options={delimiterOptions}
                    onChange={this.changeDelimiter}
                />
                </span>
            </div> : null;

        const doesFileHaveHeader =
        <div style={{paddingLeft: '50px'}}>
        <input style={{paddingBottom: '5px'}}
               type="checkbox"
               name="header"
               id="header"
               onChange={this.handleInputChange} checked={this.state.header} />
            <label htmlFor="header">
                <b><span className="thinHeading editFileInput" id="editFileCheckLabel">Does File Have a Header?</span></b>
            </label>
        </div>

        return (
            <div>
            <div className="row">
                <div className="col-sm-4">
                    {doesFileHaveHeader}
                </div>
                <div className="col-sm-4">
                    {delimiterInput}
                </div>
                <div className="col-sm-4">
                    <div style={{marginRight: '50px', float: 'right'}}>
                        <EditFileReadButton editOptions={this.state} />
                    </div>
                </div>
            </div>
            <div className="row">
                <StartRowOptions
                                 startRow={this.state.startRow}
                                 handleStartRowChange={this.handleStartRowChange}
                                 tableSample={tableSample}
                                 columnNames={columnNames}
                />
            </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation,
    editOptions: state.mainState.editOptions
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EditTableOptions)