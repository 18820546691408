import React, {Component} from 'react'
import { Menu, Image } from 'semantic-ui-react'
import images from '../../Constants/Images'
import NavbarItem from './NavbarItem'
import NavbarLogIn from './NavbarLogIn'
import {connect} from "react-redux";
import {push} from "react-router-redux";

class NavbarNonAuth extends Component {
    constructor(props){
        super(props);

        this.goToURL = this.goToURL.bind(this);
    }

    goToURL(e){
        e.preventDefault();

        this.props.navigateTo("/")
    }

    render() {
        return (
            <div>
                <Menu secondary stackable>
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                <Image style={{cursor: 'pointer'}} src={images.logoNav} onClick={this.goToURL} size="small"/>
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <small style={{marginTop: '15px'}}>
                            <a
                                href="http://www.lovespreadsheets.com/" target="_blank" rel="noopener noreferrer">
                                A <span role="img" aria-label="heart">❤️</span> Love Spreadsheets product
                            </a>
                            </small>
                        </Menu.Item>
                        {/*<Menu.Item>*/}
                            {/*<NavbarItem to="contact-us" name="Contact" color="black" />*/}
                        {/*</Menu.Item>*/}
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        <Menu.Item>
                            <NavbarItem to="pricing" name="Pricing" color="black" />
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="guides" name="Guides" color="gray" />
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="FAQ" name="FAQ" color="gray" />
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarItem to="feedbackModal" name="Feedback" color="gray" modal/>
                        </Menu.Item>
                        <Menu.Item>
                            <NavbarLogIn/>
                        </Menu.Item>
                        {/*<Menu.Item>*/}
                            {/*<NavbarSignUp />*/}
                        {/*</Menu.Item>*/}
                    </Menu.Menu>
                </Menu>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    navigateTo: push
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarNonAuth)