import React, {Component} from 'react'
import {Button} from "semantic-ui-react";
import {toggleModal} from "../Actions/ToggleModal";
import {connect} from "react-redux";
import {modifyTutorialStep} from "../Actions/ModifyTutorialStep";

class IsSampleCorrect extends Component {
    constructor(props) {
        super(props);
    }

    openNoModal = () => {
        this.props.toggleModal("noModal")
    }

    fileAppendCorrect = () => {
        this.props.modifyTutorialStep(2)
        this.props.scrollToDownload()
    }


    render() {
        if (this.props.tutorialStep === 1) {
            return (
                <div className="ui raised segment">
                    <div className="isSampleCorrect" >
                        <h4 className="thinHeading" style={{textAlign:'center'}}> Is the file appended correctly?</h4>

                        <div style={{textAlign:'center',margin:'auto',width:'50%',padding:'10px'}}>
                            <div className="ui buttons">
                                <button className="big ui green button"
                                        onClick={this.fileAppendCorrect}
                                >

                                    Yes</button>
                                <div className="or"></div>
                                <button className="big ui red button"
                                        onClick={this.openNoModal}
                                >
                                    No
                                </button>
                            </div>
                        </div>


                        {/*<div className="row" >*/}
                        {/*    <div className="col-sm-6" style={{textAlign:'center'}}>*/}

                        {/*        <Button style={{ width: '65%', fontSize: '125%', textAlign: 'center'}}*/}
                        {/*                color='green'*/}
                        {/*                className="yesButton"*/}
                        {/*                onClick={this.fileAppendCorrect}>*/}
                        {/*            Yes </Button>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-sm-6" style={{textAlign:'center'}}>*/}
                        {/*        <Button style={{width: '65%', fontSize: '125%', textAlign: 'center'}}*/}
                        {/*                color="google plus"*/}
                        {/*                className="roundedButton"*/}
                        {/*                onClick={this.openNoModal}>*/}
                        {/*            No </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    tutorialStep:state.mainState.tutorialStep


})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyTutorialStep:modifyTutorialStep,

}

export default connect(mapStateToProps, mapActionsToProps)(IsSampleCorrect)