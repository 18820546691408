import React, {Component} from 'react'
import getCurrencyAmount from "../../../Constants/getCurrencyAmount";
import Accordion from "../../../SharedComponents/Accordion";
import WhatIsJoin from "../../../App/ExplainModals/Join/WhatIsJoin";
import { connect } from 'react-redux'
import NavbarItem from '../../../SharedComponents/Navbar/NavbarItem'

class Join extends Component {
    render() {
        return (
            <div style={{padding: "25px 0 25px 0"}}>
                <h4>1. Upload your files to the tool under the join tab</h4>
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinUpload.gif"
                     alt="JoinUpload"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="join spreadsheet"
                />
                <br/><br/>
                <p>You can always upload unlimited files under a total size of 2 MB.
                    If you want to upload files that have a total size of over 2 MB, you can <b>upgrade to a pro plan for just {getCurrencyAmount(this.props.currency)}</b>.
                    With the pro plan, you can upload however big files you want.
                </p>
                <br/><br/>
                <h4>2. Import the sheets you wish to join</h4>
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinImportSheets.png"
                     alt="JoinUpload"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="join sheets"
                />
                <br/><br/>
                <p>
                    Click <b>File Options</b> under the file where you want to import the sheets.
                    Then click <b>Import Other Sheets</b>.
                </p>
                <p>
                    Check all the sheets you want to import and click <b>Import Sheets</b>
                </p>
                <br/><br/>
                <h4>3. Select the Left file (sheet), Right file (sheet) and Join Type</h4>
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinFiles.gif"
                     alt="JoinFiles"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="merge join"
                />
                <br/><br/>
                <p>
                    You need to select three things in order for the files to join
                </p>
                <ul>
                    <li>
                        <b>Left File</b> when merging Excel sheets all the values on the left side will come from this file (sheet)
                    </li>
                    <li>
                        <b>Right File</b> when merging Excel sheets all the values on the right side will come from this file (sheet)
                    </li>
                    <li>
                        <b>Join Type</b> is to select how the two excel sheets should be combined. It is a slightly advanced topic but nothing you can't wrap your head around with a few examples.
                        <br/><br/>
                        <Accordion
                            header="See Join Type Examples"
                            body={<WhatIsJoin guide/>}
                        />
                    </li>
                </ul>
                <br/><br/>
                <h4>3. Select the column(s) you want to join on</h4>
                <img src="https://media.giphy.com/media/ftr0PaGeJ3kduNSUkE/giphy.gif"
                     alt="JoinFiles"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="merge_excel"
                />
                <br/><br/>
                <p>
                    Finally, you need to select the column(s) whose values you want to join on.
                    You need to pick the column name for both the left and right files.
                    You can pick as many columns as you want.
                    The joining will be done on the values of all the columns selected.
                </p>
                <br/><br/>
                <h4>4. Download your merged excel file</h4>
                <img src="https://media.giphy.com/media/MZjDj90KraFs8K3hyz/giphy.gif"
                     alt="DownloadJoinedExcelFile"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="merge_join_gif"
                />
                <br/><br/>
                <p>
                    Give the file a name and download your merged and joined file.
                </p>
                <br/><br/><br/>
                <h1 className="thinHeading" style={{color: 'purple'}}>Additional Features</h1>
                <br/><br/>
                <h2>Change Column Names</h2>
                <br/>
                <h4>1. Select which row the column names are on</h4>
                <p>
                    Column names are not always on the first row of an Excel sheet, if they even exist.
                </p>
                <p>
                    If they are on another row, then we need to tell the tool which row they are on.
                    This can be done with the <b>Start at Row</b> option.
                </p>
                <p>
                    If there are no headers in the sheet or tab, then you can check the <b>Does File Have a Header?</b> checkbox.
                    <br/><br/>
                    The columns will then be renamed in the format: <b>[Filename]_[Column Number]</b>.
                    <br/>
                    <i>E.g., if [Filename] is <b>Small-Demo-Data</b> then column 1 will be renamed: <b>Small-Demo-Data_1</b>, column 2 will be: <b>Small-Demo-Data_2</b>...</i>
                    <br/><br/>
                    The <b>Sheet Name</b> will not factor in to the renaming of the columns.
                </p>
                <p>
                    These options can be selected in the <b>Read File Options</b> under <b>File Options</b>.
                    If all the files/sheets need to be changed then you can click on <b>Read All File Options</b> over the sample.
                </p>
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/HeaderChange.gif"
                     alt="Header Change Spreadsheets"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="image"
                />
                <br/><br/><br/>
                <h1 className="thinHeading" style={{color: 'purple'}}>Parting Thoughts</h1>
                <p>
                    This is one way of joining spreadsheets. There are some other ways
                    but we believe our tool is the easiest.
                </p>
                <p>
                    If you know Python, we have written another guide on
                    <a href="https://medium.com/@lovespreadsheets/merging-spreadsheets-with-python-join-d53bc701e4ec"> <i>Merging Spreadsheets with Python-Join</i> on our blog at Medium.</a>
                </p>
                <p>
                    Please help us improve our guides and tool by giving us <NavbarItem to="feedbackModal" name="Feedback" color="gray" modal/>
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(Join)