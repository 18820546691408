import React, {Component} from 'react'
import {connect} from 'react-redux'
import getCurrencyAmount from "../../Constants/getCurrencyAmount";
import { Segment, Header, Dropdown } from 'semantic-ui-react'
import {modifyCurrency} from "../../Actions/ModifyCurrency";
import PaymentForm from './PaymentForm'
import CompletedCelebration from './CompletedCelebration'

class SignUpPayModalBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            process: "pay"
        }

        this.changeCurrency = this.changeCurrency.bind(this);
        this.changeProcess = this.changeProcess.bind(this);
    }

    changeCurrency(event, {value}){
        this.props.modifyCurrency(value);
    }

    changeProcess(process){
        this.setState({
            process: process
        })
    }

    render() {
        const currencyAmount = getCurrencyAmount(this.props.currency);

        const currencyOptions = [
            {key: 0, text: 'US Dollar ($)', value: 'usd'},
            {key: 1, text: 'Indian Rupee (₹)', value: 'inr'},
            {key: 2, text: 'British Pound (£)', value: 'gbp'},
            {key: 3, text: 'Australian Dollar (AUD)', value: 'aud'},
            {key: 4, text: 'Brazilian Real (R$)', value: 'brl'},
            {key: 5, text: 'Euro (€)', value: 'eur'},
            {key: 6, text: 'Japanese Yen (¥)', value: 'jpy'},
            {key: 7, text: 'Canadian Dollar (CAD)', value: 'cad'},
            {key: 8, text: 'Polish Zloty (zł)', value: 'pln'},
            {key: 9, text: 'Singapore Dollar (SGD)', value: 'sgd'},
            {key: 10, text: 'Philippine Peso (₱)', value: 'php'},
            {key: 11, text: 'Turkish Lira (₺)', value: 'try'}
        ];

        const payBody =
        <Segment raised>
            <div className="row">
                <div className="col-sm-7">
                    <Header as='h2' style={{marginLeft: '10px'}}>
                        {"Pro Account for " + currencyAmount + " per month"}
                    </Header>
                </div>
                <div className="col-sm-5">
                    <span style={{float: 'right', marginRight: '10px', paddingTop: '7px'}}>
                        <p>Change Currency {''}
                            <Dropdown
                                inline
                                options={currencyOptions}
                                placeholder="Change Currency"
                                name="currency"
                                value={this.props.currency}
                                onChange={this.changeCurrency}
                            />
                        </p>
                    </span>
                </div>
            </div>
            <br/>
            <PaymentForm changeProcess={this.changeProcess}/>
        </Segment>;

        return (
            <div>
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        {this.state.process === "pay" ? payBody : <CompletedCelebration />}
                        <br/>
                    </div>
                    <div className="col-sm-1" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

const mapActionsToProps = {
    modifyCurrency: modifyCurrency
}

export default connect(mapStateToProps, mapActionsToProps)(SignUpPayModalBody)