import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'

class HowToAppend extends Component {
    render() {
        return (
            <div>
                <p className="landingExplainDesc">Simply Upload All Your Files To Append Them</p>
                <p className="landingExplainDesc">The Tool Auto-Magically Merges Files Based on their Column Names</p>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MergeAppendUpload.gif" centered style={{border: '1px lightgray solid'}} size="huge"/>*/}
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MergeAppendUpload.gif" style={{border: '1px lightgray solid', width: '100%'}} alt="AutoMerge Files"/>
            </div>
        )
    }
}

export default HowToAppend