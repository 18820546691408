import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyJoinEditID} from "../../../Actions/Join/ModifyJoinEditID";
import {toggleModal} from "../../../Actions/ToggleModal";
import {Button} from 'semantic-ui-react';

class ImportSheets extends Component {
    constructor(props) {
        super(props);

        this.importSheetsModal = this.importSheetsModal.bind(this);
    }

    importSheetsModal(){
        this.props.toggleModal("joinImportSheets");
        this.props.modifyJoinEditID(this.props.pkID);
    }

    render() {
        return (
            <Button fluid basic icon="file outline" content="Read Another Sheet" onClick={this.importSheetsModal} color="grey"/>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyJoinEditID: modifyJoinEditID
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheets)