import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import {connect} from 'react-redux'
import JoinLandingBody from "./JoinLandingBody";

class JoinExplainModal extends Component {
    render() {
        return (
            <ModalApp name="joinExplainModal"
                      header={null}
                      headerStyle={{textAlign: 'center', backgroundColor: 'whitesmoke'}}
                      size="large"
                      bodyColor="whitesmoke"
                      body={<JoinLandingBody/>}
            />
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(JoinExplainModal)