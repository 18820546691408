import React, {Component} from 'react'
import ExplainTable from '../../App/ExplainModals/ExplainTable'
import Append from "./CombineExcelFiles/Append";
import Join from "./CombineExcelFiles/Join";
import ToggleMergeButton from "./CombineExcelFiles/ToggleMergeButton";
import CommentSection from "./CommentSection";

class CombineExcelFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mergeType: 'append'
        }

        this.changeMergeType = this.changeMergeType.bind(this);
    }

    changeMergeType(mergeType){
        this.setState({
            mergeType: mergeType
        })
    }

    render() {
        const mergeTypeDisplay = {
            append: <Append/>,
            join: <Join/>
        }

        return (
            <div>
                <p>
                    There are many ways to combine multiple excel files.
                    We believe <a href="/">our tool, Merge Spreadsheets, </a> is the easiest and most powerful way to combine spreadsheets.
                </p>
                {/*<p>*/}
                    {/*The video on the right goes through this same post in video form.*/}
                    {/*It is meant to be watched while reading the guide.*/}
                {/*</p>*/}
                <p>You first have to pick how you want to combine the files. You can either <b>append</b> or <b>join</b> files. </p>
                <ul>
                    <li>
                        <h4>Append</h4>
                        <p><i>combine multiple files into 1 based on column names</i></p>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="docSectionPlain">
                                    <h6>File 1.xlsx</h6>
                                    <ExplainTable
                                        headers={['Name', 'DOB']}
                                        rows={[
                                            ['Emma Watson', '1990-04-15'],
                                            ['Ben Franklin', '1706-01-17'],
                                            ['Chris Martin', '1977-03-02']
                                        ]}
                                        cellColor={["black", "black"]}
                                    />
                                    <br/>
                                    <h6>File 2.xlsx</h6>
                                    <ExplainTable
                                        headers={['Name', 'DOB']}
                                        rows={[
                                            ['Michael Scott', '1965-03-15'],
                                            ['Miles Davis', '1926-05-26'],
                                            ['Kalpana Chawla', '1961-07-01']
                                        ]}
                                        cellColor={["black", "black"]}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="docSectionPlain segment-raised">
                                    <h6>Appended File</h6>
                                    <ExplainTable
                                        headers={['Name', 'DOB']}
                                        rows={[
                                            ['Emma Watson', '1990-04-15'],
                                            ['Ben Franklin', '1706-01-17'],
                                            ['Chris Martin', '1977-03-02'],
                                            ['Michael Scott', '1965-03-15'],
                                            ['Miles Davis', '1926-05-26'],
                                            ['Kalpana Chawla', '1961-07-01']
                                        ]}
                                        cellColor={["black", "black"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </li>
                    <br/>
                    <li>
                        <h4>Join</h4>
                        <p><i>combine multiple files into 1 based on similar values in columns</i></p>
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="docSectionPlain">
                                    <h6>File 1.xlsx</h6>
                                    <ExplainTable
                                        headers={['Name', 'DOB']}
                                        rows={[
                                            ['Emma Watson', '1990-04-15'],
                                            ['Ben Franklin', '1706-01-17'],
                                            ['Chris Martin', '1977-03-02']
                                        ]}
                                        cellColor={["black", "black"]}
                                    />
                                    <br/>
                                    <h6>File 2.xlsx</h6>
                                    <ExplainTable
                                        headers={['Name', 'Email']}
                                        rows={[
                                            ['Emma Watson', 'emma@wat.son'],
                                            ['Ben Franklin', 'ben@frank.lin'],
                                            ['Chris Martin', 'chris@mar.tin']
                                        ]}
                                        cellColor={["black", "black"]}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <div className="docSectionPlain segment-raised">
                                    <h6>Joined File</h6>
                                    <ExplainTable
                                        headers={['Name', 'DOB', 'Email']}
                                        rows={[
                                            ['Emma Watson', '1990-04-15', 'emma@wat.son'],
                                            ['Ben Franklin', '1706-01-17', 'ben@frank.lin'],
                                            ['Chris Martin', '1977-03-02', 'chris@mar.tin'],
                                        ]}
                                        cellColor={["black", "black", "black"]}
                                    />
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <br/><br/>
                <h3 className="thinHeading" style={{textAlign: 'center'}}>Choose how you want to combine the files</h3>
                <div className="row">
                    <div className="col-sm-6">
                        <ToggleMergeButton
                            currentMerge={this.state.mergeType}
                            mergeType="append"
                            toggleMergeType={this.changeMergeType}
                            mobile
                        />
                    </div>
                    <div className="col-sm-6">
                        <ToggleMergeButton
                            currentMerge={this.state.mergeType}
                            mergeType="join"
                            toggleMergeType={this.changeMergeType}
                        />
                    </div>
                </div>
                {mergeTypeDisplay[this.state.mergeType]}
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://www.mergespreadsheets.com/guides/how-to-combine-excel-files" data-numposts="5" data-width="" />}/>
            </div>
        )
    }
}

export default CombineExcelFiles