import React, {Component} from 'react'

class ExplainFeature extends Component {
    render() {
        let titleClass = "segment-no-margin";

        if (this.props.mergeType === "append"){
            titleClass += " segment-raised-purple"
        }
        else{
            titleClass += " segment-raised-blue"
        }

        return (
            <div className="docSectionPlain" style={{paddingLeft: '50px', paddingRight: '50px', paddingBottom: '50px'}}>
                <div className={titleClass}>
                    <h2 className="thinHeading" style={{textAlign: 'center'}}>{this.props.header} {this.props.icon}</h2>
                </div>
                <br/><br/>
                {this.props.desc}
            </div>
        )
    }
}

export default ExplainFeature