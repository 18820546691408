import React, {Component} from 'react'
import { connect } from 'react-redux'
import AppendIndex from './AppendIndex'
import JoinIndex from './JoinIndex'
import ToggleMerge from "./ToggleMerge";
import {modifyCurrency} from "../Actions/ModifyCurrency";
import API_Root from "../Constants/API_Root";
import getCurrency from "../Constants/getCurrency";
import {getIPAddress} from "../Actions/GetIPAddress";
import FailedPaymentMessage from "../SharedComponents/FailedPaymentMessage";
import {isEmbed} from "../Constants/isEmbed";

class AppIndex extends Component {
    componentDidMount(){
        if (this.props.getIPAddress){
            fetch("https://api.ipdata.co?api-key=279fd964a008f067fe1b6b5855b155947b4724b16eaf38223b89b77b").then(res => res.json()).then(
                (data) => {
                    const currencyCode = getCurrency(data);
                    this.props.modifyCurrency(currencyCode);
                    this.props.getIPAddress(false);
                }
            ).catch((err) => {this.props.modifyCurrency("usd"); this.props.getIPAddress(false)})
        }

        if (!isEmbed(window.location.href)){
            (function(){
                const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/5e224f95daaca76c6fce9ae8/default';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
            })();
        }
    }

    render() {
        const section = this.props.mergeType === "append" ? <AppendIndex /> : <JoinIndex/>;

        return (
            <div className="app">
                <ToggleMerge />
                {section}
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    mergeType: state.mainState.mergeType,
    getIPAddress: state.mainState.getIPAddress
})

const mapActionsToProps = {
    modifyCurrency: modifyCurrency,
    getIPAddress: getIPAddress
}

export default connect(mapStateToProps, mapActionsToProps)(AppIndex)