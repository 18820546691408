import React, {Component} from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {initJoinDownloadURL} from "../../Actions/Join/InitJoinDownloadURL";
import API_Root from "../../Constants/API_Root";
import totalFileSize from "../../Constants/totalFileSize";
import bytesToMB from "../../Constants/bytesToMB";
import pricing from "../../Constants/Pricing";

class DownloadFileButton extends Component {
    constructor(props) {
        super(props);

        this.downloadFile = this.downloadFile.bind(this);
    }

    downloadFile(e){
        if (this.props.fileName.trim() === ""){
            this.props.updateDownloadStatus("fileNameError")
        }
        else{
            this.props.toggleModal("loadingModalFile");

            const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
            const socket = new WebSocket(websocketRoute + "ws/api/create-joined-file/");

            let filePK = [{}, {}];

            for (let i=0; i<this.props.joinFilePK.length; i++){
                if (this.props.joinFilePK[i].id === this.props.joinPkId[0]){
                    filePK[0] = this.props.joinFilePK[i]
                }

                if (this.props.joinFilePK[i].id === this.props.joinPkId[1]){
                    filePK[1] = this.props.joinFilePK[i]
                }
            }

            socket.onopen = (event) => {
                this.props.updateDownloadStatus("preparing");

                socket.send(JSON.stringify({
                    filePK: filePK,
                    sheetRead: this.props.joinSheetRead,
                    joinType: this.props.joinType,
                    joinColumns: this.props.joinColumns,
                    removeDuplicates: this.props.joinRemoveDuplicates,
                    addFileNames: this.props.joinAddFileNames,
                    editOptions: this.props.joinEditOptions,
                    sample: false,
                    download: true,
                    fileName: this.props.fileName

                }));

                socket.onmessage = (event) => {
                    const reply = JSON.parse(event.data);

                    const status = reply['status'];

                    if (status === "merged"){
                        localStorage.setItem("lastMerged", new Date())
                        this.props.initJoinDownloadURL(reply['url']);
                        this.downloadLink.click();
                        this.props.toggleModal(null);
                        if (!this.props.isUserPro){
                            this.props.toggleModal("feedbackModal")
                        }
                        socket.close()
                    }
                    else{
                        this.props.toggleModal(null);

                        socket.close()
                        alert("Download Failed. Try again")
                    }
                }
            };

        }
    }

    render() {
        const url = this.props.joinDownloadURL;

        return (
            <div>
                <a
                    style={{display: 'none'}}
                    ref={downloadLink => this.downloadLink = downloadLink}
                    href={url}
                >Download hidden</a>
                <Button style={{height: '75%', width: '75%', fontSize: '125%'}}
                        color="green"
                        id="downloadFileButton"
                        className="roundedButton"
                        onClick={this.downloadFile}
                        disabled={this.props.proBlock}>
                    <Icon name="download" />Download File
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    joinPkId: state.joinState.joinPkId,
    joinType: state.joinState.joinType,
    joinColumns: state.joinState.joinColumns,
    joinFilePK: state.joinState.joinFilePK,
    joinSheetRead: state.joinState.joinSheetRead,
    joinEditOptions: state.joinState.joinEditOptions,
    joinFileInformation: state.joinState.joinFileInformation,
    joinDownloadURL: state.joinState.joinDownloadURL,
    toggleModal: state.joinState.toggleModal,
    joinRemoveDuplicates: state.joinState.joinRemoveDuplicates,
    joinAddFileNames: state.joinState.joinAddFileNames,
    isUserPro: state.mainState.proBlock,
});


const mapActionsToProps = {
    toggleModal: toggleModal,
    initJoinDownloadURL: initJoinDownloadURL
}

export default connect(mapStateToProps, mapActionsToProps)(DownloadFileButton)
