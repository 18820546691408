import React, {Component} from 'react'
import ExplainTable from '../ExplainTable'
import { Image } from 'semantic-ui-react'

class WhatIsAppend extends Component {
    render() {
        return (
            <div>
                <p className="landingExplainDesc">Append means to stack multiple files together vertically based on column names</p>
                <br/>
                {/*<Image src="https://media.giphy.com/media/Xzw7iDMCYKI4zZ3JRp/giphy.gif" centered />*/}
                {/*<br/>*/}
                <div className="row">
                    <div className="col-sm-6">
                        <div className="docSectionHover">
                            <p>Let's say you have a file called <b>Customer_Names1.xlsx</b></p>
                            <p>It has 3 rows and 2 columns named <i>Name </i> and <i>DOB</i></p>
                            <ExplainTable
                                headers={['Name', 'DOB']}
                                rows={[
                                    ['Emma Watson', '1990-04-15'],
                                    ['Ben Franklin', '1706-01-17'],
                                    ['Chris Martin', '1977-03-02']
                                ]}
                                cellColor={["black", "black"]}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="docSectionHover">
                            <p style={{textAlign: 'right'}}>And another one called <b style={{color: 'gray'}}>Customer_NAMES_FINAL_2.xlsx</b></p>
                            <p style={{textAlign: 'right'}}>It has 3 rows and 2 columns named <i>Name</i> and <i>Work</i></p>
                            <ExplainTable
                                headers={['Name', 'Work']}
                                rows={[
                                    ['Harry J. Potter', 'Auror'],
                                    ['Momofuku Ando', 'Inventor'],
                                    ['Mary Poppins', 'Nanny']
                                ]}
                                cellColor={["gray", "gray", "gray"]}
                            />
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-12">
                        <p className="landingExplainDesc">Appending both files will create a new file with 6 rows and 3 columns</p>
                        <ExplainTable
                            headers={['Name', 'DOB', 'Work']}
                            rows={[
                                ['Emma Watson', '1990-04-15', ''],
                                ['Ben Franklin', '1706-01-17',''],
                                ['Chris Martin', '1977-03-02', ''],
                                ['Harry J. Potter', '', 'Auror'],
                                ['Momofuku Ando', '','Inventor'],
                                ['Mary Poppins', '','Nanny']
                            ]}
                            cellColor={["#484848", "#484848", "#484848", "#484848", "#484848", "#484848"]}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default WhatIsAppend