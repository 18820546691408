import React, {Component} from 'react'
import UploadFilesIndex from '../Join/UploadFiles/UploadFilesIndex'
import JoinSelectIndex from "../Join/JoinSelect/JoinSelectIndex";
import JoinPreview from "../Join/JoinPreview/JoinPreview";
import DownloadFileIndex from "../Join/Download/DownloadFileIndex";
import DownloadAppMessage from './DownloadApp/DownloadAppMessage'
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux'

class JoinIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            oneMBMessage: false,
            fileNames: [],
            showSampleArrow: false,
        }

        this.modifyOneMBMessage = this.modifyOneMBMessage.bind(this);
        this.modifyFileNames = this.modifyFileNames.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.isTableInViewport = this.isTableInViewport.bind(this);
        this.scrollDown = this.scrollDown.bind(this);
    }

    modifyOneMBMessage(oneMBMessage){
        this.setState({
            oneMBMessage: oneMBMessage
        })
    }

    modifyFileNames(fileNames){
        this.setState({
            fileNames: fileNames
        })
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    isTableInViewport(){
        const tableExist = document.getElementsByClassName("joinSelectIndexHeader").length !== 0;

        if (tableExist){
            const tableElement = document.getElementsByClassName("joinSelectIndexHeader")[0];
            const bounding = tableElement.getBoundingClientRect();

            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            )

        }
        else{
            return false
        }

    }

    handleScroll(){
        const isTableInViewport = this.isTableInViewport()
        const areFilesUploaded = this.props.filePK.length > 0;

        if (!isTableInViewport && areFilesUploaded){
            this.setState({
                showSampleArrow: true
            })
        }
        else{
            this.setState({
                showSampleArrow: false
            })
        }
    }

    scrollDown(){
        this.node.scrollIntoView({behavior: 'smooth'})

    }

    render() {
        const uploadAdj = !this.state.oneMBMessage  ? <DownloadFileIndex /> : <DownloadAppMessage fileNames={this.state.fileNames} modifyOneMBMessage={this.modifyOneMBMessage}/>;

        const areFilesUploaded = this.props.filePK.length > 0;
        const isTableInViewport = this.isTableInViewport();

        let sampleArrow = null;
        if ((!isTableInViewport && areFilesUploaded) || (this.state.showSampleArrow && areFilesUploaded)){
            sampleArrow = <button className="sampleBelowButton" onClick={this.scrollDown}>Join Files <Icon name="arrow down" /></button>
        }

        return (
            <div className="app">
                <br/><br/>
                <div style={{marginBottom: '50px'}}>
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-5">
                            <div className="row">
                                <div className="col-sm-11">
                                    {uploadAdj}
                                </div>
                                <div className="col-sm-1" />
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div style={{float: 'left'}}>
                                <UploadFilesIndex
                                    modifyOneMBMessage={this.modifyOneMBMessage}
                                    modifyFileNames={this.modifyFileNames}
                                    toggleFileUploaded={this.toggleFileUploaded}
                                    scrollDown={this.scrollDown}/>
                            </div>
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div style={{textAlign: 'center', zIndex: '50', marginTop: '-30px'}}>
                        {sampleArrow}
                    </div>
                </div>
                <br/><br/>
                <div style={{marginBottom: '50px'}}>
                    <div ref={node => this.node = node}>
                        <JoinSelectIndex/>
                    </div>
                </div>
                <br/><br/>
                <div style={{marginBottom: '200px'}}>
                    <JoinPreview />
                </div>
                <br/><br/>
                <br/><br/>
                <br/><br/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.joinState.joinFilePK
})

export default connect(mapStateToProps, )(JoinIndex)