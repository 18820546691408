import store from '../../index'
import {toggleIsJoining} from "../../Actions/Join/ToggleIsJoining";
import API_Root from "../../Constants/API_Root";
import {modifyJoinPreview} from "../../Actions/Join/ModifyJoinPreview";


const join = (joinFilePK, joinPkId, sheetRead, joinType, joinColumns, removeDuplicates, addFileNames, editOptions, sample) => {
    store.dispatch(toggleIsJoining(true));

    const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
    const socket = new WebSocket(websocketRoute + "ws/api/create-joined-file/");

    let filePK = [{}, {}];

    for (let i=0; i<joinFilePK.length; i++){
        if (joinFilePK[i].id === joinPkId[0]){
            filePK[0] = joinFilePK[i]
        }

        if (joinFilePK[i].id === joinPkId[1]){
            filePK[1] = joinFilePK[i]
        }
    }

    // Send the PKs and if any column names
    socket.onopen = (event) => {
        socket.send(JSON.stringify({
            filePK: filePK,
            sheetRead: sheetRead,
            joinType: joinType,
            joinColumns: joinColumns,
            removeDuplicates: removeDuplicates,
            addFileNames: addFileNames,
            editOptions: editOptions,
            sample: sample
        }));
    };

    socket.onmessage = (event) => {
        const reply = JSON.parse(event.data);

        const status = reply['status'];

        if (status === "merged"){
            store.dispatch(modifyJoinPreview(reply.joinPreview))

            store.dispatch(toggleIsJoining(false))
            socket.close()

        }

        else if (status === "error"){
            alert("There was an error joining. Try again!")

            store.dispatch(toggleIsJoining(false))

            socket.close()
        }

        else{
            alert("There was an error joining. Try again!")
            socket.close()
        }
    }
}

export default join;