import React, {Component} from 'react'
import ExplainTable from './ExplainTable'
import { Table, Segment } from 'semantic-ui-react'

class JoinColumnModalBody extends Component {
    render() {
        return (
            <div>
                <p>Column names that are identical and joined on get all their values in one column. Column names that are identical otherwise get either a _left or _right added to them depending on which file they came from</p>
                <div className="row">
                    <div className="col-sm-6">
                        <ExplainTable
                            headers={['Name', 'Email']}
                            rows={[
                                ['Brett Favre', 'b@packer.com'],
                                ['Emma Watson', 'e@watson.com'],
                                ['Lebron James', 'l@nike.org']
                            ]}
                            cellColor={["purple", "purple", "purple"]}
                            fileName={<h5>LeftFile.xlsx</h5>}
                        />
                    </div>
                    <div className="col-sm-6">
                        <ExplainTable
                            headers={['Name', 'Email']}
                            rows={[
                                ['Brett Favre', 'bfavre@packer.com'],
                                ['Emma Watson', 'emma@watson.com'],
                                ['Lebron James', 'lbj@nike.org']
                            ]}
                            cellColor={["red", "red", "red"]}
                            fileName={<h5>RightFile.xlsx</h5>}
                        />
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-12">
                        <Segment>
                            <h4 style={{textAlign: 'center'}}>Join both files on Name</h4>
                            <Table celled stripd compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Name</Table.HeaderCell>
                                        <Table.HeaderCell>Email_left</Table.HeaderCell>
                                        <Table.HeaderCell>Email_right</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell style={{color: 'purple'}}>Brett Favre</Table.Cell>
                                        <Table.Cell style={{color: 'purple'}}>b@packer.com</Table.Cell>
                                        <Table.Cell style={{color: 'red'}}>bfavre@packer.com</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={{color: 'purple'}}>Emma Watson</Table.Cell>
                                        <Table.Cell style={{color: 'purple'}}>e@watson.com</Table.Cell>
                                        <Table.Cell style={{color: 'red'}}>emma@watson.com</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell style={{color: 'purple'}}>Lebron James</Table.Cell>
                                        <Table.Cell style={{color: 'purple'}}>l@nike.org</Table.Cell>
                                        <Table.Cell style={{color: 'red'}}>lbj@nike.org</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </div>
                </div>
            </div>
        )
    }
}

export default JoinColumnModalBody