import React, {Component} from 'react'
import CommentSection from "./CommentSection";


class UltimateGuide extends Component {
    render() {
        return (
            <div>
            <h1>What do you even mean by merging spreadsheets?</h1>
                <p>The definition of merging spreadsheets is right in its name — we want to combine two or more spreadsheets into a single spreadsheet. This is a really essential skill to learn for any novice Excel users out there.</p>
            <h1>Interesting… what should I already know?</h1>
                <p>If you know how to make simple tables and organize data in Excel, you’re ready to go! As a general rule of thumb, the first row of data is usually composed of headers and each file (a workbook) can be composed of several pages (spreadsheets).
                </p>
            <h1>So, what exactly is in this article?</h1>
                <p>I’m going to start with the easiest types of spreadsheet merging and then move onto the harder types of merging. After that, I’ll include some common problems I ran into and some other methods of merging spreadsheets that you could use!</p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                <ol>
                    <li>Moving spreadsheets from one workbook to another.</li>
                    <li>Merging all the spreadsheets in one workbook together.</li>
                    <li>Merging one spreadsheet in multiple workbooks together.</li>
                    <li>Merging multiple spreadsheets in multiple workbooks together.</li>
                    <li>Joining spreadsheets with different structures together.</li>
                    <li>Troubleshooting.</li>
                    <li>Other methods.</li>
                </ol>
                </div>
                <br/><br/>
            <h1>Cool, so how do I move a spreadsheet from one workbook into another?</h1>
                <p>Moving a spreadsheet from one workbook to another is really simple. It can also make combining spreadsheets a lot easier in the future if you move everything into one workbook. There are several ways to do this, but I’ll show you what I believe to be the best methods.</p>
                <img src="https://miro.medium.com/max/700/1*p6dAbHewpkTl3mNgXLWTRw.png"
                     alt="Merge Excel Files"
                     style={{margin: '0 auto', display: 'block'}}
                />
                <br/>
                <p><b>I.</b> First, begin by opening all the workbooks you want to edit.</p>
                <p><b>II.</b> Then, select the spreadsheet you want to move by clicking the tab with its name at the bottom (it should become highlighted). If you’d like to select more than one spreadsheet, you can do so by pressing down “Ctrl” while you click more tabs.</p>
                <p><b>III.</b> Now, right-click on your selection and click “Move or Copy”.</p>
                <img src="https://miro.medium.com/max/482/1*muE4uvNvkPfqWbHz0iSklQ.png"
                     alt="Excel Spreadsheet"
                     style={{margin: '0 auto', display: 'block'}}
                />
                <br/><br/>
                <p><b>IV.</b> From there, you can select which workbook you’d like to move your sheet(s) to under “To book:” and where exactly you’d like to insert them under “Before sheet:”.</p>
                <img src="https://miro.medium.com/max/432/1*4yyIPDv5INApoBKfFgFRNA.png"
                     alt="Excel Spreadsheet"
                     style={{margin: '0 auto', display: 'block'}}
                />
                <br/><br/>
                <p><b>V.</b> After you click “Ok”, your sheet(s) will be transported!</p>
            <h1>Okay… now, how do I merge all the spreadsheets in one workbook together?</h1>
                <p>If all your headers are the same, it’s really easy to merge spreadsheets together. Excel allows you to add data from one spreadsheet after the data in another spreadsheet (appending) in several ways. I’ll go through two main methods so that you can decide which one you’d prefer!</p>
                <img src="https://miro.medium.com/max/700/1*OQ4ZfQKSvIMMIO0g85fVKQ.png"
                     alt="Excel Spreadsheet"
                     style={{margin: '0 auto', display: 'block'}}
                />
                <br/><br/>
                <h3>Method One: Using Power Query</h3>
                    <p>Power Query is a great included tool in Excel that allows users to edit and sort spreadsheets. You may also know it as the “Get & Transform Data” tool in the Data tab. It’s included in most newer versions of Excel, but you can download it through <a href="https://www.microsoft.com/en-us/download/details.aspx?id=39379">Microsoft</a> if you don't see it.</p>
                    <p><b>I.</b> First, open a new Excel workbook and make sure you don’t have your workbook of interest opened. This is the easiest method I found for Power Query, but feel free to play around and figure out what works best for you.</p>
                    <p><b>II.</b> Then, under the “Get & Transform Data” section in the Data tab, click <b>“Get Data” → “From File” → “From Workbook”</b> and select your workbook.</p>
                    <img src="https://miro.medium.com/max/663/1*wGIUHV70ySj42lsZWFiaAw.png"
                         alt="Excel Spreadsheet"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>III.</b> Once you’ve opened your file of interest, you’ll be prompted with a Navigator that shows you all the sheets in your workbook. Check the <b>“Select Multiple Items”</b> box and then check off all the spreadsheets you want to merge together.</p>
                    <img src="https://miro.medium.com/max/700/1*a7De3G5ijRTzzAjEzjyQkg.png"
                         alt="Microsoft Excel Spreadsheet"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>IV.</b> After you’ve completed, click <b>“Transform data”.</b></p>
                    <img src="https://miro.medium.com/max/700/1*XTVxu-Fu-sZId6tICgGB3g.png"
                         alt="Microsoft Excel Spreadsheet Transform Data"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>V.</b> Now you’ll have the Power Query Editor opened and we’re almost finished! On the left, you’ll see a list of your sheets under “Queries”, click on the sheet that you’d like to begin with (I typically leave it at whatever it automatically selects). You can also click on each sheet to see what data it includes!</p>
                    <img src="https://miro.medium.com/max/700/1*fy9OKEOdEa7N43-np7fC1g.png"
                         alt="Microsoft Excel Spreadsheet"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>VI.</b> Next, click <b>“Append Queries”</b> under the “Combine” section. If you’re combining two sheets, simply select the sheet that you want to add to the sheet you just selected under “Table to append”. If you’re combining three or more sheets, select the “Three or more tables” option and use the “Add>>” button to choose the specific order you want your final spreadsheet to be in. Once you selected everything, click <b>“Ok”.</b></p>
                    <img src="https://miro.medium.com/max/681/1*2vDFbbCLWZMzeKnKfXVEwg.png"
                         alt="Microsoft Excel Spreadsheet Append Queries"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>VII.</b> Now you should be back at the Power Query Editor again. The table you see on your screen is a preview of your merged spreadsheet! If it looks fine, click on the <b>“Close & Load”</b> button on the left to save your new spreadsheet into your workbook. You’re done!
                    </p>
                    <img src="https://miro.medium.com/max/700/1*CY_-66jaXVoCuNnfcfCBOQ.png"
                         alt="Microsoft Excel Spreadsheet Query Settings"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p>After you’ve done this a couple of times, the steps seem less confusing. I like this method because it’s generally straightforward and included in Excel. However, it can seem a bit intimidating at first. If this isn’t something you’re comfortable learning, try the next method.</p>
                <h3>Method Two: Merge Spreadsheets</h3>
                    <p><b>I.</b> Go to the Merge Spreadsheets <a href="https://www.mergespreadsheets.com" target="_blank">website</a> and upload all your workbooks.</p>
                    <img src="https://miro.medium.com/max/700/1*fvEwYswLZK8JA8HWwOxXdw.png"
                         alt="Merge Spreadsheets"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>II.</b> Then, check and see if all of your sheets of interest are opened. If not, go to “File Options” and click “Import Other Sheets” to choose the proper sheet for each workbook. I found this method to be great if I had more than one spreadsheet in some workbooks that I wanted to merge. There’s also a “Delete File” button if you’d like to exclude a sheet from being merged.</p>
                    <img src="https://miro.medium.com/max/700/1*LaT2pyfiFedQ_EvQrcxbIA.png"
                         alt="Merge Spreadsheets File Options"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>III.</b> Again, you can move around and edit the columns if you’d like. Once you’re satisfied, you can download your spreadsheet at the top right of the page.</p>
                    <p>For me, this method seemed a lot simpler but, they are both approachable and easy to learn.</p>
            <h1>Okay… but could I also merge multiple spreadsheets from multiple workbooks together?</h1>
                <p>Yep! We can combine multiple spreadsheets from different workbooks together using Power Query or Merge Spreadsheets, too!</p>
                <img src="https://miro.medium.com/max/700/1*YxeuZJbOdrZgxaF5Ia9n9w.png"
                     alt="Merge Spreadsheets"
                     style={{margin: '0 auto', display: 'block'}}
                />
                <br/>
                <h3>Method One: Power Query</h3>
                    <p><b>I.</b> Let’s begin by opening all the workbooks you want to merge together.</p>
                    <p><b>II.</b> Then, using the <b>“Move or Copy”</b> tool, move all the spreadsheets of interest into one workbook. As a reminder, you can find this by selecting all the sheets you’d like and right-clicking on them.
                    </p>
                    <img src="https://miro.medium.com/max/482/1*muE4uvNvkPfqWbHz0iSklQ.png"
                         alt="Excel Move or Copy"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>III.</b> Now that we have all of our spreadsheets in the same workbook, we can combine them together as we did before. Just to go over it again, save your current workbook and exit the window. Then, open a new workbook.</p>
                <p><b>IV.</b> Now, go to the Data tab and click <b>“Get Data” → “From File” → “From Workbook”.</b></p>
                    <img src="https://miro.medium.com/max/663/1*wGIUHV70ySj42lsZWFiaAw.png"
                         alt="Excel Spreadsheets"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>V.</b>Then, select your workbook, check the <b>“Select Multiple Items”</b> box, and check off all the spreadsheets you want to merge together.</p>
                    <img src="https://miro.medium.com/max/700/1*a7De3G5ijRTzzAjEzjyQkg.png"
                         alt="Excel Spreadsheets"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>VI.</b> Afterward, click <b>“Transform data”,</b> then click <b>“Append Queries”</b> under the “Combine” section. Select and order the sheets like you would’ve before, then click <b>"Ok".</b></p>
                    <img src="https://miro.medium.com/max/700/1*XTVxu-Fu-sZId6tICgGB3g.png"
                         alt="Excel Spreadsheets Transform Data"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <img src="https://miro.medium.com/max/681/1*2vDFbbCLWZMzeKnKfXVEwg.png"
                         alt="Excel Spreadsheets Append Queries"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>VII.</b> Finally, click <b>“Close & Load”</b> and your merged spreadsheet should be in your new workbook!</p>
                    <img src="https://miro.medium.com/max/700/1*CY_-66jaXVoCuNnfcfCBOQ.png"
                         alt="Excel Spreadsheets Query"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p>If you have a lot of different workbooks you need to combine, only using Excel’s features can be tedious. This method may be fine with a couple of spreadsheets but I would definitely recommend using Merge Spreadsheets when dealing with larger amounts of data to avoid wasting time.</p>
                <h3>Method Two: Merge Spreadsheets</h3>
                    <p><b>I.</b> Like before, go to the Merge Spreadsheets <a href="https://www.mergespreadsheets.com/">website</a> and upload all of the workbooks that you’d like to merge. </p>
                    <p><b>II.</b> Then, under “File Options” and “Import Other Sheets”, select all the sheets in each workbook that you’d like to merge. I found the <b>“Select All”</b> switch to be extremely helpful for this.</p>
                    <img src="https://miro.medium.com/max/700/1*YxeuZJbOdrZgxaF5Ia9n9w.png"
                         alt="Merge Spreadsheets"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>III.</b> Finally, edit the columns if you’d like and download the final file on the top right of the screen.</p>
            <h1>But, what if my data is structured differently in different spreadsheets? (How can I join spreadsheets?)</h1>
                <p>If you have data that may have one common column header (or more) but all other columns are different, you can still join them together! For instance, if you have food names and ID numbers in one sheet but food names and prices in another spreadsheet, they can still be easily merged so that you can match up the ID numbers and prices to each food item! Just like before, you can do this either in Excel or through Merge Spreadsheets.</p>
                <img src="https://miro.medium.com/max/700/1*Y82EH3rlgikejpcNTKkTzQ.png"
                     alt="Merge Spreadsheets"
                     style={{margin: '0 auto', display: 'block'}}
                />
                <br/>
                <h3>Method One: Power Query</h3>
                    <p>Using Power Query for this is still really easy, however, it is a lot more tedious than when we used Power Query for other types of merging (appending).</p>
                    <p><b>I.</b> First, we’ll begin by turning all of our sheets into tables and loading them as a connection. To do this, start by selecting a cell in your first sheet and pressing <b>Ctrl</b> and <b>T</b> at the same time. Then, verify that the range for your table is correct and click “Ok”.</p>
                    <img src="https://miro.medium.com/max/357/1*pbXlVRTWhwR3FMCaxy53bw.png"
                         alt="Excel Spreadsheets"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>II.</b> Next, under the “Get & Transform Data” section, click <b>“From Table/Range”.</b> </p>
                    <img src="https://miro.medium.com/max/681/1*IEpNdEJMiNFImotvA2QH0g.png"
                         alt="Excel Spreadsheets Table Range"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>III.</b> After arriving at the Power Query Editor, click for more options under “Close & Load” and select <b>“Close & Load To”.</b> </p>
                    <img src="https://miro.medium.com/max/406/1*ONnygCfEKIn9hQscG58KsA.png"
                         alt="Excel Spreadsheets Close and Load"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>IV.</b> After that, check <b>“Only Create Connection”</b>and click <b>"Ok".</b> Now, repeat this for every single <b><i>sheet</i></b> except for the last one.</p>
                    <img src="https://miro.medium.com/max/350/1*sYy_eAk0xOHQtcajb8vOuA.png"
                         alt="Excel Spreadsheets Import Data"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>V.</b> Once you’ve arrived at the last sheet, turn it into a table and open it in the Power Query Editor as you did before. Now, instead of closing and loading it, click <b>“Append Queries” </b>and append all the other tables like you normally would for identical headers.</p>
                    <img src="https://miro.medium.com/max/655/1*VdNwQYTU6UIHgq8yUx-rzQ.png"
                         alt="Excel Spreadsheets Append Queries"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>VI.</b> After all the sheets are appended and you’ve returned to the Power Query Editor, click all the column headers that are the same throughout the sheets (press Ctrl while clicking if there’s more than one). Then, right-click and select <b>“Unpivot Other Columns”.</b></p>
                    <img src="https://miro.medium.com/max/665/1*qJ1Fc4mNKGuplCwBbrAtbQ.png"
                         alt="Excel Spreadsheets Unpivot Other Columns"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>VII.</b> Now, select on the column named <b>“Attribute”</b> and go to the Transform tab, where you’ll click <b>“Pivot Column”</b>  in the “Any Column section”. </p>
                    <img src="https://miro.medium.com/max/700/1*04HNetujZCnTecZG_KeLxw.png"
                         alt="ExcelSpreadsheetsPowerQuery"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>VIII.</b> Within the new window, select <b>“Values” </b>under the “Values Column” and select <b>“Don’t Aggregate” </b>under “Aggregate Value Function” in the advanced settings. Finally, click <b>"Ok"</b> to see a preview of your joined spreadsheet.</p>
                    <img src="https://miro.medium.com/max/700/1*XCIbf7Rckfz8WgcoONuPQQ.png"
                         alt="PivotExcel Spreadsheets"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p><b>IX.</b>Once, you’re satisfied with the result, click <b>“Close & Load”</b> to save it to your workbook!</p>
                <h3>Method Two: Merge Spreadsheets</h3>
                    <p>Using Merge Spreadsheets to join spreadsheets gives us many more options in much fewer steps.</p>
                    <p><b>I.</b> First, open the <a href="https://www.mergespreadsheets.com/">website</a> like usual and click on <b>"Join"</b> rather than leaving it at the default “Append” setting. Joining will allow you to combine spreadsheets that may have one column of the same data (or more) while all other columns are different. Unlike using Power Query, all that’s different here compared to appending is a single button.</p>
                    <p><b>II.</b>Then, upload your spreadsheets and choose your join type. I typically choose Full Join (which Power Query will automatically choose for you), but you can play around and look at which preview fits your needs best.</p>
                    <p><b>III.</b>Finally, click <b>“Join Files”</b>and then <b>“Download File”</b> to save your new spreadsheet!</p>
                    <p>I found this method to be much easier and I ran into far fewer problems than using Power Query but, if you’re willing to put in the effort, both methods work fine in the end.</p>
                <br/>
                <hr/>
            <h1>Help, I ran into some problems! (Troubleshooting)</h1>
                <br/>
                <img src="https://miro.medium.com/max/700/0*UCA6Wkcvlmbg6eXB"
                     alt="Frustrated Man"
                     style={{margin: '0 auto', display: 'block'}}
                />
                <br/>
                <p>If you run into problems, let me just say that you aren’t alone. Here are the ones that I ran into during my own merging experience:</p>
                <h4>Wait, I just made a mistake in the Power Query Editor, how can I go back?</h4>
                    <p>The editor lists all of the steps you made on the right of the screen, under “Applied Steps”. To undo your most recent step, simply click on the “X” next to the last step in that list.</p>
                <h4>How can I move or copy spreadsheets with a table? Why isn’t Excel letting me copy them?</h4>
                    <p>For some reason, Excel won’t allow us to use the “Move or Copy” function on sheets that include tables. The easiest fix for this is to click on any cell in your table, then click “Convert to Range” in the Tools section of the Table Design tab. This will turn the table into a normal range and allow you to move it without any problems.</p>
                    <p>If you’d like to change it back into a table after moving it, simply select any cell in the range and press Ctrl and T at the same time. Then, check to make sure the range is correct and select “Ok”.</p>
                <h4>What if I have duplicates? Is there an easy way to remove them?</h4>
                    <p><i>Power Query:</i> Within the Power Query Editor, there is a “Remove Rows” tool under the “Reduce Rows” section. Within that is an option to “Remove Duplicates”, which will look at your first column and only keep the first instance of any identical cell values.</p>
                    <p><i>Merge Spreadsheets: </i> After you’ve uploaded your spreadsheet, you’ll be able to see a “Remove Duplicates” checkbox next to the download options. This will look for identical rows and keep only the first instance.</p>
                <h4>How can I move around my rows or columns?</h4>
                    <p><i>Power Query:</i> To move columns, simply click and drag the column header within the Power Query Editor. To move rows, I found it easiest to click and drag the cells after you’ve closed and loaded the table into your workbook.</p>
                    <p><i>Merge Spreadsheets:</i> To move columns, use the arrow keys under the column names. And, like for Power Query, I found it easiest to move rows in the normal Excel workbook after you’ve downloaded the spreadsheet.</p>
            <h1>Wait, you talked so much about Merge Spreadsheets, but are there other online tools?</h1>
               <p> Of course! There are many tools out there, here are the ones I’ve seen being used the most:</p>
                <div style={{border: '1px solid lightgrey', borderRadius: '15px', padding: '15px'}}>
                    <ol>
                        <li><a href="https://www.ablebits.com/docs/excel-merge-two-tables/">AbleBits Merge Table Wizard</a>- a program that you can download as an Excel add-in with additional features along with merging spreadsheets.</li>
                        <li><a href="https://www.extendoffice.com/product/kutools-for-excel/merge-excel-wordbooks.html">Kutools</a>- another Excel add-in with multiple features including merging spreadsheets.</li>
                        <li><a href="https://www.easydatatransform.com/?AFFILIATE=126684&__c=1">Easy Data Transform</a>- a program that allows you to merge and reformat spreadsheets and tables.</li>
                        <li><a href="https://www.synkronizer.com/">Synkronizer</a>- an Excel add-in for merging spreadsheets.</li>
                        <li><a href="http://www.excel-tool.com/">Excel Tool</a>- a program for merging spreadsheets, along with some other features.</li>
                        <li><a href="https://www.asap-utilities.com/asap-utilities-excel-tools-tip.php?tip=194&utilities=Import">ASAP Utilities</a>- a program for merging different types of files, including spreadsheets.</li>
                    </ol>
                </div>
                <br/>
                <p>The first two choices on this list are what I’ve seen most commonly recommended other than Merge Spreadsheets. However, there are might be many more resources out there, so feel free to do more research on your own!</p>
            <h1>What if I want more customizable options? Is there a more technical option?</h1>
                <img src="https://miro.medium.com/max/700/0*8VRJZE1KH5Zzu4qL"
                     alt="Code"
                     style={{margin: '0 auto', display: 'block'}}
                />
                <br/>
                <p>If you’re into coding, working with Excel in your programs can be really fun! The most common way to connect with Excel is using Visual Basic for Applications (VBA), which is what most Microsoft Office programs are programmed with. There are many resources available online but here’s a <a href="https://excelmacromastery.com/vba-tutorial-1/">great starter.</a></p>
                <p>Another option is using an API. As a starting place, here’s an article about how to <a href="https://apispreadsheets.com/tutorials/what-the-hell-is-an-api"> read spreadsheets using an API in Python.</a> With an API, we’re able to use other languages like Java or R as well!</p>
                <p>There are also some options that’ll focus primarily on coding. You can use <a href="https://medium.com/@lovespreadsheets/merging-spreadsheets-with-python-append-591d599d49da">Python</a> again without an API or try using the new coding favorite, <a href="https://medium.com/@niharika.goel/merge-multiple-csv-excel-files-in-a-folder-using-r-e385d962a90a">R.</a> If you’re into using the <a href="https://webpierat.com/2011/05/23/merging-csv-files-using-the-command-line/">Command Line,</a> you can try that as well! Some other languages, such as <a href="https://dev.to/eiceblue/merge-excel-files-in-java-2lo2#:~:text=A%20quick%20way%20to%20merge,data%20table%20into%20another%20worksheet">Java,</a> will require extra libraries in order to merge spreadsheets.</p>
                <p>If those options don’t seem to fit you, try researching for other options! The possibilities for coding are endless, so you’ll definitely find something that’s perfect for you!</p>
                <br/>

            <h1>Wrapping Everything Up</h1>
                <p>Congratulations, you’ve made it to the end! Now you should know how to merge different types of spreadsheets in the best way for you!</p>
                <p>If you have any further questions don’t hesitate to reach out at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a></p>
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://www.mergespreadsheets.com/guides/ultimate-guide-to-merging-spreadsheets" data-numposts="5" data-width="" />}/>
            </div>
        )
    }
}

export default UltimateGuide