import React, {Component} from 'react'
import ModalApp from '../../../SharedComponents/Modal'
import SignUpPayModalBody from '../../../SharedComponents/Payment/SignUpPayModalBody'

class SignUpModal extends Component {
    render() {
        // const bodyColor = "#0E4D92";
        const bodyColor = "#551A8B";

        return (
            <ModalApp name="signUpModal"
                      bodyColor={bodyColor}
                      header={null}
                      body={<SignUpPayModalBody />}
                      size="large"/>
        )
    }
}

export default SignUpModal