import React, {Component} from 'react'
import {connect} from 'react-redux'
import EditOptions from './EditOptions'
import EditTableOptions from './EditTableOptions'
import getExtension from "../Constants/getExtension";

class EditFileBody extends Component {
    render() {
        const editID = this.props.editID;
        const fileInformation = this.props.fileInformation;

        let isCSV;
        let fileName;

        if (editID !== "all"){
            fileName = fileInformation[editID].name;
            isCSV = getExtension(fileName) === "csv" || getExtension(fileName) === "txt";
        }
        else{
            for (let pkID in fileInformation){
                fileName = fileInformation[pkID].name;
                isCSV = getExtension(fileName) === "csv" || getExtension(fileName) === "txt";
                break;
            }
        }

        // if (editAll){
        return (
            <div className="editFileBody">
                <br/>
                <EditOptions isCSV={isCSV} />
                <br/>
            </div>
        )
        // }
        // else{
        //     return(
        //         <div className="editFileBody">
        //             <br/>
        //             <EditTableOptions isCSV={isCSV} />
        //             <br/>
        //         </div>
        //     )
        // }
    }
}

const mapStateToProps = (state) => ({
    editID: state.mainState.editID,
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(EditFileBody)