import React, {Component} from 'react'
import { Segment } from 'semantic-ui-react'
import {connect} from 'react-redux'
import { Dropdown, Icon } from 'semantic-ui-react'
import {modifyJoinPkId} from "../../Actions/Join/ModifyJoinPkId";
import {modifyJoinType} from "../../Actions/Join/ModifyJoinType";
import ColumnSelectIndex from "./ColumnSelectIndex"
import FileOptions from './FileOptions/FileOptions'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyJoinTutorialStep} from "../../Actions/Join/ModifyJoinTutorialStep";

class JoinSelectIndex extends Component {
    constructor(props){
        super(props);

        this.changeFileName1 = this.changeFileName1.bind(this);
        this.changeFileName2 = this.changeFileName2.bind(this);
        this.modifyJoinType = this.modifyJoinType.bind(this);
        this.openJoinColumnModal = this.openJoinColumnModal.bind(this);

    }


    changeFileName1(event, {value}){
        let updatedFileNames = ["-1", "-2"];

        updatedFileNames[0] = value;
        updatedFileNames[1] = this.props.joinPkId[1];

        this.props.modifyJoinPkId(updatedFileNames);
        this.props.modifyJoinTutorialStep(2)
    }

    changeFileName2(event, {value}){
        let updatedFileNames = ["-1", "-2"];

        updatedFileNames[0] = this.props.joinPkId[0];
        updatedFileNames[1] = value;

        this.props.modifyJoinPkId(updatedFileNames);
        this.props.modifyJoinTutorialStep(3)
    }

    modifyJoinType(event, {value}){
        this.props.modifyJoinType(value);
        this.props.modifyJoinTutorialStep(4)
    }

    openJoinColumnModal(e){
        this.props.toggleModal("joinExplainModal")

    }

    render() {
        if (Object.entries(this.props.joinFileInformation).length !== 0
            && this.props.joinFileInformation.constructor === Object) {

            if (Object.entries(this.props.joinFileInformation).length === this.props.joinFilePK.length){
                let fileNameOptions = [];
                let key = 0;

                for (let pkID in this.props.joinFileInformation){
                    const fileInfo = this.props.joinFileInformation[pkID];
                    let fileName = fileInfo.name;

                    const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";
                    let sheetName = isExcel ? " : " + this.props.joinSheetRead[pkID] : "";

                    fileNameOptions.push({key: key, text: fileName + sheetName, value: pkID})

                    key += 1
                }

                const fileNameValue1 = this.props.joinPkId[0] === "-1" ? null : this.props.joinPkId[0];
                const fileNameValue2 = this.props.joinPkId[1] === "-2" ? null : this.props.joinPkId[1];

                const joinTypeOptions = [
                    {key: 1, text:
                            <div id="joinSelectType">
                                <div id="joinSelectIcon"><span className="joinicons-innerjoin"/> </div>
                                <div id="joinSelectText"><span className="joinSelectText">INNER JOIN</span></div>
                            </div>, value: 'inner'},
                    {key: 2, text: <div id="joinSelectType">
                            <div id="joinSelectIcon"><span className="joinicons-leftjoin"/> </div>
                            <div id="joinSelectText"><span className="joinSelectText">LEFT JOIN</span></div>
                        </div>, value: 'left'},
                    {key: 3, text: <div id="joinSelectType">
                            <div id="joinSelectIcon"><span className="joinicons-rightjoin"/> </div>
                            <div id="joinSelectText"><span className="joinSelectText">RIGHT JOIN</span></div>
                        </div>, value: 'right'},
                    {key: 4, text: <div id="joinSelectType">
                            <div id="joinSelectIcon"><span className="joinicons-fulljoin"/> </div>
                            <div id="joinSelectText"><span className="joinSelectText">FULL JOIN</span></div>
                        </div>, value: 'outer'},
                ];

                const joinType = this.props.joinType === "" ? null : this.props.joinType;

                return (
                    <div className="row">
                        <div className="col-sm-1"/>
                        <div className="col-sm-10">
                            <Segment raised>
                                <h4 className="joinSelectIndexHeader" style={{textAlign: 'center', paddingBottom: '10px', paddingTop: '10px'}}><Icon name="file alternate outline" /> Select the Two Files to Join</h4>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div id="joinFileSelect1Div">
                                            <small><Icon name="file alternate outline" /> <b>Left File Name</b></small>
                                            <Dropdown
                                                placeholder="Select First File"
                                                name="file1"
                                                value={fileNameValue1}
                                                options={fileNameOptions}
                                                onChange={this.changeFileName1}
                                                selection
                                            />

                                            <FileOptions pkID={fileNameValue1}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div id="joinTypeDiv">
                                            <small><b>Join Type</b></small>
                                            <br/>
                                            <Dropdown
                                                placeholder="Select Join Type"
                                                name="joinType"
                                                value={joinType}
                                                options={joinTypeOptions}
                                                onChange={this.modifyJoinType}
                                                selection
                                            />
                                            <br/>
                                            <small style={{textAlign:'center'}} className="linkStyle" onClick={this.openJoinColumnModal}>What do these join mean?</small>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div id="joinFileSelect2Div">
                                            <small><b>Right File Name< Icon name="file alternate outline" /></b></small>
                                            <Dropdown
                                                placeholder="Select Second File"
                                                name="file2"
                                                value={fileNameValue2}
                                                options={fileNameOptions}
                                                onChange={this.changeFileName2}
                                                selection
                                            />
                                            <FileOptions pkID={fileNameValue2}/>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <ColumnSelectIndex />
                            </Segment>
                        </div>
                        <div className="col-sm-1"/>
                    </div>
                )
            }
            else{
                return(
                    null
                )
            }

        }
        else{
            return(
                null
            )
        }
    }
}


const mapStateToProps = (state) => ({
    joinFileInformation: state.joinState.joinFileInformation,
    joinPkId: state.joinState.joinPkId,
    joinFilePK: state.joinState.joinFilePK,
    joinType: state.joinState.joinType,
    joinSheetRead: state.joinState.joinSheetRead
})

const mapActionsToProps = {
    modifyJoinPkId: modifyJoinPkId,
    modifyJoinType: modifyJoinType,
    toggleModal: toggleModal,
    modifyJoinTutorialStep: modifyJoinTutorialStep
}

export default connect(mapStateToProps, mapActionsToProps)(JoinSelectIndex)