import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import PreviewModalBody from "./PreviewModalBody";

class PreviewModal extends Component {
    render() {
        return (
            <ModalApp name="previewModal"
                      header="File Preview"
                      size="large"
                      body={<PreviewModalBody/>}
            />
        )
    }
}

export default PreviewModal