import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {modifyFinalColumnNames} from "../../../Actions/ModifyFinalColumnNames";
import {modifyFinalColumnPK} from "../../../Actions/ModifyFinalColumnPK";
import {modifyFilePK} from "../../../Actions/ModifyFilePK";
import {modifyFileInformation} from "../../../Actions/ModifyFileInformation";
import API_Root from "../../../Constants/API_Root";
import {modifySheetRead} from "../../../Actions/ModifySheetRead";

class RemoveFile extends Component {
    constructor(props) {
        super(props);

        this.removeFile = this.removeFile.bind(this);
        this.deleteServerFile = this.deleteServerFile.bind(this);
        this.deleteFromColumns = this.deleteFromColumns.bind(this);
        this.deleteSheetsRead = this.deleteSheetsRead.bind(this);
        this.toggleProBlock = this.toggleProBlock.bind(this);
    }

    removeFile(){
        const pkID = this.props.pkID;
        const pk = this.props.pk;

        const isExcel = this.props.isExcel;

        let deleteServerFile = true;

        // Figure out whether to delete the file from the server or not and delete any sheets read
        if (isExcel){
            let countPK = 0;
            for (let i=0; i<this.props.filePK.length; i++){
                const currPK = this.props.filePK[i]['pk'];

                if (currPK === pk){
                    countPK += 1
                }
            }

            if (countPK > 1){
                deleteServerFile = false
            }

            // Delete from sheets read
            this.deleteSheetsRead(pk, pkID)
        }

        // Delete the file from the server
        if (deleteServerFile){
            this.deleteServerFile(pk)
        }

        // Delete the idx of filePK and update
        const removedFilePK = JSON.parse(JSON.stringify(this.props.filePK));
        removedFilePK.splice(this.props.idx, 1);

        this.props.modifyFilePK(removedFilePK);

        // Delete the file information associated to this
        const copiedFileInformation = JSON.parse(JSON.stringify(this.props.fileInformation));

        delete copiedFileInformation[pkID];

        this.props.modifyFileInformation(copiedFileInformation);

        // Delete from columns
        this.deleteFromColumns(pkID)

    }

    deleteServerFile(pk){
        fetch(API_Root + "api/delete-merge-file/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                pk: pk
            })
        }).then(res => {
        }).catch();
    }

    toggleProBlock(deletedFileInformation){
        
    }

    deleteFromColumns(pkID){
        // Deep copy both final column PK and the final column Names
        const copiedFinalColumnPK = JSON.parse(JSON.stringify(this.props.finalColumnPK));
        let finalColumnNames = this.props.finalColumnNames;

        for (let colID in copiedFinalColumnPK){
            const colIDInfo = copiedFinalColumnPK[colID]
            const filePK = colIDInfo['filePK'];
            const fileColIdx = colIDInfo['fileColIdx'];

            if (filePK.includes(pkID)){
                const idx = filePK.indexOf(pkID);

                // Delete the instance of the file pk ID and the file col idx corresponding to it
                filePK.splice(idx, 1);
                fileColIdx.splice(idx, 1);

                // If there is nothing left, delete the column associated to it and the whole key as well
                if (filePK.length === 0){
                    // Delete the Column PK Information

                    delete copiedFinalColumnPK[colID]

                    // Delete the Column Name information
                    let updatedColumnNames = [];

                    for (let i=0; i < finalColumnNames.length; i++){
                        const columnNameInfo = finalColumnNames[i];

                        if (colID !== columnNameInfo['id']){
                            updatedColumnNames.push(columnNameInfo)
                        }
                    }
                    finalColumnNames = updatedColumnNames
                }
                else{
                    colIDInfo['filePK'] = filePK;
                    colIDInfo['fileColIdx'] = fileColIdx;

                    copiedFinalColumnPK[colID] = colIDInfo
                }
            }
        }

        // Update redux with new values
        this.props.modifyFinalColumnPK(copiedFinalColumnPK);
        this.props.modifyFinalColumnNames(finalColumnNames);

    }

    deleteSheetsRead(pk, pkID){
        const removedSheets = JSON.parse(JSON.stringify(this.props.sheetRead));
        const sheetName = removedSheets[pkID];

        delete removedSheets[pkID];

        const pkSheets = removedSheets[pk];

        pkSheets.splice(pkSheets.indexOf(sheetName), 1);

        removedSheets[pk] = pkSheets;

        this.props.modifySheetRead(removedSheets);

    }

    render() {
        return (
            <Popup
                trigger={
                    <Button basic color='red' icon='trash alternate outline' content="Delete File"/>
                }
                content={
                    <div style={{textAlign: 'center'}}>
                        <h5 className="thinHeading">Are you sure you want to delete this file?</h5>
                        <Button color='red' content='Delete File' onClick={this.removeFile} />
                    </div>}
                on='click'
                position='top right'
            />
        )
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK,
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFinalColumnPK: modifyFinalColumnPK,
    modifyFilePK: modifyFilePK,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead
}

export default connect(mapStateToProps, mapActionsToProps)(RemoveFile)