import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown, Popup, Button } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";

class CancelAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            canceling: false,
            reason: null,
            popupOpen: false
        }

        this.changeReason = this.changeReason.bind(this);
        this.cancelAccount = this.cancelAccount.bind(this);

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
    }

    handleOpen(e){
        this.setState({ popupOpen: true })
    }

    handleClose(e){
        this.setState({ popupOpen: false })
    }

    changeReason(event, {value}){
        this.setState({ reason: value})
    }

    cancelAccount(){
        this.setState({ canceling: true })

        fetch(API_Root + "api/cancel-merge-customer/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token"),
                reason: this.state.reason.toString()
            })
        }).then(res => {
            alert("Account Canceled"); window.location.reload(); this.setState({canceling: false});
        }).catch(err => {
            alert("Account Canceled"); window.location.reload(); this.setState({canceling: false});
        });
    }

    render() {
        const delimiterOptions = [
            {key: 1, text: "I don't use this anymore", value: 'no-more-user'},
            {key: 2, text: "it's too expensive", value: 'too-expensive'},
            {key: 3, text: "it's not helpful", value: 'not-helpful'},
            {key: 4, text: "I started merging another way", value: 'another-method'},
            {key: 5, text: "just because", value: 'no-reason'},
        ];

        return (
            <div>
                <span className="">I am canceling because
                    {' '}
                    <Dropdown
                        placeholder="....select a reason"
                        name="reason"
                        value={this.state.reason}
                        options={delimiterOptions}
                        onChange={this.changeReason}
                        inline
                    />
                </span>
                <br/><br/>
                <Popup
                    trigger={<Button content="Cancel Account" color="red" icon="user" disabled={this.state.reason === null || !this.props.canCancel} loading={this.state.canceling}/>}
                    content={<div style={{textAlign: 'center'}}>
                        <h5 className="thinHeading">Are you sure you want to cancel?</h5>
                        <span>
                           <Button color="red" content="Cancel 😢️" onClick={this.cancelAccount} />
                            <br/><br/>
                           <Button color="green" content="Don't Cancel 😀" onClick={this.handleClose} />
                       </span>
                    </div>}
                    on='click'
                    position='top right'
                    open={this.state.popupOpen}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(CancelAccount)