import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'

class ExplainTable extends Component {
    render() {
        const tableHeaders = this.props.headers.map((x, idx) => <Table.HeaderCell key={idx}>{x}</Table.HeaderCell>)
        const tableRows = this.props.rows.map((x, idx) =>
            <Table.Row key={idx}>
                {x.map((y, i) => {
                    const cellColor = this.props.cellColor[idx];
                    return <Table.Cell style={{color: cellColor}}>{y}</Table.Cell>})}
            </Table.Row>)


        return (
            <Table celled striped compact>
                <Table.Header>
                    <Table.Row>
                        {tableHeaders}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {tableRows}
                </Table.Body>
            </Table>
        )
    }
}

export default ExplainTable