import React, {Component} from 'react'
import {authorDetails} from "./authorDetails";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

class GuidesCard extends Component {
    render() {
        const writtenBy = <span><Image src={authorDetails[this.props.tutorialInfo.writtenBy]['pic']} avatar /> <a href={authorDetails[this.props.tutorialInfo.writtenBy]['social']} target="_blank" rel="noopener noreferrer">{this.props.tutorialInfo.writtenBy}</a></span>
        const writtenDt = <span><i>{this.props.tutorialInfo.date}</i></span>


        return (

            <div className="guidesCard">
                <a style={{textDecoration:"none",color:"black"}} href={"/guides/" + this.props.url + ".html"}>
                    <div>
                        <h4> {this.props.header} </h4>
                        <img className="guidesCardImg" src={this.props.img} alt="merged files"/>
                        <br/><br/>
                        {this.props.desc}
                        <br/><br/>
                        <div style={{paddingTop: '10px'}}>{writtenBy} {writtenDt}</div>
                    </div>
                </a>
            </div>

        )
    }

}


export default GuidesCard