import React, {Component} from 'react'
import { Menu, Icon, Input } from 'semantic-ui-react'

class HelpMenu extends Component {
    constructor(props) {
        super(props);

        this.changeMenuItem = this.changeMenuItem.bind(this);
    }

    changeMenuItem(e, {name}){
        this.props.changeHelpMenu(name)
    }

    render() {
        const topicMenu = [
            {name: 'upload', icon: 'upload', desc: 'Upload Files'},
            {name: 'read', icon: 'book', desc: 'Read the Files'},
            {name: 'append', icon: 'th', desc: 'Append the Files'},
            {name: 'downloadAppend', icon: 'download', desc: 'Download Appended File'},
            {name: 'join', icon: 'th list', desc: 'Join the Files'},
            {name: 'downloadJoin', icon: 'download', desc:'Download Joined File'}
        ]

        const menuItems = topicMenu.map((x, idx) =>
            <Menu.Item
            name={x.name}
            active={this.props.topic === x.name}
            onClick={this.changeMenuItem}
            key={idx}
            >
                <Icon name={x.icon} /> {x.desc}
            </Menu.Item>
        )

        return (
            <div style={{paddingLeft: '50px'}}>
                {/*<Input size='medium' icon='search' placeholder='Search Help...' />*/}
                <Menu pointing secondary vertical>
                    {menuItems}
                </Menu>
            </div>
        )
    }
}

export default HelpMenu