import React, {Component} from 'react'
import API_Root from "../Constants/API_Root";
import { Icon } from 'semantic-ui-react';

class FeedbackDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            avgFeedback: 4.56,
            totalCount: 47
        }
    }

    componentDidMount(){
        fetch(API_Root + "api/get-merge-feedback/").then(res => {
            if (res.status === 200){
                res.json().then(data => this.setState({ avgFeedback: data.feedback, totalCount: data.totalCount }))
            }
        }).catch();
    }

    render() {
        let stars = [];

        for (let i=0; i<this.state.avgFeedback; i++){
            if (this.state.avgFeedback - i < 1 && this.state.avgFeedback - i > 0){
                stars.push(<Icon name="star half" style={{color: 'purple'}} key={i.toString()}/>)
            }
            else {
                stars.push(<Icon name="star" style={{color: 'purple'}} key={i.toString()}/>)
            }
        }


        return (
            <div style={{textAlign: 'center'}}>
                <h5>{this.state.totalCount.toString()} people rated us</h5>
                <h2>{stars}</h2>
                <h4><b>{this.state.avgFeedback.toString()}</b> out of <b>5</b></h4>
            </div>
        )
    }
}

export default FeedbackDisplay