import React, {Component} from 'react'
import {connect} from 'react-redux'
import ColumnSelectRow from './ColumnSelectRow'
import {modifyJoinColumns} from "../../Actions/Join/ModifyJoinColumns";
import { Button, Divider, Popup, Icon} from 'semantic-ui-react';
import JoinButton from './JoinButton'
import {modifyJoinTutorialStep} from "../../Actions/Join/ModifyJoinTutorialStep";

class ColumnSelectIndex extends Component {
    constructor(props) {
        super(props);

        this.addColumnRow = this.addColumnRow.bind(this);
        this.scrollDown = this.scrollDown.bind(this);
    }

    addColumnRow(){
        const file1SelectedColumns = JSON.parse(JSON.stringify(this.props.joinColumns['file1']));
        const file2SelectedColumns = JSON.parse(JSON.stringify(this.props.joinColumns['file2']));

        const updated1 = file1SelectedColumns.concat([0]);
        const updated2 = file2SelectedColumns.concat([0]);

        this.props.modifyJoinColumns({"file1": updated1, "file2": updated2})



    }
    scrollDown(){
        this.node.scrollIntoView({behavior: 'smooth'})
    }



    render() {
        if (this.props.joinPkId[0] !== "-1" && this.props.joinPkId[1] !== "-2"){
            const columnName1 = this.props.joinFileInformation[this.props.joinPkId[0]].columnNames;
            const columnName2 = this.props.joinFileInformation[this.props.joinPkId[1]].columnNames;

            const file1ColumnOptions = columnName1.map((x, idx) => {return {key: idx, text: x, value: idx}});
            const file2ColumnOptions = columnName2.map((x, idx) => {return {key: idx, text: x, value: idx}});

            let columnSelectRow = [];

            const file1SelectedColumns = this.props.joinColumns['file1'];
            const file2SelectedColumns = this.props.joinColumns['file2'];

            const selectedLen = file2SelectedColumns.length;

            for (let i=0; i<file1SelectedColumns.length; i++){
                columnSelectRow.push(<ColumnSelectRow idx={i}
                                                      key={i}
                                                      file1Idx={file1SelectedColumns[i]}
                                                      file2Idx={file2SelectedColumns[i]}
                                                      file1ColumnOptions={file1ColumnOptions}
                                                      file2ColumnOptions={file2ColumnOptions}
                                                      selectedLen={selectedLen}
                />)
            }

            return (
                <div>
                    <div style={{margin: '0 auto', width: '75%'}}>
                        <Divider/>
                        <h4 style={{textAlign: 'center', paddingBottom: '10px', paddingTop: '10px'}}><Icon name="columns" /> Select Columns to Join on</h4>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div id="columnFile1">
                                <small><b><Icon name="columns" /> Left File Columns</b></small>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div id="removeColumnRow">

                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div id="columnFile2">
                                <small><b>Right File Columns <Icon name="columns" /></b></small>
                            </div>
                        </div>
                    </div>
                    {columnSelectRow}
                    <div style={{float: 'right', marginTop: '5px'}}>
                        <Popup
                            trigger={
                                <Button basic color="green" circular icon='plus' onClick={this.addColumnRow}/>
                            }
                            content="Add Column Pair"
                            position='top right'
                        />
                    </div>
                    <br/>
                    <JoinButton scrollDown={this.scrollDown}/>
                </div>
            )
        }
        else{
            return(
                null
            )
        }
    }
}

const mapStateToProps = (state) => ({
    joinPkId: state.joinState.joinPkId,
    joinFileInformation: state.joinState.joinFileInformation,
    joinColumns: state.joinState.joinColumns
})

const mapActionsToProps = {
    modifyJoinColumns: modifyJoinColumns,
    modifyJoinTutorialStep: modifyJoinTutorialStep

}

export default connect(mapStateToProps, mapActionsToProps)(ColumnSelectIndex)