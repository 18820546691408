import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifyEditID} from "../../../Actions/ModifyEditID";
import {Button} from 'semantic-ui-react';

class ImportSheets extends Component {
    constructor(props) {
        super(props);

        this.importSheetsModal = this.importSheetsModal.bind(this);
    }

    importSheetsModal(){
        this.props.toggleModal("importSheets");
        this.props.modifyEditID(this.props.pkID);
    }

    render() {
        return (
            <Button basic icon="file outline" content="Import Other Sheets" onClick={this.importSheetsModal} color="grey"/>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheets)