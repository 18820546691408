import React, {Component} from 'react'
import {Button, Icon} from "semantic-ui-react";
import Accordion from "../SharedComponents/Accordion";
import ModalApp from "../SharedComponents/Modal";
import WhatIsJoin from "../App/ExplainModals/Join/WhatIsJoin";
import {toggleModal} from "../Actions/ToggleModal";
import {connect} from "react-redux";

class NoModalBody extends Component {
    constructor(props) {
        super(props);
        this.signUpAndPay = this.signUpAndPay.bind(this);
    }

    signUpAndPay(e){
        this.props.toggleModal("signUpModal");
    }

    render() {
        return (
            <div style={{padding: '15px'}}>
                <div className="segment-raised" style={{backgroundColor:'whitesmoke',padding:'10px',borderRadius:'5px',textAlign:'left',margin:'auto'}}>
                    <div className="segment-raised" style={{backgroundColor:'#2d1538',padding:'10px',borderRadius:'5px',textAlign:'left',margin:'auto',color:'white'}}>
                        <h2 style={{color:'white'}}> Read Column Names Properly </h2>
                    </div>
                    <br/>
                    <p style={{fontSize:'130%'}}> Our algorithm merges files automatically by looking at column names. Make sure yours are read properly. </p>
                    <br/>
                    <div className="segment" style={{backgroundColor:'#6a5173',padding:'10px', margin: "0", borderRadius:'5px',textAlign:'left',color:'white'}}>
                        <h4> How to Change Column Names Properly </h4>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="segment-raised" style={{padding:'10px',borderRadius:'5px',backgroundColor:'white',height:'400px'}}>
                                <h4 style={{textAlign:'center'}}> If all your files have the same extension </h4>
                                <p style={{fontSize:'120%'}}> Click the button <b> Read All Files Options </b>. It will right above the merged sample </p>
                                <p style={{fontSize:'120%'}}> Same extension means your files are all the same type. For example, all files are <b>.xlsx</b> or all files are <b>.csv</b> </p>
                                <img style={{width:'100%'}} src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/ReadAllOptionsButton.gif"/>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="segment-raised" style={{padding:'10px',borderRadius:'5px',backgroundColor:'white',height:'400px'}}>
                                <h4 style={{textAlign:'center'}}> If your files have different extensions </h4>
                                <p style={{fontSize:'120%'}}> 1. Go to the file </p>
                                <p style={{fontSize:'120%'}}> 2. Click on <b>File Options</b> dropdown</p>
                                <p style={{fontSize:'120%'}}> 3. Click on <b>File Options</b> Button </p>
                                <img style={{width:'100%'}} src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/IndividualFilesDemo.gif"/>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="segment" style={{backgroundColor:'#6a5173',padding:'10px', margin: "0", borderRadius:'5px',textAlign:'left',color:'white'}}>
                        <h4> Finish Changing Column Names Depending on Your File Layout </h4>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="segment-raised" style={{padding:'10px',borderRadius:'5px',backgroundColor:'white'}}>
                                <h4 style={{textAlign:'center'}}> If Your File Has Column Names </h4>
                                <img style={{display:'block',marginLeft:'auto',marginRight:'auto'}}
                                     src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/FileWithColNames.png"/>
                                <br/>
                                <p style={{fontSize:'120%'}}> 1. In <b>Start at Row</b> put the row number of the column names. In this case, it would be row 3  </p>
                                <p style={{fontSize:'120%'}}> 2. Make sure <b> Does File Have a Header?</b> is selected </p>
                                <br/>
                                <img style={{display:'block',marginLeft:'auto',marginRight:'auto',width:'85%',border:'1px solid'}}
                                     src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/FileOptionsYesColumn.png"/>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="segment-raised" style={{padding:'10px',borderRadius:'5px',backgroundColor:'white'}}>
                                <h4 style={{textAlign:'center'}}> If Your File Has No Column Names </h4>
                                <img style={{display:'block',marginLeft:'auto',marginRight:'auto'}}
                                     src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/FileWithNOColNames.png"/>
                                <br/>
                                <p style={{fontSize:'120%'}}> 1. In <b>Start at Row</b> put the row number of where the data starts. In this case, it would be row 2 </p>
                                <p style={{fontSize:'120%'}}> 2. Make sure <b> Does File Have a Header?</b> is <span style={{color:'red'}}> NOT</span> selected </p>
                                <br/>
                                <img style={{display:'block',marginLeft:'auto',marginRight:'auto',width:'85%',border:'1px solid'}}
                                     src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/FileOptionsNoColumn.png"/>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <Accordion
                        style={{backgroundColor: "white"}}
                        header={<h4>Additional Problems for CSV Files</h4>}
                        body={
                            <div style={{padding:'10px',borderRadius:'5px',backgroundColor:'white'}}>
                                <p style={{fontSize:'120%'}}> If you uploaded CSV files, there will be an option to select the Delimiter in <b> Read All Files Options </b> if it is not a comma</p>
                                <img
                                    style={{display:'block',marginLeft:'auto',marginRight:'auto',border:'1px solid',width:'50%'}}
                                    src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/CSVFileProbs.png"/>
                            </div>
                        }
                    />
                    <br/>
                </div>
                <br/><br/>

                <div className="segment-raised" style={{backgroundColor:'whitesmoke',padding:'10px',borderRadius:'5px',textAlign:'left',margin:'auto'}}>
                    <div className="segment-raised" style={{backgroundColor:'#2d1538',padding:'10px',borderRadius:'5px',textAlign:'left',margin:'auto',color:'white'}}>
                        <h2> Manually Edit Columns </h2>
                    </div>
                    <br/>
                    <p style={{fontSize:'120%'}}> If the merge sample is looking completely off, you can manually rearrange the merging.</p>
                    <p style={{fontSize:'120%'}}> To do this, you press the <b> Detailed View</b> button <Button color="violet"><Icon name={"columns"}/> Detailed View </Button> </p>
                    <br/>
                    <div className="segment-raised" style={{padding:'10px',borderRadius:'5px',backgroundColor:'white'}}>
                        <h3> Move Columns Within File </h3>
                        <p style={{fontSize:'120%'}}> In the file <b>ActorsInfo</b> there is a column called "Full Name", while in <b>SingersInfo</b> it is just "Name".  </p>
                        <p style={{fontSize:'120%'}}> Since these columns names are different, they will not match up. In order to make them: </p>
                        <p style={{fontSize:'120%'}}> 1. Click on <b>Detailed View</b> </p>
                        <p style={{fontSize:'120%'}}> 2. Click on the column you want to move </p>
                        <p style={{fontSize:'120%'}}> 3. Click on the new column you want to move it to </p>
                        <img style={{width:'70%',display:'block',marginLeft:'auto',marginRight:'auto',paddingBottom:'5px'}} src={"https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/ManuallyEditColumns/Move+Columns+Around.gif"}/>
                        <br/>
                    </div>
                    <br/>
                    <div className="segment-raised" style={{padding:'10px',borderRadius:'5px',backgroundColor:'white'}}>
                        <h3> Delete Columns </h3>
                        <p style={{fontSize:'120%'}}> Now the "Name" column from the previous example is empty </p>
                        <p style={{fontSize:'120%'}}> 1. To delete it, press it the <b>X</b> button </p>
                        <img style={{width:'70%',display:'block',marginLeft:'auto',marginRight:'auto',paddingBottom:'5px'}} src={"https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/ManuallyEditColumns/Delete+Column.gif"}/>
                        <br/>
                    </div>
                    <br/>
                    <div className="segment-raised" style={{padding:'10px',borderRadius:'5px',backgroundColor:'white'}}>
                        <h3> Rename Columns </h3>
                        <p style={{fontSize:'120%'}}> I want to rename "Full Name" to "Customer Names" </p>
                        <p style={{fontSize:'120%'}}> 1. Go to the column name and click to edit it </p>
                        <p style={{fontSize:'120%'}}> 2. Press enter or click outside the box and it will be saved </p>
                        <img style={{width:'70%',display:'block',marginLeft:'auto',marginRight:'auto',paddingBottom:'5px'}} src={"https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/ManuallyEditColumns/RenameCols.gif"}/>
                        <br/>
                    </div>
                    <br/>
                    <div className="segment-raised" style={{padding:'10px',borderRadius:'5px',backgroundColor:'white'}}>
                        <h3> Move Column Location </h3>
                        <p style={{fontSize:'120%'}}> I want to move "Customer Names" over a few columns </p>
                        <p style={{fontSize:'120%'}}> 1. Click on the arrow key until the location you need </p>
                        <img style={{width:'70%',display:'block',marginLeft:'auto',marginRight:'auto',paddingBottom:'5px'}} src={"https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/NoModalPics/ManuallyEditColumns/MoveColLocation.gif"}/>
                        <br/>
                    </div>
                </div>
                <br/><br/>

                <div className="segment-raised" style={{backgroundColor:'whitesmoke',padding:'10px',borderRadius:'5px',textAlign:'left',margin:'auto'}}>
                    <div className="segment-raised" style={{backgroundColor:'#2d1538',padding:'10px',borderRadius:'5px',textAlign:'left',margin:'auto',color:'white'}}>
                        <h3> Import A Different Sheet </h3>
                    </div>
                    <br/>
                    <p style={{fontSize:'120%'}}> Our algorithm reads the first sheet by default. If you want to read another sheet: </p>
                    <p style={{fontSize:'120%'}}> 1. Go to the file and click the <b> File Options </b> dropdown </p>
                    <p style={{fontSize:'120%'}}> 2.  Press <b> Import Other Sheets. </b> You can import all of them or just the one you need.</p>
                    <img style={{width: '75%', display:'block',marginLeft:'auto',marginRight:'auto', paddingBottom: '5px'}}
                         src={"https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/AppendTipsGifs/ImportSheets.gif"}/>
                    <br/><br/>
                </div>
                <br/><br/>

                <div className="segment-raised" style={{backgroundColor:'whitesmoke',padding:'10px',borderRadius:'5px',textAlign:'left',margin:'auto'}}>
                    <div className="segment-raised" style={{backgroundColor:'#2d1538',padding:'10px',borderRadius:'5px',textAlign:'left',margin:'auto',color:'white'}}>
                        <h2> Additional Help </h2>
                    </div>
                    <br/>
                    <p style={{fontSize:'120%'}}> If none of these tips worked, please email admin@lovespreadsheets.com </p>
                    <p style={{fontSize:'120%'}}> Our general response time is 48 hours, however if you <Button id="proBlockMessageButton" color="purple" onClick={this.signUpAndPay}
                                                                                                                style={{height: '80%'}}>
                        <p> Upgrade to Pro</p> </Button>  we will respond in 12 hours or earlier </p>

                </div>
                <br/><br/>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({

})

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(NoModalBody)