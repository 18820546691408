import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import {connect} from 'react-redux';
import HelpBody from "./HelpBody";

class HelpModal extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <ModalApp name="appendHelpModal"
                      header="Why aren't my files appending properly?"
                      body={<HelpBody/>}
                      size="medium"
            />
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps,)(HelpModal)