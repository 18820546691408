import React, {Component} from 'react'
import getCurrencyAmount from "../../../Constants/getCurrencyAmount";
import { connect } from 'react-redux'
import NavbarItem from '../../../SharedComponents/Navbar/NavbarItem'

class Append extends Component {
    render() {
        return (
            <div style={{padding: "25px 0 25px 0"}}>
                <h4>1. Upload all your files to the tool</h4>
                <br/>
                <img src="https://media.giphy.com/media/SRvzueBSzOO95rzzke/giphy.gif"
                     alt="Combine Excel Files"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="image"
                />
                <br/><br/>
                <p>You can always upload unlimited files under a total size of 2 MB.
                    If you want to upload files that have a total size of over 2 MB, you can <b>upgrade to a pro plan for just {getCurrencyAmount(this.props.currency)}</b>.
                    With the pro plan, you can upload however big files you want.
                </p>
                <br/><br/>
                <h4>2. Tool will merge automatically based on column names</h4>
                <br/>
                <img src="https://media.giphy.com/media/ZFhdRVzh7LS8Wk4h5T/giphy.gif"
                     alt="Merge Excel Files"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="image"
                />
                <br/><br/>
                <p>The tool will automatically align columns with the same name.
                    The name must be completely identical. Completely.
                </p>
                <p>
                    The sample of the how the final file will look will appear.
                    It only shows the first row from each file so it might happen that the sample row appears blank.
                </p>
                <br/><br/>
                <h4>3. Pick download options and download your combined excel file</h4>
                <br/>
                <p>
                    You can pick two options and click <b>Download</b> to download the .xlsx file. You need to give the file a name before downloading.
                </p>
                <p>
                    Picking <b>Remove Duplicates</b> will keep the first row of identical duplicates from the merged file. This will be done automatically when you download. The example below is done manually just for example purposes.
                </p>
                <br/>
                <img src="https://media.giphy.com/media/Q87IOkY1el3Hz1VTjI/giphy.gif"
                     alt="Merge Spreadsheets"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="image"
                />
                <br/><br/>
                <p>
                    Picking <b>Add Source File Names</b> will add a new column with the source file name and sheet name that a row comes from.
                </p>
                <br/>
                <img src="https://media.giphy.com/media/ZbHg0seRxcQoMWTUK8/giphy.gif"
                     alt="Combine Excel Files"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="image"
                />
                <br/><br/><br/><br/><br/><br/>
                <h1 className="thinHeading" style={{color: 'purple'}}>Additional Features</h1>
                <br/><br/>
                <h2>Change Column Names</h2>
                <br/>
                <h4>1. Select which row the column names are on</h4>
                <p>
                    Column names are not always on the first row of a file, if they even exist.

                </p>
                <p>
                    If they are on another row, then we need to tell the tool which row they are on.
                    This can be done with the <b>Start at Row</b> option.
                </p>
                <p>
                    If there are no headers in the file, then you can check the <b>Does File Have a Header?</b> checkbox.
                    <br/><br/>
                    The columns will then be renamed in the format: <b>[Filename]_[Column Number]</b>.
                    <br/>
                    <i>E.g., if [Filename] is <b>Small-Demo-Data</b> then column 1 will be renamed: <b>Small-Demo-Data_1</b>, column 2 will be: <b>Small-Demo-Data_2</b>...</i>
                </p>
                <p>
                    These options can be selected in the <b>Read File Options</b> under <b>File Options</b>.
                    If all the files need to be changed then you can click on <b>Read All File Options</b> over the sample.
                </p>
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/HeaderChange.gif"
                     alt="HeaderChange"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="image"
                />
                <br/><br/><br/>
                <h4>2. Rename the columns</h4>
                <p>
                    The merged columns can be renamed by clicking on the name.
                </p>
                <img src="https://media.giphy.com/media/MBffO4JNBJRJVxXKgJ/giphy.gif"
                     alt="Combine-Excel-Files"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="image"
                 />
                <br/><br/>
                <p>
                    This will show up in the Downloaded Merged File.
                </p>
                <br/><br/><br/>
                <h2>Move Columns</h2>
                <br/>
                <h4>1. Change the column order from each file</h4>
                <p>
                    Using the <b>Detailed View</b> you can actually change the order the columns append.
                    You can click on the values and move them to another column in the same row.
                </p>
                <p>
                    This is extremely useful when the column names don't match up.
                </p>
                <img
                    src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/DetailedColumn.gif"
                    alt="DetailedColumn"
                    style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                    className="image"
                />
                <br/><br/>
                <h4>2. Change the order of the merged columns</h4>
                <p>
                    You can change the order of the merged columns by clicking on the left and right buttons.
                </p>
                <img
                    src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MoveColumns.gif"
                    alt="MoveColumns"
                    style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                    className="image"
                />
                <br/><br/>
                <h4>3. Remove merged columns</h4>
                <p>
                    Simply click on the close button on the column
                </p>
                <br/><br/><br/>
                <h2>Move File Order</h2>
                <br/>
                <p>
                    You can move the order in which the files appear by going into the <b>File Options</b> and clicking
                    on <b>Move File Up</b> or <b>Move File Down</b>
                </p>
                <br/>
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MoveRows.gif"
                     alt="MoveRows"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="image"
                />
                <br/><br/><br/>
                <h1 className="thinHeading" style={{color: 'purple'}}>Parting Thoughts</h1>
                <p>
                    This is one way of merging spreadsheets. There are many other ways of combining excel files
                    but we believe our tool is the easiest.
                </p>
                <p>
                    If you know Python, we have written another guide on
                    <a href="https://medium.com/@lovespreadsheets/merging-spreadsheets-with-python-append-591d599d49da"> <i>Merging Spreadsheets with Python-Append</i> on our blog at Medium.</a>
                </p>
                <p>
                    Please help us improve our guides and tool by giving us <NavbarItem to="feedbackModal" name="Feedback" color="gray" modal/>
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(Append)