import React, {Component} from 'react'
import PricingCard from './PricingCard'
import pricing from "../../Constants/Pricing";
import { connect } from 'react-redux'
import { Button, Icon, Dropdown, Segment } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import API_Root from "../../Constants/API_Root";
import {modifyCurrency} from "../../Actions/ModifyCurrency";
import getCurrencyAmount from "../../Constants/getCurrencyAmount";
import FeedbackDisplay from "../../SharedComponents/FeedbackDisplay";
import PricingFeature from "./PricingFeature";
import Calender from "./Calender.svg"
import Cancel from "./Cancel.svg"
import Stripe from "./Stripe.svg"
import {isUserPro} from "../../Constants/isUserPro";

class PricingPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            isUserPro: false
        }

        this.openSignUp = this.openSignUp.bind(this);
        this.changeCurrency = this.changeCurrency.bind(this);
    }

    componentWillMount(){
        document.title = "Pricing - Merge Spreadsheets"

        const profileData = new FormData();

        profileData.append("email", localStorage.getItem("email"));
        profileData.append("token", localStorage.getItem("token"));

        fetch(API_Root + 'api/is-merge-user-pro-route/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: profileData
        }).then(res => res.json()).then(
            data => {
                this.setState({
                    isUserPro: isUserPro(data.pro)
                })
            }
        ).catch().catch()
    }


    openSignUp(e){
        this.props.toggleModal("signUpModal");
    }

    changeCurrency(event, {value}){
        this.props.modifyCurrency(value);
    }

    render() {
        const currencyOptions = [
            {key: 0, text: 'US Dollar ($)', value: 'usd'},
            {key: 1, text: 'Indian Rupee (₹)', value: 'inr'},
            {key: 2, text: 'British Pound (£)', value: 'gbp'},
            {key: 3, text: 'Australian Dollar (AUD)', value: 'aud'},
            {key: 4, text: 'Brazilian Real (R$)', value: 'brl'},
            {key: 5, text: 'Euro (€)', value: 'eur'},
            {key: 6, text: 'Japanese Yen (¥)', value: 'jpy'},
            {key: 7, text: 'Canadian Dollar (CAD)', value: 'cad'},
            {key: 8, text: 'Polish Zloty (zł)', value: 'pln'},
            {key: 9, text: 'Singapore Dollar (SGD)', value: 'sgd'},
            {key: 10, text: 'Philippine Peso (₱)', value: 'php'},
            {key: 11, text: 'Turkish Lira (₺)', value: 'try'}
        ];

        const hero = <div id="aboutHero">
            <h1>Pricing</h1>
            <p>Easy monthly pricing</p>
        </div>;



        if (this.state.isUserPro){
            return(
                <div className="pricingPage">
                    {hero}
                    <div>
                        <br/><br/>
                        <div style={{margin: '0 auto', width: '50%', textAlign: 'center'}}>
                            <p>You are already a Pro Member!</p>
                        </div>
                        <br/><br/>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className="pricingPage">
                    {hero}
                    <div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-4">
                                <div style={{marginLeft: '25px'}}>
                                    <span>
                                    <p>Currency: {''}
                                        <Dropdown
                                            inline
                                            options={currencyOptions}
                                            placeholder="Change Currency"
                                            name="currency"
                                            value={this.props.currency}
                                            onChange={this.changeCurrency}
                                        />
                                    </p>
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <h2 style={{textAlign: 'center'}}>{"Upgrade to a Pro Account for only " + getCurrencyAmount(this.props.currency) + " per month!"}</h2>
                            </div>
                            <div className="col-sm-4">
                            </div>
                        </div>
                        <br/>
                        <div style={{textAlign: 'center'}}>
                            <h4 className="thinHeading text-muted">A Pro Account Lets You Merge As Many Files As You Want</h4>
                            <h6 className="thinHeading">You can only merge up to 1 MB of files</h6>
                            <h6 className="thinHeading">You also get FREE access to our other tool <a href="https://www.splitspreadsheets.com" target="_blank" rel="noopener noreferrer">Split Spreadsheets</a> to auto split large spreadsheets</h6>
                            <br/>
                        </div>
                        <div className="pricingGrid">
                            <div className="freePricingCard pricingCard">
                                <PricingCard header="FREE"
                                             cost={<span><span className="priceAmount">FREE</span><span className="priceIdentifier">ALWAYS</span></span>}
                                             float="right"
                                             desc={[<span><Icon name="file alternate outline" />{pricing.mbMax.toString() + " MB Max" }</span> , <span><Icon name="clock outline" />{'Unlimited Merges'}</span>]}
                                />
                            </div>
                            <div className="paidPricingCard pricingCard">
                                <PricingCard header="PRO"
                                             cost={
                                                 <span><span className="priceAmount">{getCurrencyAmount(this.props.currency)}</span><span className="priceIdentifier">PER MONTH</span></span>}
                                             float="left"
                                             desc={[<span><Icon name="file alternate outline" />Unlimited Size</span>, <span><Icon name="clock outline" />Unlimited Merges</span>]}
                                />
                            </div>
                        </div>
                        <br/><br/><br/>
                        <FeedbackDisplay/>
                    </div>
                    <br/><br/><br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <PricingFeature
                                title="Easy Subscription"
                                img={Calender}
                                desc={<ul style={{textAlign: 'left'}}>
                                    <li>Month begins day you signed up</li>
                                    <li>Sign up on May 5, renews on June 5...</li>
                                    <li>Invoice every month</li>
                                </ul>}
                            />
                        </div>
                        <div className="col-sm-4">
                            <PricingFeature
                                title="Cancel Anytime"
                                img={Cancel}
                                desc={<ul style={{textAlign: 'left'}}>
                                    <li>Easy Cancel from Profile</li>
                                    <li>Just click 1 button to Cancel</li>
                                    <li>Account stays active till end of month</li>
                                </ul>}
                            />
                        </div>
                        <div className="col-sm-4">
                            <PricingFeature
                                title="Simple Payment"
                                img={Stripe}
                                desc={<ul style={{textAlign: 'left'}}>
                                    <li>Pay in 12 different currencies</li>
                                    <li>Simply pay with any Credit Card</li>
                                    <li>Our payment partner is <a href="https://www.stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a></li>
                                </ul>}
                            />
                        </div>
                    </div>
                    <br/><br/><br/><br/><br/>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.openSignUp} color="purple" size="massive">
                            Upgrade Now <Icon name="right arrow" />
                        </Button>
                    </div>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    proBlock: state.mainState.proBlock,
    currency: state.mainState.currency
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyCurrency: modifyCurrency
}

export default connect(mapStateToProps, mapActionsToProps)(PricingPage)