import React, {Component} from 'react'
import ExplainTable from '../ExplainTable'
import { Segment, Menu, Image } from 'semantic-ui-react'
import JoinResultTable from "../JoinResultTable";

class WhatIsJoin extends Component {
    constructor(props){
        super(props);

        this.state = {
            joinType: 'innerJoin'
        }

        this.handleItemClick = this.handleItemClick.bind(this);
    }

    handleItemClick(e, {name}){
        this.setState({
            joinType: name
        })
    }

    render() {
        const activeItem = this.state.joinType;

        const header = 'guide' in this.props ? null :
        <div>
            <p className="landingJoinExplainDesc">Joining means to merge two files based on exact values in certain columns</p>
            <br/>
            {/*<Image src="https://media.giphy.com/media/Qtw03fjZcLZMJlBZvC/giphy.gif" centered />*/}
            {/*<br/>*/}
        </div>

        return (
            <div>
                {header}
                <div className="row">
                    <div className="col-sm-5">
                        <div className="docSectionHover">
                            <p>Let's say you have a file called <b>Names.xlsx</b></p>
                            <p>It has 3 rows and 2 columns named <i>Name</i> and <i>Work</i></p>
                            <ExplainTable
                                headers={['Name', 'Work']}
                                rows={[
                                    ['Harry Potter', 'Auror'],
                                    ['Ben Franklin', 'Everything'],
                                    ['Chris Martin', 'Singer']
                                ]}
                                cellColor={["black", "black"]}
                            />
                        </div>
                    </div>
                    <div className="col-sm-7">
                        <div className="docSectionHover">
                            <p style={{textAlign: 'right'}}>And another one called <b style={{color: 'gray'}}>Emails.xlsx</b></p>
                            <p style={{textAlign: 'right'}}>It has 4 rows and 2 columns named <i>Name</i> and <i>Email</i></p>
                            <ExplainTable
                                headers={['Name', 'Email']}
                                rows={[
                                    ['Harry Potter', 'potter@hogwarts.com'],
                                    ['Chris Martin', 'chris@martin.com'],
                                    ['Chris Martin', 'cm@coldplay.com'],
                                    ['John Adams', 'john@ff.com'],
                                ]}
                                cellColor={["gray", "gray", "gray", "gray"]}
                            />
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-12">
                        <p className="landingJoinExplainDesc">Joining the files on the Name column will create a new file with names matched up with their emails. Click on the different types of Join to see what the result would look like.</p>
                        <Segment>
                            <h4 style={{textAlign: 'center'}}>Join both files on Name</h4>
                            <Menu>
                                <Menu.Item
                                    name='innerJoin'
                                    active={activeItem === 'innerJoin'}
                                    onClick={this.handleItemClick}
                                >
                                    <span className="joinicons-innerjoin" /> Inner Join
                                </Menu.Item>
                                <Menu.Item
                                    name='leftJoin'
                                    active={activeItem === 'leftJoin'}
                                    onClick={this.handleItemClick}
                                >
                                    <span className="joinicons-leftjoin" /> Left Join
                                </Menu.Item>
                                <Menu.Item
                                    name='rightJoin'
                                    active={activeItem === 'rightJoin'}
                                    onClick={this.handleItemClick}
                                >
                                    <span className="joinicons-rightjoin" /> Right Join
                                </Menu.Item>
                                <Menu.Item
                                    name='fullJoin'
                                    active={activeItem === 'fullJoin'}
                                    onClick={this.handleItemClick}
                                >
                                    <span className="joinicons-fulljoin" /> Full Join
                                </Menu.Item>
                            </Menu>
                            <JoinResultTable joinType={this.state.joinType}/>
                        </Segment>
                    </div>
                </div>
            </div>
        )
    }
}

export default WhatIsJoin