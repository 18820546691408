import React, {Component} from 'react'
import { Icon, Progress } from 'semantic-ui-react';

class UploadFilesLabel extends Component {
    render() {
        let icons;
        let header = null;
        let progressBar = null;

        if (this.props.status === "upload" || this.props.status === "merged"){
            let firstIconName = null;

            if (this.props.status === "upload"){
                firstIconName = "file excel outline";
                if (this.props.fileType === 'append'){
                    header = <span> <br/> Upload or Drop Multiple Files <br/>to Automatically Append Them </span>
                }else{
                    header = <span> <br/> Upload or Drop Multiple Files to Join </span>
                }

            }
            else if (this.props.status === "merged"){
                firstIconName = "plus square outline";
                if (this.props.fileType === 'append'){
                    header = <span> <br/> Add More Files <br/> to Automatically Append with Current </span>
                }else{
                    header = <span> <br/> Add More Files to Select For Join </span>
                }

            }

            icons =
                <span>
                <Icon name={firstIconName} />
                <Icon name="file alternate outline" />
            </span>
        }
        else{
            let percentage = 10;

            if (this.props.status === "reading" || this.props.status === "saved"){
                icons = <Icon name="folder open outline" />
                header = "Reading Files..."

                percentage = this.props.status === "saved" ? 70 : 30
            }
            else if (this.props.status === "read"){
                icons = <Icon name="sync alternate" />
                header = "Merging Files..."
                percentage = 90
            }

            progressBar =
                <div className="uploadProgressBar">
                    <Progress percent={percentage} indicating />
                </div>
        }

        return (
            <div className="uploadLabel">
                <span className="uploadIcons">
                    {icons}
                </span>
                {progressBar}
                <h4 className="uploadHeading thinHeading">{header}</h4>
            </div>
        )
    }
}

export default UploadFilesLabel