import React, {Component} from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import GuidesMenuItem from './GuidesMenuItem'
import CombineExcelFiles from "./CombineExcelFiles";
import MergeExcelSheets from "./MergeExcelSheets"
import MergeCSVFiles from "./MergeCSVFiles"
import Append from './CombineExcelFiles/Append'
import Join from './CombineExcelFiles/Join'
import { Icon } from 'semantic-ui-react'
import UltimateGuide from "./UltimateGuide";
import GuidesCardIndex from "./GuidesCardIndex";
import TutorialShare from "./TutorialShare";
import {BrowserView} from "react-device-detect";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import {authorDetails} from "./authorDetails";
import SQLJoin from "./SQLJoin";


class GuidesIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            guide: this.getGuide(),
            showGoUpButton: false,
        }


        this.setGuide = this.setGuide.bind(this);
        this.capitalizeHeader = this.capitalizeHeader.bind(this);

        this.handleScroll = this.handleScroll.bind(this);
        this.goToTopOfPage = this.goToTopOfPage.bind(this);
    }

    getGuide(){
        let { id } = this.props.match.params

        if (typeof id !== "string"){
            document.title = "Combine Excel Files - Merge Spreadsheets"
            document.getElementsByTagName('meta')["description"].content = "Learn how to easily combine multiple excel files";
            this.props.navigateTo("/guides/how-to-combine-excel-files")
        }
        else{
            const titles = {
                'combine-excel-files': 'Combine Excel Files - Merge Spreadsheets',
                'merge-excel-sheets': 'Merge Excel Sheets - Merge Spreadsheets',
                'merge-CSV-files': 'Merge CSV Files - Merge Spreadsheets',
                'ultimate-guide-to-merging-spreadsheets': 'The Ultimate Guide to Merging Spreadsheets',
                'SQL-Join':'An Introduction to SQL Joins'
            }

            const meta = {
                'combine-excel-files': "Learn how to easily combine multiple excel files",
                'merge-excel-sheets': 'Learn how to easily combine multiple excel sheets',
                'merge-CSV-files': 'Learn how to easily merge multiple CSV files',
                'ultimate-guide-to-merging-spreadsheets': 'Every possible way that you can merge multiple spreadsheets',
                'SQL-Join': 'A brief yet comprehensive introduction to SQL Joins'
            }

            document.getElementsByTagName('meta')["description"].content = meta[id];

            document.title = titles[id]

        }

        return id
    }

    setGuide(guide){
        this.props.navigateTo("/guides/" + guide)
    }

    capitalizeHeader(guide){
        let header = guide.charAt(0).toUpperCase();

        for (let i=1; i<guide.length; i++){
            header += guide.charAt(i-1) === "-" ? guide.charAt(i).toUpperCase() : guide.charAt(i)
        }


        header = header.replace(/-/g, " ")

        return header
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e){
        const elmnt = document.getElementById("guidesIndex");
        const maxHeight = elmnt.clientHeight;

        if (window.scrollY - window.innerHeight> 500){
            if (maxHeight - window.scrollY > 600){
                if (!this.state.showGoUpButton){
                    this.setState({ showGoUpButton: true })
                }
            }
            else{
                if (this.state.showGoUpButton){
                    this.setState({ showGoUpButton: false })
                }
            }
        }
        else{
            if (this.state.showGoUpButton){
                this.setState({ showGoUpButton: false })
            }
        }
    }

    goToTopOfPage(e){
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    render() {
        const display = {
            'combine-excel-files': <CombineExcelFiles />,
            'merge-excel-sheets': <MergeExcelSheets />,
            'merge-CSV-files': <MergeCSVFiles />,
            'ultimate-guide-to-merging-spreadsheets': <UltimateGuide/>,
            'SQL-Join':<SQLJoin/>
        }

        const headers = {
            'combine-excel-files': this.capitalizeHeader('how-to-combine-excel-files'),
            'merge-excel-sheets': this.capitalizeHeader('how-to-merge-excel-sheets'),
            'merge-CSV-files': this.capitalizeHeader('how-to-merge-CSV-files'),
            'ultimate-guide-to-merging-spreadsheets': this.capitalizeHeader('ultimate-guide-to-merging-spreadsheets'),
            'SQL-Join':this.capitalizeHeader('introduction-to-SQL-Joins')
        }

        const authors = {
            'combine-excel-files': 'Adhaar Sharma',
            'merge-excel-sheets':'Adhaar Sharma',
            'merge-CSV-files': 'Adhaar Sharma',
            'ultimate-guide-to-merging-spreadsheets': 'Clarissa Ng',
            'SQL-Join':'Clarissa Ng'

        }
        const dates ={
            'combine-excel-files': '2020-06-09',
            'merge-excel-sheets':'2020-06-09',
            'merge-CSV-files': '2020-06-09',
            'ultimate-guide-to-merging-spreadsheets': '2020-06-26',
            'SQL-Join':'2020-08-11'
        }
        const showGoUpbutton = this.state.showGoUpButton ? <button id="scrollButton" onClick={this.goToTopOfPage}>
            Go Up <Icon name="arrow up" /> </button> : null

        const writtenBy = <span><Image src={authorDetails[authors[this.state.guide]].pic} avatar /> <a href={authorDetails[authors[this.state.guide]]['social']} target="_blank" rel="noopener noreferrer">{authors[this.state.guide]}</a></span>
        const writtenDt = <span><i>{dates[this.state.guide]}</i></span>


        return (
            <div className="guidesIndex" id="guidesIndex">
                <BrowserView>
                    <TutorialShare url={this.getGuide()} header={headers[this.state.guide]} />
                </BrowserView>
                <div className="guidesDisplayIndex">
                    <div className="guidesDisplayHeader">
                        <h1 className="thinHeading" style={{fontSize: '350%'}}> {headers[this.state.guide]}</h1>
                        <b>by</b> {writtenBy} <b>on</b> {writtenDt}
                    </div>
                    <div className="guidesDisplay">
                        {display[this.state.guide]}
                        {showGoUpbutton}
                    </div>
                    {/*<div className="guidesDisplayVideo">*/}
                        {/*Video*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

const mapActionsToProps = {
    navigateTo: push
}

export default connect(null, mapActionsToProps)(GuidesIndex)