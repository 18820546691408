import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
import {modifyStatus} from "../Actions/ModifyStatus";
import {modifyFinalColumnPK} from "../Actions/ModifyFinalColumnPK";
import {modifyFinalColumnNames} from "../Actions/ModifyFinalColumnNames";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyEditOptions} from "../Actions/ModifyEditOptions";
import {modifyEditFileResponse} from "../Actions/ModifyEditFileResponse";

class EditFileReadButton extends Component {
    constructor(props) {
        super(props);

        this.editFile = this.editFile.bind(this)
    }

    editFile(){
        // Check if there is any error
        const skipRowsError = this.props.skipRowsError;
        const startRowError = this.props.startRowError;

        if (!skipRowsError && !startRowError){
            // Open up WebSockets
            const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
            const socket = new WebSocket(websocketRoute + "ws/api/merge-files/");

            let filePK;

            // Get the PK of the file(s)
            if (this.props.editID !== "all"){
                for (let i=0; i<this.props.filePK.length; i++){
                    const filePKObj = this.props.filePK[i];
                    const fileID = filePKObj['id'];

                    if (fileID === this.props.editID){
                        filePK = [filePKObj];
                        break
                    }
                }
            }
            else{
                filePK = this.props.filePK
            }

            const props = this.props;

            // Send the PKs and if any column names
            socket.onopen = function(event) {
                socket.send(JSON.stringify({
                    operation: "edit",
                    filePK: filePK,
                    finalColumnNames: props.finalColumnNames,
                    finalColumnPK: props.finalColumnPK,
                    editOptions: props.editOptions,
                    sheetRead: props.sheetRead
                }));
            };

            props.toggleModal(null);
            props.toggleModal("loadingModalEditFile");

            socket.onmessage = function(event) {
                const reply = JSON.parse(event.data);
                const status = reply['status'];

                props.modifyStatus(status);

                if (status === "merged"){
                    let fileInformation = reply.fileInformation;
                    let finalColumnNames = reply.finalColumnNames;
                    let finalColumnPK = reply.finalColumnPK;

                    let propsFileInformation = JSON.parse(JSON.stringify(props.fileInformation));

                    if (typeof propsFileInformation !== 'undefined'){
                        if (Object.entries(propsFileInformation).length !== 0 && propsFileInformation.constructor === Object){
                            for (let pkID in fileInformation){
                                const fileInfo = fileInformation[pkID];
                                propsFileInformation[pkID] = fileInfo
                            }
                        }
                    }

                    props.modifyEditFileResponse(finalColumnNames, finalColumnPK, propsFileInformation);

                    const reduxEditOptions = JSON.parse(JSON.stringify(props.reduxEditOptions));

                    if (props.editID !== "all"){
                        reduxEditOptions[props.editID] = props.editOptions;
                    }
                    else{
                        for (let i=0; i<props.filePK.length; i++){
                            const filePKObj = props.filePK[i];
                            const fileID = filePKObj['id'];

                            reduxEditOptions[fileID] = props.editOptions;
                        }

                        reduxEditOptions["all"] = props.editOptions;
                    }

                    props.modifyEditOptions(reduxEditOptions);

                    socket.close()
                    props.toggleModal(null);
                }
                else if (status === "error"){
                    socket.close()
                    props.toggleModal(null);
                    alert("There was a problem reading your file. Please try again")
                }
            };
        }
    }

    render() {
        const text = this.props.editID === "all" ? "Re-Read All Files" : "Re-Read File";

        const skipRowsError = this.props.editOptions.skipRowsError;
        const startRowError = this.props.editOptions.startRowError;

        return (
            <div>
                <Button color="blue" icon="edit outline" disabled={skipRowsError || startRowError} content={text} onClick={this.editFile}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK,
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    editID: state.mainState.editID,
    reduxEditOptions: state.mainState.editOptions,
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    modifyFinalColumnPK: modifyFinalColumnPK,
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFileInformation: modifyFileInformation,
    modifyEditOptions: modifyEditOptions,
    toggleModal: toggleModal,
    modifyEditFileResponse: modifyEditFileResponse
}

export default connect(mapStateToProps, mapActionsToProps)(EditFileReadButton)