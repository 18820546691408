import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Accordion, Icon, Divider, Button } from 'semantic-ui-react'
import ImportSheets from './ImportSheets'
import {toggleModal} from "../../../Actions/ToggleModal";
import RemoveFile from "./RemoveFile";
import {modifyJoinEditID} from "../../../Actions/Join/ModifyJoinEditID";
import PreviewModal from "../../OpenPreview/PreviewModal";

class FileOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1
        }

        this.handleAccordionClick = this.handleAccordionClick.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
    }

    handleAccordionClick(e, titleProps){
        const { index } = titleProps
        const activeIndex  = this.state.activeIndex;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    }

    openEditModal(){
        this.props.modifyJoinEditID(this.props.pkID)
        this.props.toggleModal("joinEditModal")
    }

    openPreviewModal = () =>{
        this.props.modifyJoinEditID(this.props.pkID)
        this.props.toggleModal("previewModal")
    }

    render() {
        if (this.props.pkID === null){
            return(
                null
            )
        }
        else{
            let fileName;
            let idx;
            let pk;

            for (let i=0; i<this.props.joinFilePK.length; i++){
                if (this.props.joinFilePK[i].id === this.props.pkID){
                    pk = this.props.joinFilePK[i].pk;
                    fileName = this.props.joinFileInformation[this.props.pkID].name;
                    idx = i;
                    break;
                }
            }

            const activeIndex = this.state.activeIndex;

            const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";
            const importSheets = isExcel ? <ImportSheets idx={idx} pkID={this.props.pkID} pk={pk} /> : null;

            return (
                <div style={{textAlign: 'left', marginTop: '10px'}}>
                    <div className="fileOptions" style={{textAlign:'left'}}>
                        <Button fluid basic color='green' icon='eye' content="Preview File" onClick={this.openPreviewModal}/>
                    </div>
                    <div className="fileOptions" style={{textAlign: 'left'}}>
                        <Button fluid basic color='blue' icon='edit outline' content="File Options" onClick={this.openEditModal} />
                    </div>
                        {importSheets}
                    <Divider/>
                    <div className="fileOptions">
                        <RemoveFile  idx={idx} pkID={this.props.pkID} pk={pk} isExcel={isExcel} />
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    joinFileInformation: state.joinState.joinFileInformation,
    joinFilePK: state.joinState.joinFilePK,
    joinSheetRead: state.joinState.joinSheetRead,
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyJoinEditID: modifyJoinEditID
}

export default connect(mapStateToProps, mapActionsToProps)(FileOptions)