import React, {Component} from 'react'
import { Icon, Image, Divider } from 'semantic-ui-react'
import {toggleModal} from "../../Actions/ToggleModal";
import {modifyHelpVideoURL} from "../../Actions/ModifyHelpVideoURL";
import { connect } from 'react-redux'
import {modifyHelpFAQ} from "../../Actions/ModifyHelpFAQ";

class FAQ extends Component{
    constructor(props){
        super(props);

        this.openVideoModal = this.openVideoModal.bind(this);
    }

    openVideoModal(e){
        this.props.modifyHelpVideoURL(this.props.img);
        this.props.modifyHelpFAQ({question: this.props.question, answer: this.props.answer})
        this.props.toggleModal("imageHelpModal");
    }

    render() {
        const answer = this.props.answer;
        let answerBlock;

        if (answer.constructor === Array){
            const answerList = answer.map((x, idx) => <li key={idx}>{x}</li>);
            answerBlock = <ul>{answerList}</ul>
        }
        else{
            answerBlock = <p>{answer}</p>
        }

        let seeImg = null;
        if ("img" in this.props){
            seeImg = <p className="linkStyle" onClick={this.openVideoModal}><Icon name="eye"/> See Details</p>
            // seeImg = <Image src={this.props.img} size="medium" onClick={this.openVideoModal} style={{cursor: 'pointer'}} />
        }
        return (
            <div>
                <h5>{this.props.question}</h5>
                {answerBlock}
                {seeImg}
                {/*<br/>*/}
                <Divider/>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyHelpVideoURL: modifyHelpVideoURL,
    modifyHelpFAQ: modifyHelpFAQ
}

export default connect(null, mapActionsToProps)(FAQ)