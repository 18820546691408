export const MODIFY_ALL_JOIN_INFORMATION = 'MODIFY_ALL_JOIN_INFORMATION';

export function modifyAllJoinInformation(joinFileInformation, joinSheetRead){
    return{
        type: MODIFY_ALL_JOIN_INFORMATION,
        mainState: {
            joinFileInformation: joinFileInformation,
            joinSheetRead: joinSheetRead
        }
    }
}