import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {resetAllStates} from "../Actions/ResetAllStates";
import {resetJoinState} from "../Actions/Join/ResetJoinState";
import {modifyTutorialStep} from "../Actions/ModifyTutorialStep";

class ResetState extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resetOpen: false
        };

        this.resetState = this.resetState.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
    }

    handleOpen(e){
        this.setState({ resetOpen: true })
    }

    handleClose(e){
        this.setState({ resetOpen: false })
    }


    resetState(e){
        if (this.props.mergeType === "append"){
            this.props.resetAllStates()
        }
        else{
            this.props.resetJoinState()
        }
    }

    render() {
        let color;
        let buttonText;

        if (this.props.mergeType === "append"){
            color = "purple"
            buttonText = "Reset Append"
        }
        else{
            color = "blue"
            buttonText = "Reset Join"
        }

        return (
            <Popup
                trigger={
                    <Button id="resetButton" basic color={color} icon="redo" content={buttonText} />
                }
                content={
                    <div style={{textAlign: 'center'}}>
                        <h5 className="thinHeading">Are you sure you want to reset?</h5>
                        <span>
                           <Button id="resetButtonYes" color="red" content={buttonText} onClick={this.resetState} />
                           <Button id="resetButtonNo" color="green" content="No Reset" onClick={this.handleClose} />
                       </span>
                    </div>}
                on='click'
                position='top right'
                open={this.state.resetOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
            />

        )
    }
}

const mapStateToProps = (state) => ({
    mergeType: state.mainState.mergeType
})

const mapActionsToProps = {
    resetAllStates: resetAllStates,
    resetJoinState: resetJoinState,
    modifyTutorialStep:modifyTutorialStep,
}

export default connect(mapStateToProps, mapActionsToProps)(ResetState)