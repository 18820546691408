import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'

class JoinResultTable extends Component {
    render() {
        const tableBody = {
            innerJoin: [
                [
                    <Table.Cell style={{color: 'purple'}}>Harry Potter</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Auror</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>potter@hogwarts.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Chris Martin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Singer</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>chris@martin.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Chris Martin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Singer</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>cm@coldplay.com</Table.Cell>,
                ],

            ],
            leftJoin: [
                [
                    <Table.Cell style={{color: 'purple'}}>Harry Potter</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Auror</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>potter@hogwarts.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Ben Franklin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Everything</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}> </Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Chris Martin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Singer</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>chris@martin.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Chris Martin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Singer</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>cm@coldplay.com</Table.Cell>,
                ],
            ],
            rightJoin: [
                [
                    <Table.Cell style={{color: 'purple'}}>Harry Potter</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Auror</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>potter@hogwarts.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Chris Martin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Singer</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>chris@martin.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Chris Martin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Singer</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>cm@coldplay.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>John Adams</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}> </Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>john@ff.com</Table.Cell>,
                ]
            ],
            fullJoin:[
                [
                    <Table.Cell style={{color: 'purple'}}>Harry Potter</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Auror</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>potter@hogwarts.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Ben Franklin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Everything</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}> </Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Chris Martin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Singer</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>chris@martin.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>Chris Martin</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}>Singer</Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>cm@coldplay.com</Table.Cell>,
                ],
                [
                    <Table.Cell style={{color: 'purple'}}>John Adams</Table.Cell>,
                    <Table.Cell style={{color: 'purple'}}> </Table.Cell>,
                    <Table.Cell style={{color: 'red'}}>john@ff.com</Table.Cell>,
                ]
            ]
        }

        const joinDesc = {
            innerJoin: "Only Names that are present in both files will be present in the Inner Join",
            leftJoin: "All names from the LEFT file will be present in the Left Join, regardless of if they exist in the RIGHT file",
            rightJoin: "All names from the RIGHT file will be present in the Right Join, regardless of if they exist in the LEFT file",
            fullJoin: "All names from BOTH files will be present in the Full Join"
        }

        const tableRowValues = tableBody[this.props.joinType];
        const tableRows = tableRowValues.map((x, idx) =>
        <Table.Row>
            {x.map(y => y)}
        </Table.Row>)

        return (
            <div>
                <h5 className="thinHeading">{joinDesc[this.props.joinType]}</h5>
                <Table celled stripd compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Work</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tableRows}
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

export default JoinResultTable