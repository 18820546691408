import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import WhatIsAppend from "./Append/WhatIsAppend";
import ExplainFeature from "./ExplainFeature";
import HowToAppend from "./Append/HowToAppend";
import DifferentColumns from "./Append/DifferentColumns";
import RearrangeColumns from "./Append/RearrangeColumns";
import NoColumnNames from "./Append/NoColumnNames";
import OtherFileTypes from "./Append/OtherFileTypes";
import SlideCounter from "./SlideCounter";
import FullTutorial from "./Append/FullTutorial";

const totalNumber = 6;

class AppendLandingBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            featureNumber: 0
        }

        this.changeFeatureNumber = this.changeFeatureNumber.bind(this);
    }


    changeFeatureNumber(e, changeFunc){
        const currFeatureNumber = this.state.featureNumber;
        let changedFeature = changeFunc(currFeatureNumber) % totalNumber;

        if (changedFeature < 0){
            changedFeature = totalNumber + changedFeature
        }

        this.setState({
            featureNumber: changedFeature
        })

    }

    render() {
        const currFeature = Math.abs(this.state.featureNumber);

        const features = {
            0: <ExplainFeature header="What is Append?" desc={<WhatIsAppend/>} mergeType="append"/>,
            1: <ExplainFeature header="How do I Append Files?" desc={<HowToAppend/>} mergeType="append"/>,
            2: <ExplainFeature header="What if my Files don't have Column Names?" desc={<NoColumnNames/>} mergeType="append"/>,
            3: <ExplainFeature header="How Do I Deal with Excel Sheets & CSV Files?" desc={<OtherFileTypes />} mergeType="append"/>,
            4: <ExplainFeature header="Can I Rearrange the Columns?" desc={<RearrangeColumns/>} mergeType="append"/>,
            5: <ExplainFeature header="I still don't understand how this works" desc={<FullTutorial/>} mergeType="append"/>
        }

        const iconStyle = {
            fontSize: '250%',
        }

        return (
            <div className="row">
                <div className="col-sm-2">
                    <div className="linkStyle" style={{float: 'right', padding: '250px 0', marginRight: '25%'}}>
                        <Icon name="angle left"
                              onClick={(e) => this.changeFeatureNumber(e, x =>  x-1 )}
                              style={iconStyle}
                        />
                    </div>
                </div>
                <div className="col-sm-8">
                    {features[currFeature]}
                    <SlideCounter activeFeature={this.state.featureNumber} totalNumber={totalNumber}/>
                </div>
                <div className="col-sm-2">
                    <div className="linkStyle" style={{float: 'left', padding: '250px 0', marginLeft: '25%'}}>
                        <Icon name="angle right"
                              onClick={(e) => this.changeFeatureNumber(e, x => x+1 )}
                              style={iconStyle}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default AppendLandingBody