import React, {Component} from 'react'
import getCurrencyAmount from "../../../Constants/getCurrencyAmount";
import { connect } from 'react-redux'
import NavbarItem from '../../../SharedComponents/Navbar/NavbarItem'

class Append extends Component {
    render() {
        return (
            <div style={{padding: "25px 0 25px 0"}}>
                <h4>1. Upload all your files to the tool</h4>
                <br/>
                <img src="https://media.giphy.com/media/SRvzueBSzOO95rzzke/giphy.gif"
                     alt="Combine Excel Files"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="merge spreadsheets"
                />
                <br/><br/>
                <p>You can always upload unlimited files under a total size of 2 MB.
                    If you want to upload files that have a total size of over 2 MB, you can <b>upgrade to a pro plan for just {getCurrencyAmount(this.props.currency)}</b>.
                    With the pro plan, you can upload however big files you want.
                </p>
                <br/><br/>
                <h4>2. Tool will default read the first sheet</h4>
                <br/>
                <img src="https://media.giphy.com/media/jQgvIrRPUMRQHVwFTu/giphy.gif"
                     alt="Merge Excel Files "
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="excel_spreadsheet"
                />
                <br/><br/>
                <p>
                    The first sheet is <b>Actors</b> in File1 and <b>Actresses</b> in File2
                </p>
                <br/>
                <img src="https://media.giphy.com/media/eGwvRMNlEEgJnmdLLI/giphy.gif"
                     alt="Merge Excel Files "
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="Merge Spreadsheet"
                />
                <br/><br/>
                <p>
                    If there are multiple files, the first sheet of all files will be merged automatically based on column names.
                    The tool will automatically align columns with the same name.
                    The name must be completely identical. Completely.
                </p>
                <br/><br/>
                <h4>3. Import and automatically merge other sheets</h4>
                <br/>
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/ImportAllSheets.png"
                     alt="Import Spreadsheets"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="merge_spreadsheet"
                />
                <br/><br/>
                <p>
                    Pick which sheets you want to import.
                    After your click <b>Import Sheets</b>, all the imported sheets will be automatically merged.
                    Each sheet will be imported as if a new file.
                </p>
                <p>
                    Let's repeat that once again. <b><i>EACH SHEET WILL BE IMPORTED AS IF A NEW FILE.</i></b>
                </p>
                <br/>
                <img src="https://media.giphy.com/media/RfHNOMdZXJPH9lihtG/giphy.gif"
                     alt="Import Spreadsheets"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="merge spreadsheets"
                />
                <br/><br/>
                <p>
                    The tool merges based on the column names in the sheets.
                    The column name must be completely identical. Completely.
                </p>
                <br/><br/>
                <h1 className="thinHeading" style={{color: 'purple'}}>Additional Features</h1>
                <br/><br/>
                <h2>Change Column Names</h2>
                <br/>
                <h4>1. Select which row the column names are on</h4>
                <p>
                    Column names are not always on the first row of an Excel sheet, if they even exist.
                </p>
                <p>
                    If they are on another row, then we need to tell the tool which row they are on.
                    This can be done with the <b>Start at Row</b> option.
                </p>
                <p>
                    If there are no headers in the sheet or tab, then you can check the <b>Does File Have a Header?</b> checkbox.
                    <br/><br/>
                    The columns will then be renamed in the format: <b>[Filename]_[Column Number]</b>.
                    <br/>
                    <i>E.g., if [Filename] is <b>Small-Demo-Data</b> then column 1 will be renamed: <b>Small-Demo-Data_1</b>, column 2 will be: <b>Small-Demo-Data_2</b>...</i>
                    <br/><br/>
                    The <b>Sheet Name</b> will not factor in to the renaming of the columns.
                </p>
                <p>
                    These options can be selected in the <b>Read File Options</b> under <b>File Options</b>.
                    If all the files/sheets need to be changed then you can click on <b>Read All File Options</b> over the sample.
                </p>
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/HeaderChange.gif"
                     alt="HeaderChange Spreadsheets"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="merge spreadsheets"
                />
                <br/><br/><br/>
                <h4>2. Rename the columns</h4>
                <p>
                    The merged columns can be renamed by clicking on the name.
                </p>
                <img src="https://media.giphy.com/media/MBffO4JNBJRJVxXKgJ/giphy.gif"
                     alt="Combine-Excel-Files"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="merge spreadsheets"
                />
                <br/><br/>
                <p>
                    This will show up in the Downloaded Merged File.
                </p>
                <br/><br/>
                <h2>Move Columns</h2>
                <br/>
                <h4>1. Change the column order from each sheet</h4>
                <p>
                    Using the <b>Detailed View</b> you can actually change the order the columns append.
                    You can click on the values and move them to another column in the same row.
                </p>
                <p>
                    This is extremely useful when the column names don't match up.
                </p>
                <img
                    src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/DetailedColumn.gif"
                    alt="Detailed Column Spreadsheet"
                    style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                    className="spreadsheets merge"
                />
                <br/><br/>
                <h4>2. Change the order of the merged columns</h4>
                <p>
                    You can change the order of the merged columns by clicking on the left and right buttons.
                </p>
                <img
                    src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MoveColumns.gif"
                    alt="Move Columns"
                    style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                    className="merging spreadsheets"
                />
                <br/><br/>
                <h4>3. Remove merged columns</h4>
                <p>
                    Simply click on the close button on the column
                </p>
                <br/><br/><br/>
                <h2>Move Sheet Order</h2>
                <br/>
                <p>
                    You can move the order in which the sheets or tabs appear by going into the <b>File Options</b> and clicking
                    on <b>Move File Up</b> or <b>Move File Down</b>
                </p>
                <br/>
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/MoveRows.gif"
                     alt="Move Rows Spreadsheets"
                     style={{border: '1px lightgray solid',margin: '0 auto', display: 'block'}}
                     className="merge_spreadsheets"
                />
                <hr/>
                <br/><br/><br/>
                <h1 className="thinHeading" style={{color: 'purple'}}>Parting Thoughts</h1>
                <p>
                    This is one way of combini ng Excel sheets or tabs. There are many other ways of merging Excel sheets or tabs
                    but we believe our tool is the easiest.
                </p>
                <p>
                    If you know Python, we have written another guide on
                    <a href="https://medium.com/@lovespreadsheets/merging-spreadsheets-with-python-append-591d599d49da"> <i>Merging Spreadsheets with Python-Append</i> on our blog at Medium.</a>
                </p>
                <p>
                    Please help us improve our guides and tool by giving us <NavbarItem to="feedbackModal" name="Feedback" color="gray" modal/>
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currency: state.mainState.currency
})

export default connect(mapStateToProps, null)(Append)