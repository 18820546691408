import React, {Component} from 'react'
import ModalApp from '../../SharedComponents/Modal'
import JoinColumnModalBody from "./JoinColumnModalBody";

class JoinColumnModal extends Component {
    render() {
        return (
            <ModalApp name="joinColumnModal"
                      header={<span>How are the final column names determined?</span>}
                      headerStyle={{textAlign: 'center'}}
                      size="medium"
                      body={
                          <JoinColumnModalBody/>
                      }
            />
        )
    }
}

export default JoinColumnModal