import React, {Component} from 'react'
import GuidesCard from "./GuidesCard";
import Merge from "./Merge.svg";
import combine from "./combine.svg"
import csv from "./csv.svg"
import guide from "./guide.svg"
import SQL from "./SQL.svg"

class GuidesCardIndex extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <div className="guidesCardIndex">
                <div id="aboutHero">
                <h1> Guides</h1>
                </div>
                <br/><br/>
            <div className="guidesLayout">
                <div className="row">
                    <div className="col-sm-4">
                        <GuidesCard
                            header="Merge Excel Sheets"
                            url="merge-excel-sheets"
                            desc="Learn how to easily combine multiple excel sheets"
                            img={Merge}
                            tutorialInfo={{ writtenBy: "Adhaar Sharma", date: "2020-06-09"}}

                        />
                    </div>
                    <div className="col-sm-4">
                        <GuidesCard
                            header="Combine Excel Files"
                            url="combine-excel-files"
                            desc="Learn how to easily combine multiple excel files"
                            tutorialInfo={{ writtenBy: "Adhaar Sharma", date: "2020-06-09"}}
                            img={combine}
                        />
                    </div>
                    <div className="col-sm-4">
                        <GuidesCard
                            header="Merge CSV Files"
                            url="merge-csv-files"
                            desc="Learn how to easily merge multiple CSV files"
                            tutorialInfo={{ writtenBy: "Adhaar Sharma", date: "2020-06-09"}}
                            img={csv}
                        />
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-sm-4">
                        <GuidesCard
                            header="Ultimate Guide to Merging"
                            url="ultimate-guide-to-merging-spreadsheets"
                            desc="Every possible way that you can merge multiple spreadsheets"
                            tutorialInfo={{ writtenBy: "Clarissa Ng", date: "2020-06-26"}}
                            img={guide}
                        />
                    </div>
                    <div className="col-sm-4">
                    <GuidesCard
                        header="Introduction to SQL Joins"
                        url="SQL-Join"
                        desc="A brief yet comprehensive introduction to SQL Joins"
                        tutorialInfo={{ writtenBy: "Clarissa Ng", date: "2020-08-11"}}
                        img={SQL}
                        />
                    </div>
                    <div className="col-sm-4">
                        <GuidesCard
                                header="Ultimate Guide to Combining CSV Files "
                                url="ultimate-guide-to-combing-csv-files"
                                desc="Everything you need to know about combining CSV Files"
                                tutorialInfo={{ writtenBy: "Astha Sharma", date: "2021-06-24"}}
                                img={csv}
                            />
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-sm-4">
                        <GuidesCard
                            header="Why You Should Merge Your Spreadsheets"
                            url="when-you-need-to-merge"
                            desc="Scenarios where you would use Merge Spreadsheets"
                            tutorialInfo={{ writtenBy: "Astha Sharma", date: "2021-07-13"}}
                            img={csv}
                        />
                    </div>
                    <div className="col-sm-4">
                        <GuidesCard
                            header="Fastest Way To Merge Your Spreadsheets"
                            url="mergespreadsheets-vlookup-indexmatch"
                            desc="See the differences between using Vlookup, Index Match & Merge Spreadsheets"
                            tutorialInfo={{ writtenBy: "Astha Sharma", date: "2021-07-27"}}
                            img={combine}
                        />
                    </div>
                    <div className="col-sm-4">
                        <GuidesCard
                            header="7 Reasons Why You Need SQL Joins  "
                            url="reasons-to-do-sql-joins"
                            desc="See the reasons why you need SQL Joins"
                            tutorialInfo={{ writtenBy: "Astha Sharma", date: "2021-08-03"}}
                            img={SQL}
                        />
                    </div>
                </div>
                <br/><br/>
                <div className="row">
                    <div className="col-sm-4">
                        <GuidesCard
                            header="4 Scenarios When to Use a SQL Join"
                            url="4-scenarios-when-to-use-a-sql-join"
                            desc="Scenarios where you would need to use a SQL Join"
                            tutorialInfo={{ writtenBy: "Harshit Jain", date: "2021-07-13"}}
                            img={SQL}
                        />
                    </div>
                    <div className="col-sm-4">
                        <GuidesCard
                            header="A Beginners Guide to Combining Spreadsheets"
                            url="beginners-guide-combining-spreadsheets"
                            desc="Learn how to combine your spreadsheets"
                            tutorialInfo={{ writtenBy: "Astha Sharma", date: "2021-08-10"}}
                            img={combine}
                        />
                    </div>
                    <div className="col-sm-4">
                        <GuidesCard
                            header="6 Indispensable Tips for Merging your Spreadsheets"
                            url="indispensable-tips-for-merging-your-spreadsheets"
                            desc="Read these tips before you go merging your spreadsheets"
                            tutorialInfo={{ writtenBy: "Harshit Jain", date: "2021-10-23"}}
                            img={guide}
                        />
                    </div>
                </div>
            </div>
            </div>

        )
    }

}


export default GuidesCardIndex
