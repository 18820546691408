import pricing from "./Pricing";

export const hasUserMergedWithinTimeframe = () => {
    let hasUserMerged = false;

    // Time Frame in Minutes Currently
    // const timeFrame = pricing.minutesElapsed;
    //
    // if (localStorage.getItem("lastMerged") !== null){
    //     const lastMergedTime = new Date(localStorage.getItem("lastMerged"))
    //     const currTime = new Date();
    //
    //     const minutesElapsed = Math.ceil(Math.abs(currTime - lastMergedTime) / (1000 * 60))
    //
    //     if (minutesElapsed < timeFrame){
    //         hasUserMerged = true
    //     }
    // }

    return hasUserMerged
}