import React, {Component} from 'react'
import FAQBlock from "./FAQBlock";
import { connect } from 'react-redux'
import API_Root from "../../Constants/API_Root";
import {toggleModal} from "../../Actions/ToggleModal";
import {isUserPro} from "../../Constants/isUserPro";

class HelpDisplay extends Component {
    constructor(props){
        super(props);

        this.state = {
            isUserPro: false
        }

        this.openSignUp = this.openSignUp.bind(this);
        this.toggleJoinModal = this.toggleJoinModal.bind(this);
        this.openJoinColumnModal = this.openJoinColumnModal.bind(this);
    }


    componentWillMount(){
        const profileData = new FormData();

        profileData.append("email", localStorage.getItem("email"));
        profileData.append("token", localStorage.getItem("token"));

        fetch(API_Root + 'api/is-merge-user-pro-route/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
            body: profileData
        }).then(res => res.json()).then(
            data => {
                this.setState({
                    isUserPro: isUserPro(data.pro)
                })
            }
        ).catch().catch()
    }

    openSignUp(e){
        this.props.toggleModal("signUpModal");
    }

    toggleJoinModal(e){
        this.props.toggleModal("joinExplainModal");
    }

    openJoinColumnModal(e){
        this.props.toggleModal("joinColumnModal")
    }

    render() {
        const downloadDesktop = this.state.isUserPro ? <span> You have a Pro account so you can merge as many files as you want.</span> : <span className="linkStyle" onClick={this.openSignUp}>You can merge as many files as you want if you upgrade to the Pro account.</span>

        const uploadFAQs = [
            {question: "How do I select the files?", answer: "You can click on the big dialog and select them or you can drag and drop them", video: "https://fast.wistia.net/embed/iframe/rcmmq3t92t?videoFoam=true"},
            {question: "What kind of files can I select?", answer: "You can select files with these extensions: .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .csv, .txt"},
            {question: "How many files can I select?", answer: <span>As many as you want. All files must total under 1MB on the website on the Free account. {downloadDesktop}</span>},
            {question: "How many files can I select at one time?", answer: "As many as you want"},
        ];

        const readFAQs = [
            {question: "How are sheets in Excel Files read?", answer: "We automatically read the first sheet. Then you can read all the other sheets in the file by clicking on the Import Other Sheets option in the File Options", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/ImportSheets.png"},
            {question: "What if I don't need the first sheet read?", answer: "You can delete any sheets or files in the File Options", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/DeleteFile.png"},
            {question: "How are CSV files read?", answer: "They are automatically read with a comma delimiter. You can change the delimiter in the read options", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/CSVDelimiter.png"},
            {question: "How do you find the column names?", answer: <span>We read the first row in the file as the header. You can change that in the read options using the <b>Start at Row</b> and <b>Does File Have a Header?</b> Options</span>, img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/ReadColumns.png"},
            {question: "Can I change read options for all files?", answer: "Yes, if all the files are the same extension, i.e. .xlsx, .xls, then a Read All Files Options link will appear. Otherwise you must change the options for each file individually", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/ReadAllFileOptions.png"}
        ];

        const mergeFAQs = [
            {question: "How do you automatically append the files?", answer: "We match up the columns that have the same name and align the files", img:"https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/mergeExplain.png"},
            {question: "How do you determine the order in which the files get appended?", answer: "It's determined by the order the files are uploaded. You can change the order in the File Options dropdown", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/MoveFile.png"},
            {question: "How do you determine the order of columns in the appended file?", answer: "Each new file gets its columns added if that column name isn't there already. You can change the column order by using the buttons underneath the column name", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/MoveColumns.png"},
            {question: "Am I stuck with the column names that were in my file?", answer: "No, you can change them by clicking on the Column name in the Append File Sample", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/ChangeColumnName.png"},
            {question: "Am I stuck with the automatic placement of the columns?", answer: "No, you can switch to Detailed mode and move the columns around by clicking on the values to move first and then clicking on an empty column. You can only move the values among the same rows", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/DetailedCell.png"},
            {question: "How does the append work when more sheets or files are read?", answer: "The appended columns adjust automatically to the new sheets or files read"},
            {question: "How does the append work when sheets or files are deleted?", answer: "The appended columns adjust automatically to the sheets and files that have been deleted"},
            {question: "I accidentally deleted a column that shouldn't have been deleted! Help!", answer: "Sorry you must start over again"},
        ];

        const downloadFAQs = [
            {question: "What format is the appended file downloaded in?", answer: "The file is in .xlsx format"},
            {question: "What does the remove duplicates option do?", answer: "For all identical records found after the file has been merged, only the first record gets kept. The Append File Sample does NOT reflect this. It only affects the final file."},
            {question: "What does the source file option do?", answer: "It adds a column named File_Name that adds the name of the file to each record. You can see this in the Append File Sample"},
        ];

        const joinFAQs = [
            {question: "How does the join process work?", answer: "After you upload your files, you need to select your left file and your right file and the join type. Then select the column names that you want to join on and click Join Names", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/Join.png"},
            {question: "How are the final column names determined?", answer: <span>Column names that are identical and joined on get all their values in one column. Column names that are identical otherwise get either a _left or _right added to them depending on which file they came from. See <span onClick={this.openJoinColumnModal} className="linkStyle">example</span></span>},
            {question: "What are the different join types?", answer: <span>They are inner, left, right and full. You can check out examples <span onClick={this.toggleJoinModal} className="linkStyle">here</span>. If you want to learn more about joins, check out this <a href="https://www.geeksforgeeks.org/sql-join-set-1-inner-left-right-and-full-joins/" target="_blank" rel="noopener noreferrer">website</a></span>},
            {question: "How does joining on multiple columns work?", answer: "Click the + button on the select column prompt and then select all the columns you want to join on", img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/AddJoinColumn.png"},
            {question: "My sample is blank. Does that mean there are no matching values?", answer: "No, the sample is produced with only the first 25 lines of each files. You should click on the download button to see if there are any full matches"},
            {question: "My column names are not right. What should I do?", answer: <span>We read the first row in the file as the header. You can change that in the read options using the <b>Start at Row</b>, and <b>Does File Have a Header?</b> Options</span>, img: "https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/FAQ/ReadColumns.png"},
        ];

        const downloadJoinFAQs = [
            {question: "What format is the joined file downloaded in?", answer: "The file is in .xlsx format"},
            {question: "What does the remove duplicates option do?", answer: "For all identical records found after the file has been merged, only the first record gets kept. The Join File Sample does NOT reflect this. It only affects the final file."},
        ];

        const topicFAQs = {
            upload: {title: "Upload", icon: "upload", faq: uploadFAQs},
            read: {title: "Read", icon: "book", faq: readFAQs},
            append: {title: "Append", icon: "th", faq: mergeFAQs},
            downloadAppend: {title: "Download Append", icon: "download", faq: downloadFAQs},
            join: {title: "Join", icon: "th list", faq: joinFAQs},
            downloadJoin: {title: "Download Join", icon: "download", faq: downloadJoinFAQs}
        }

        const topic = topicFAQs[this.props.topic];

        return (
            <div>
                <FAQBlock title={topic.title} icon={topic.icon} FAQs={topic.faq}/>
            </div>
        )
    }
}

const mapActionsToProps = {
    toggleModal: toggleModal
}

export default connect(null, mapActionsToProps)(HelpDisplay)