import React, {Component} from 'react'
import { Table } from 'semantic-ui-react'

class SampleCell extends Component {
    render() {
        return (
            <Table.Cell>{this.props.value}</Table.Cell>
        )
    }
}

export default SampleCell