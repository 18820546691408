import React, {Component} from 'react'
import { Header, Button, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {toggleModal} from "../../Actions/ToggleModal";
import {push} from "react-router-redux";

class CompletedCelebration extends Component {
    constructor(props){
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(e){
        this.props.toggleModal(null);
        this.props.navigateTo("/");
    }

    render() {
        const emojiStyle = {fontSize: '250%'};
        return (
            <Segment raised>
            <div style={{textAlign: 'center'}}>
                <br/>
                <Header as='h1'>
                    Congratulations On Becoming a Pro Member!
                    <br/>
                    <Header.Subheader style={{marginTop: '20px'}}>
                        <span role="img" aria-label="smile" style={emojiStyle}>😀</span>
                        <span role="img" aria-label="confetti" style={emojiStyle}>🎉</span>
                        <span role="img" aria-label="heart" style={emojiStyle}>❤️</span>
                        <span role="img" aria-label="beer" style={emojiStyle}>🍻</span>
                        <span role="img" aria-label="poppers" style={emojiStyle}>🎊</span>
                    </Header.Subheader>
                </Header>
                <br/>
                <Header as='h1'>
                    You Can Now Merge As Many Spreadsheets As You Want!
                </Header>
                <Button icon="right arrow" labelPosition="right" content="Proceed" color="green" onClick={this.closeModal} />
                <br/><br/>
            </div>
            </Segment>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(CompletedCelebration)