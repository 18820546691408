import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'

class NoColumnNames extends Component {
    render() {
        return (
            <div>
                <p className="landingExplainDesc">By default the first row is read as the column names</p>
                <br/>
                <h3 className="thinHeading">1. You can specify if the file has column names</h3>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/HeaderChange.gif" centered />*/}
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/HeaderChange.gif" style={{width: '100%'}} alt="append no column names"/>
                <br/><br/>
                <h3 className="thinHeading">2. Or you can specify if the column names exist on a different row</h3>
            </div>
        )
    }
}

export default NoColumnNames