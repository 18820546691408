import React, {Component} from 'react'
import { MobileView } from 'react-device-detect'
import {isEmbed} from "../Constants/isEmbed";

class LandingPageHero extends Component {
    render() {
        if (isEmbed(window.location.href)){
            return null
        } else {
            return (
                <section className="heroSection">
                    <div className="row">
                        <div className="col-sm-12">
                            <div style={{textAlign: 'center'}}>
                                <MobileView>
                                    <br/>
                                </MobileView>
                                <h1 className="thinHeading">Merge Spreadsheets</h1>
                                <h4 className="thinHeading text-muted">Automatically combine multiple spreadsheets</h4>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }
}

export default LandingPageHero