import React, {Component} from 'react'
import { connect } from 'react-redux'
import API_Root from "../../Constants/API_Root";
import { toggleModal } from "../../Actions/ToggleModal";
import constants from "../../Constants/constants";
import {toggleProBlock} from "../../Actions/ToggleProBlock";
import FileDrop from 'react-file-drop';
import {modifyFilePK} from "../../Actions/ModifyFilePK";
import copyArray from "../../Constants/copyArray";
import {modifyStatus} from "../../Actions/ModifyStatus";
import {modifyFileInformation} from "../../Actions/ModifyFileInformation";
import {modifyFinalColumnNames} from "../../Actions/ModifyFinalColumnNames";
import {modifyFinalColumnPK} from "../../Actions/ModifyFinalColumnPK";
import copyObject from "../../Constants/copyObject";
import {modifySheetRead} from "../../Actions/ModifySheetRead";
import bytesToMB from "../../Constants/bytesToMB";
import UploadFilesLabel from "./UploadFilesLabel";
import UploadFilesExplain from "../../App/ExplainModals/UploadFilesExplain";
import {modifyAllInformation} from "../../Actions/ModifyAllInformation";
import {isUserPro} from "../../Constants/isUserPro";
import TutorialPopup from "../../TutorialSteps/TutorialPopup";
import {toggleShowTutorial} from "../../Actions/ToggleShowTutorial";
import {modifyTutorialStep} from "../../Actions/ModifyTutorialStep";
import IsSampleCorrect from "../../FileDnD/IsSampleCorrect";

class UploadFilesIndex extends Component {
    constructor(props) {
        super(props);

        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.getStatusAndResults = this.getStatusAndResults.bind(this);
        this.contactUs = this.contactUs.bind(this);


    }

    contactUs(e){
        this.contactLink.click();
    }

    handleFileUpload(droppedFiles, e){
        // If files are being dropped vs. uploaded
        const files = droppedFiles === null ? e.target.files : droppedFiles;

        if (files.length > 0){
            let fileSize = 0;
            let fileNames = [];

            const formData = new FormData();
            for (let i=0; i<files.length; i++){
                formData.append("file" + i.toString(), files[i]);
                fileSize += bytesToMB(files[i].size)
                fileNames.push(files[i].name)
            }

            formData.append("email", localStorage.getItem("email"));
            formData.append("token", localStorage.getItem("token"));

            //
            //
            // UPPER LIMIT DOES NOT EXIST CURRENTLY
            //
            //

            // if(fileSize < 250){
            this.props.modifyOneMBMessage(false);

            this.props.modifyStatus("reading");

            fetch(API_Root + 'api/save-merge-files/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: formData
            }).then(res => res.json())
                .then(data => {
                    if (!data.error){
                        // Modify the File Primary Keys
                        let filePK = data.filePK;

                        if (this.props.filePK.length > 0){
                            filePK = copyArray(this.props.filePK, data.filePK);
                        }

                        this.props.modifyStatus("saved");
                        this.props.modifyFilePK(filePK);

                        // Set True or False if User can work with this size of files
                        this.props.toggleProBlock(isUserPro(data.pro));

                        // Open up WebSocket when files are saved
                        this.getStatusAndResults(data.filePK);
                    }
                    else{
                        alert("There was an error reading the file. Please refresh and try again")
                    }
                }).catch((err) => {alert("There was an error reading the file. Please refresh and try again")});
        }
    }

    getStatusAndResults(filePK){
        // Open up WebSockets
        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/merge-files/");

        const props = this.props;

        // Send the PKs and if any column names
        socket.onopen = function(event) {
            socket.send(JSON.stringify({
                operation: "add",
                filePK: filePK,
                finalColumnNames: props.finalColumnNames,
                finalColumnPK: props.finalColumnPK,
                sheetRead: props.sheetRead
            }));
        };

        socket.onmessage = function(event) {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            props.modifyStatus(status);

            if (status === "merged"){
                let fileInformation = reply.fileInformation;
                let finalColumnNames = reply.finalColumnNames;
                let finalColumnPK = reply.finalColumnPK;
                let sheetRead = reply.sheetRead;

                if (typeof props.fileInformation !== 'undefined'){
                    if (Object.entries(props.fileInformation).length !== 0 && props.fileInformation.constructor === Object){
                        fileInformation = copyObject(props.fileInformation, reply.fileInformation);
                        sheetRead = copyObject(props.sheetRead, reply.sheetRead);
                    }
                    try{
                        props.modifyTutorialStep(1)
                        props.toggleShowTutorial(true)

                    }catch (e) {

                    }
                }

                props.modifyAllInformation(fileInformation, finalColumnPK, finalColumnNames, sheetRead)
                props.scrollDown()
                socket.close()
            }
            else if (status === "error"){
                socket.close()
                alert("There was a problem reading your file. Please try again")
            }
            // props.detectDefects(false);
        };
    }

    render() {
        // Hides the Input Button
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        // Valid extensions for the file upload
        const acceptExtensions = constants.extensions.join(",");

        // if (this.props.tutorialStep > 1){
        //     dataTypeStyle = {display:"block"}
        // }
        // if (this.props.tutorialStep > 2){
        //
        // }


        return (
            <div>
                <div className="row">
                    <input
                        type="file"
                        name="inputFileUpload"
                        id="inputFileUpload"
                        className="form-control-file"
                        onChange={(e) => this.handleFileUpload(null, e)}
                        style={inputFileStyle}
                        multiple={true}
                        accept={acceptExtensions}
                    />
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        <div className="uploadOuterSection">
                            <label id="uploadInnerSection" className="uploadInnerSection" htmlFor="inputFileUpload">
                                <FileDrop draggingOverTargetClassName="lightgray" onDrop={this.handleFileUpload}>
                                    <UploadFilesLabel status={this.props.status}
                                                      fileType="append"
                                    />
                                </FileDrop>
                            </label>
                        </div>
                        <UploadFilesExplain/>
                    </div>
                    <div className="col-sm-1" />
                    <TutorialPopup />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    finalColumnNames: state.mainState.finalColumnNames,
    fileInformation: state.mainState.fileInformation,
    finalColumnPK: state.mainState.finalColumnPK,
    status: state.mainState.status,
    sheetRead: state.mainState.sheetRead,
    tutorialStep: state.mainState.tutorialStep
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    toggleProBlock: toggleProBlock,
    modifyFilePK: modifyFilePK,
    modifyStatus: modifyStatus,
    modifyFileInformation: modifyFileInformation,
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFinalColumnPK: modifyFinalColumnPK,
    modifySheetRead: modifySheetRead,
    modifyAllInformation: modifyAllInformation,
    toggleShowTutorial: toggleShowTutorial,
    modifyTutorialStep: modifyTutorialStep
};

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesIndex)