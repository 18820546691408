import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Dropdown, Icon } from 'semantic-ui-react'
import {modifyJoinColumns} from "../../Actions/Join/ModifyJoinColumns";
import {modifyJoinTutorialStep} from "../../Actions/Join/ModifyJoinTutorialStep";

class ColumnSelectRow extends Component {
    constructor(props) {
        super(props);

        this.changeColumnFile1 = this.changeColumnFile1.bind(this);
        this.changeColumnFile2 = this.changeColumnFile2.bind(this);
        this.removeColumnRow = this.removeColumnRow.bind(this);
    }

    changeColumnFile1(event, {value}){
        const file1SelectedColumns = JSON.parse(JSON.stringify(this.props.joinColumns['file1']));
        const file2SelectedColumns = JSON.parse(JSON.stringify(this.props.joinColumns['file2']));

        file1SelectedColumns[this.props.idx] = value;
        this.props.modifyJoinColumns({"file1": file1SelectedColumns, "file2": file2SelectedColumns})
    }

    changeColumnFile2(event, {value}){
        const file1SelectedColumns = JSON.parse(JSON.stringify(this.props.joinColumns['file1']));
        const file2SelectedColumns = JSON.parse(JSON.stringify(this.props.joinColumns['file2']));

        file2SelectedColumns[this.props.idx] = value;
        this.props.modifyJoinColumns({"file1": file1SelectedColumns, "file2": file2SelectedColumns})

        this.props.modifyJoinTutorialStep(5)


    }

    removeColumnRow(e){
        const file1SelectedColumns = JSON.parse(JSON.stringify(this.props.joinColumns['file1']));
        const file2SelectedColumns = JSON.parse(JSON.stringify(this.props.joinColumns['file2']));

        file1SelectedColumns.splice(this.props.idx, 1);
        file2SelectedColumns.splice(this.props.idx, 1);

        this.props.modifyJoinColumns({"file1": file1SelectedColumns, "file2": file2SelectedColumns})
    }

    render() {
        let removeIcon = null;

        if (this.props.selectedLen > 1){
            removeIcon =
                <span>
                <small>Remove Column Pair</small>
                <br/>
                <Icon style={{color: 'red', cursor: 'pointer'}} name="minus circle" onClick={this.removeColumnRow}/>
            </span>
        }

        return (
            <div className="row">
                <div className="col-sm-4">
                    <div id="columnFile1">
                        <Dropdown
                            placeholder="Select Column to Join on"
                            name="file1ColumnNames"
                            value={this.props.file1Idx}
                            options={this.props.file1ColumnOptions}
                            onChange={this.changeColumnFile1}
                            selection
                        />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div id="removeColumnRow">
                        {removeIcon}
                    </div>
                </div>
                <div className="col-sm-4">
                    <div id="columnFile2">
                        <Dropdown
                            placeholder="Select Column to Join on"
                            name="file2ColumnNames"
                            value={this.props.file2Idx}
                            options={this.props.file2ColumnOptions}
                            onChange={this.changeColumnFile2}
                            selection
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    joinColumns: state.joinState.joinColumns
})

const mapActionsToProps = {
    modifyJoinColumns: modifyJoinColumns,
    modifyJoinTutorialStep: modifyJoinTutorialStep
}

export default connect(mapStateToProps, mapActionsToProps)(ColumnSelectRow)