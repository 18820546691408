import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react';
import {modifyFilePK} from "../../../Actions/ModifyFilePK";

class MoveFile extends Component {
    constructor(props) {
        super(props);

        this.moveFile = this.moveFile.bind(this);
    }

    moveFile(e, move){
        const copiedFilePK = JSON.parse(JSON.stringify(this.props.filePK));
        const currFileObj = copiedFilePK[this.props.idx];

        let moveIdx;

        if (move === "down"){
            moveIdx = this.props.idx + 1;
        }
        else{
            moveIdx = this.props.idx - 1;
        }

        const moveCol = copiedFilePK[moveIdx];

        copiedFilePK[moveIdx] = currFileObj;
        copiedFilePK[this.props.idx] = moveCol;

        this.props.modifyFilePK(copiedFilePK);
    }


    render() {
        const upButton = this.props.idx === 0 ? null :
            <div className="fileOptions">
                <Button basic icon='caret up' content="Move File Up" color="green" onClick={(e) => this.moveFile(e, "up")} />
            </div>;
        const downButton = this.props.idx + 1 === this.props.filePK.length ? null :
            <div className="fileOptions">
                <Button basic icon='caret down' content="Move File Down" color="orange" onClick={(e) => this.moveFile(e, "down")} />
            </div>;

        return (
            <div>
                {upButton}
                {downButton}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK
})

const mapActionsToProps = {
    modifyFilePK: modifyFilePK
}

export default connect(mapStateToProps, mapActionsToProps)(MoveFile)