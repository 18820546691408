import React, {Component} from 'react'

class FullTutorial extends Component {
    render() {
        return (
            <div>
                <p className="landingExplainDesc">Watch a Full Demo and Explainer from Us</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/frTa6Bgefaw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
            </div>
        )
    }
}

export default FullTutorial