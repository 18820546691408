import React, {Component} from 'react'
import { Button, Accordion, Icon } from 'semantic-ui-react';

class HelpBody extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <br/>
                <h5 style={{color: 'gray'}}><i>There are a few things that could be going on, but mostly they come back to the tool needing to read the appropriate column names to append</i></h5>
                <br/>
                <h5>1. Your column names are not on the first line of the file or your file doesn't have column names</h5>
                <div>
                    <ul>
                        <li>Click on <Button basic color='blue' icon='edit outline' content="Read File Options" /> in <Icon name='dropdown' /> File Options</li>
                        <li>Select which row to <b>Start</b> reading the files on if your file has column names on another line</li>
                        <li>DeSelect the <b>Does File Have a Header?</b> checkbox if your file does not have a header</li>
                    </ul>
                    <br/>
                    <i>NOTE: if all your files are the same extension, e.g., .xlsx, .txt, .csv and are the same structure, e.g., column names start on row 3 then you can click on</i> <span className="linkStyle"><Icon name="edit outline" />Read All Files Options</span> <i>to re-read them all at once</i>
                </div>
                <br/><br/>
                <h5>2. Extra columns are being created for values that should be in the same column since your column names are not EXACTLY the same between files</h5>
                <div>
                    <ul>
                        <li>Example: File1 has a column <b>Name</b> and File2 has a column <b>Full Name</b> and you want both their values to appear in the same column</li>
                        <li>In this case, the automatic merge will not work so you have to switch to <span className="linkStyle"><Icon name="columns" /> Detailed View</span></li>
                        <li>In this view, you can move values to a different column by clicking on them and clicking on the new column you want them to appear. This way even if Columns names aren't exactly the same your values can be in the same column.</li>
                    </ul>
                </div>
                <br/><br/>
                <h5 style={{color: 'gray'}}>If you are still facing problems, you can email us at <a href="mailto:help@mergespreadsheets.com">help@mergespreadsheets.com</a>. Please include the following:</h5>
                <div>
                    <ol>
                        <li>Detailed description of your issue, along with any relevant screenshots</li>
                        <li>Files that are causing the append not to work</li>
                    </ol>
                </div>
                <br/><br/>
            </div>
        )
    }
}

export default HelpBody