import React, {Component} from 'react';
import {FacebookShareButton, EmailIcon, LinkedinShareButton, RedditShareButton, TwitterShareButton, TwitterIcon, FacebookIcon, LinkedinIcon, RedditIcon} from "react-share";

class SocialMediaButtons extends  Component {
    render() {
        return (
            <div className="footerShareGrid">
                <div className="footerEmptySpace" />
                <div className="socialMediaButton" id="twitterButton">
                    <TwitterShareButton
                        url={"twitter.com"}
                        title={"I saved so much time with mergespreadsheets.com! Check them out if you need an easy solution to combine all your spreadsheets. "} >
                        <TwitterIcon
                            size={32}
                            round />
                    </TwitterShareButton>
                </div>
                <div className="socialMediaButton" id="facebookButton">
                    <FacebookShareButton
                        url={"fb.com"}
                        title={"I saved so much time with mergespreadsheets.com! Check them out if you need an easy solution to combine all your spreadsheets. "} >
                        <FacebookIcon
                            size={32}
                            round />
                    </FacebookShareButton>
                </div>
                <div className="socialMediaButton" id="redditButton">
                    <RedditShareButton
                        url={"reddit.com"}
                        title={"I saved so much time with mergespreadsheets.com! Check them out if you need an easy solution to combine all your spreadsheets. "} >
                        <RedditIcon
                            size={32}
                            round />
                    </RedditShareButton>
                </div>
                <div className="socialMediaButton" id="linkedinButton">
                    <LinkedinShareButton
                        url={"linkedin.com"}
                        title={"I saved so much time with mergespreadsheets.com! Check them out if you need an easy solution to combine all your spreadsheets. "} >
                        <LinkedinIcon
                            size={32}
                            round />
                    </LinkedinShareButton>
                </div>
                <div className="socialMediaButton" id="emailButton">
                    <a href="mailto:info@mergespreadsheets.com?Subject=I%20Want%20To%20Merge%20Spreadsheets">
                        <EmailIcon size={32} round />
                    </a>
                </div>
            </div>
        );
    }
}


export default SocialMediaButtons