import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Root from "../Constants/API_Root";
import { Button } from 'semantic-ui-react'
import {modifyFinalColumnPK} from "../Actions/ModifyFinalColumnPK";
import {modifyStatus} from "../Actions/ModifyStatus";
import {modifyEditOptions} from "../Actions/ModifyEditOptions";
import {modifyFinalColumnNames} from "../Actions/ModifyFinalColumnNames";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {toggleModal} from "../Actions/ToggleModal";
import copyArray from "../Constants/copyArray";
import copyObject from "../Constants/copyObject";
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {modifySheetRead} from "../Actions/ModifySheetRead";

class ImportSheetsButton extends Component {
    constructor(props) {
        super(props);

        this.importSheets = this.importSheets.bind(this);
    }

    importSheets(){
        // Check if there is any error
        let importSheets = false;
        let idxImport = [];

        for (let sheetIdx in this.props.sheetsChecked){
            if (this.props.sheetsChecked[sheetIdx]){
                importSheets = true;
                idxImport.push(parseInt(sheetIdx))
            }
        }

        if (importSheets){
            const props = this.props;

            // Close the Import Sheets Modal
            props.toggleModal(null);
            // Open up the import sheets loading modal
            props.toggleModal('loadingModalImportSheets');

            // Open up WebSockets
            const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
            const socket = new WebSocket(websocketRoute + "ws/api/merge-files/");

            let filePK = [];
            const sheetRead = this.props.sheetRead;

            // Create PKs for these sheets
            for (let i=0; i<idxImport.length; i++){
                const idx = idxImport[i];
                const id = this.props.pk.toString() + "_" + this.props.sheetNames[idx];

                filePK.push({id: id, pk: this.props.pk})
                sheetRead[id] = this.props.sheetNames[idx];
            }

            // Update the PKs for these sheets
            const filePKExtend = copyArray(this.props.filePK, filePK);

            this.props.modifyFilePK(filePKExtend);

            // Send the PKs and if any column names
            socket.onopen = function(event) {
                socket.send(JSON.stringify({
                    operation: "add",
                    filePK: filePK,
                    finalColumnNames: props.finalColumnNames,
                    finalColumnPK: props.finalColumnPK,
                    sheetRead: sheetRead
                }));
            };

            socket.onmessage = function(event) {
                const reply = JSON.parse(event.data);
                const status = reply['status'];

                props.modifyStatus(status);

                if (status === "merged"){

                    let fileInformation = reply.fileInformation;
                    let finalColumnNames = reply.finalColumnNames;
                    let finalColumnPK = reply.finalColumnPK;
                    let sheetRead = reply.sheetRead;

                    if (typeof props.fileInformation !== 'undefined'){
                        if (Object.entries(props.fileInformation).length !== 0 && props.fileInformation.constructor === Object){
                            fileInformation = copyObject(fileInformation, props.fileInformation);
                            sheetRead = copyObject(props.sheetRead, reply.sheetRead);
                        }
                    }

                    props.modifyFinalColumnNames(finalColumnNames);
                    props.modifyFinalColumnPK(finalColumnPK);
                    props.modifyFileInformation(fileInformation);
                    props.modifySheetRead(sheetRead);

                    socket.close()
                    // Close the loading modal
                    props.toggleModal(null);
                }
                else if (status === "error"){
                    socket.close()
                    props.toggleModal(null);
                    alert("There was a problem importing your sheets. Please try again")
                }
            };
        }
    }

    render() {
        return (
            <Button disabled={this.props.btnDisabled} color="blue" icon="plus" content="Import Sheets" onClick={this.importSheets}/>
        )
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK,
    fileInformation: state.mainState.fileInformation,
    filePK: state.mainState.filePK,
    editID: state.mainState.editID,
    reduxEditOptions: state.mainState.editOptions,
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    modifyStatus: modifyStatus,
    modifyFinalColumnPK: modifyFinalColumnPK,
    modifyFinalColumnNames: modifyFinalColumnNames,
    modifyFileInformation: modifyFileInformation,
    modifyEditOptions: modifyEditOptions,
    modifySheetRead: modifySheetRead,
    toggleModal: toggleModal
}

export default connect(mapStateToProps, mapActionsToProps)(ImportSheetsButton)