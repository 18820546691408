export const MODIFY_ALL_INFORMATION = 'MODIFY_ALL_INFORMATION';

export function modifyAllInformation(fileInformation, finalColumnPK, finalColumnNames, sheetRead){
    return{
        type: MODIFY_ALL_INFORMATION,
        mainState: {
            fileInformation: fileInformation,
            finalColumnPK: finalColumnPK,
            finalColumnNames: finalColumnNames,
            sheetRead: sheetRead
        }
    }
}