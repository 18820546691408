import React, {Component} from 'react'
import ModalApp from '../SharedComponents/Modal'
import images from "../Constants/Images";

class MergeExplainModal extends Component{
    render() {
        const explainBody =
            <div>
                <br/><br/>
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                        <img alt="howMergeWorks" src={images.explainImage} style={{width: '98%', height: '98%'}}/>
                    </div>
                    <div className="col-sm-1" />
                </div>
                <br/><br/>
            </div>
        return (
            <ModalApp name="mergeExplainModal"
                      header="What Do We Mean By Merge?"
                      size="medium"
                      body={explainBody}
            />
        )
    }
}

export default MergeExplainModal