import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class GuidesMenuItem extends Component {
    constructor(props) {
        super(props);

        this.changeMenuItem = this.changeMenuItem.bind(this);
        this.isSelected = this.isSelected.bind(this);
    }

    changeMenuItem(e){
        this.downloadLink.click()
        // this.props.history.push(this.props.displayLink);
    }

    isSelected(){
        const url = window.location.href;
        const startIdx = url.indexOf("guides") + 7;
        const endIdx = url.charAt(url.length) === "/" ? url.length - 1 : url.length;

        return url.substring(startIdx, endIdx) === this.props.displayLink
    }

    render() {
        const isSelected = this.isSelected();
        const menuItemClassname = isSelected ? "menuItemSelected" : "";

        return (
            <div className={"guidesMenuItem " + menuItemClassname}
                 onClick={this.changeMenuItem}
                 id={this.props.displayLink}
            >
                <a href={"/guides/" + this.props.displayLink}
                   ref={downloadLink => this.downloadLink = downloadLink}
                   style={{display: 'none'}}>
                    Hidden Link to {this.props.displayLink}
                </a>
                <h1 className="guidesMenuHeader">
                    <Icon name={this.props.icon} /> {this.props.header}
                </h1>
            </div>
        )
    }
}

export default GuidesMenuItem