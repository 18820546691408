import React, {Component} from 'react'
import LandingPageHero from "./LandingPageHero";
import { isBrowser } from 'react-device-detect'
import AppIndex from '../App/AppIndex'
import MobileLandingPage from './MobileLandingPage';
import FailedPaymentMessage from "../SharedComponents/FailedPaymentMessage"
import AuthComponent from "../SharedComponents/AuthComponent";

class LandingPageIndex extends Component {
    componentDidMount(){
        document.title = "Merge Spreadsheets"
    }

    render() {
        const app = isBrowser ? <AppIndex/> : <MobileLandingPage />;
        return (
            <div>
                <AuthComponent
                    authComponent={<FailedPaymentMessage/>}
                    nonAuthComponent={null}
                />
                <LandingPageHero/>
                {app}
            </div>
        )
    }
}

export default LandingPageIndex