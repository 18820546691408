import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'

class HowToJoin extends Component {
    render() {
        return (
            <div>
                <p className="landingJoinExplainDesc">There are three steps to joining</p>
                <br/>
                <h3 className="thinHeading">1. Upload Your Files</h3>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinUpload.gif" centered style={{border: '1px lightgray solid'}} />*/}
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinUpload.gif" style={{border: '1px lightgray solid', width: '100%'}} alt="Upload to Join"/>
                <br/><br/>
                <h3 className="thinHeading">2. Select Left, Right File and Join Type</h3>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinFiles.gif" centered style={{border: '1px lightgray solid'}} />*/}
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinFiles.gif" style={{border: '1px lightgray solid', width: '100%'}} alt="Join Files to Upload"/>
                <br/><br/>
                <h3 className="thinHeading">3. Select Column(s) to Join On</h3>
                {/*<Image src="https://media.giphy.com/media/ftr0PaGeJ3kduNSUkE/giphy.gif" centered style={{border: '1px lightgray solid'}} />*/}
                <img src="https://media.giphy.com/media/ftr0PaGeJ3kduNSUkE/giphy.gif" style={{border: '1px lightgray solid', width: '100%'}} alt="Select Column Names to Join" />
                <br/><br/>
            </div>
        )
    }
}

export default HowToJoin