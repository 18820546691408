import React, {Component} from 'react'
import {connect} from 'react-redux'
import SampleCell from "./SampleCell";
import { Table } from 'semantic-ui-react';
import DetailedViewCell from './DetailedViewCell'
import FileOptions from './FileOptions/FileOptions'
import getFileWithSheetName from '../../Constants/getFileWithSheetName'

class SampleRow extends Component {
    render() {
        // const endIdx = this.props.fileInfoType === "summary" ? 1 : 5;

        let row = [];
        const fileName = this.props.fileInformation[this.props.pkID].name;
        // row.push(<SampleCell value={fileName} key={-1}/>)
        row.push(<FileOptions pkID={this.props.pkID}
                              pk={this.props.pk}
                              idx={this.props.idx}
                              fileName={fileName} key={-1}/>)

        for (let i=0; i<this.props.finalColumnNames.length; i++){
            const colNameObj = this.props.finalColumnNames[i];

            const id = colNameObj.id;

            const filePKs = this.props.finalColumnPK[id].filePK;

            const fileColIndices = this.props.finalColumnPK[id].fileColIdx;
            const fileSamples = this.props.fileInformation[this.props.pkID].samples;

            if (filePKs.includes(this.props.pkID)){
                const filePKIdx = filePKs.indexOf(this.props.pkID);
                const fileColIdx = fileColIndices[filePKIdx];

                const cellValue = fileSamples.length > 0 ? fileSamples[0][fileColIdx] : null;
                if (this.props.tableView === "detail"){
                    const colName = this.props.fileInformation[this.props.pkID].columnNames[fileColIdx];

                    row.push(<DetailedViewCell fileName={fileName}
                                               fileSamples={fileSamples}
                                               fileColIdx={fileColIdx}
                                               colName={colName}
                                               filePK={this.props.pkID}
                                               finalColIdx={i}
                                               key={i}
                                               show />)
                }
                else{
                    row.push(<SampleCell key={i} value={cellValue}/>)
                }
            }
            else{
                if (this.props.tableView === "detail"){
                    row.push(<DetailedViewCell finalColIdx={i} filePK={this.props.pkID} key={i}/>)
                }
                else{
                    row.push(<SampleCell key={i} value=""/>)
                }
            }
        }


        if (this.props.addFileNames){
            row.push(<SampleCell key={-2} value={getFileWithSheetName(fileName, this.props.sheetRead, this.props.pkID)}/>)
        }

        return (
            <Table.Row>
                {row}
            </Table.Row>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK,
    tableView: state.mainState.tableView,
    addFileNames: state.mainState.addFileNames,
    sheetRead: state.mainState.sheetRead

});


const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(SampleRow)