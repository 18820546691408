import React, {Component} from 'react'
import { Image } from 'semantic-ui-react'

class OtherFileTypes extends Component {
    render() {
        return (
            <div>
                <p className="landingJoinExplainDesc">You can import any additional Excel sheets in the File Options</p>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinImportSheets.png" centered style={{border: '1px lightgray solid'}}/>*/}
                <img src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinImportSheets.png" style={{border: '1px lightgray solid', width: '100%'}} alt="Join Import Sheets Gif"/>
                <br/><br/>
                <p className="landingJoinExplainDesc">You can change the header and other things in the Read File Options</p>
                {/*<Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinReadFileOptions.png" centered style={{border: '1px lightgray solid'}}/>*/}
                <Image src="https://project-static-assets.s3.amazonaws.com/MergeSpreadsheets/Explain/JoinReadFileOptions.png" style={{border: '1px lightgray solid', width: '100%'}} alt="Join Read File Options Gif"/>
            </div>
        )
    }
}

export default OtherFileTypes