import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Progress } from 'semantic-ui-react'

class TutorialProgressBar extends Component {
    getProgressPercentage = () => {
        const totalSteps = 3;

        return Math.ceil((this.props.tutorialStep / totalSteps) * 100)
    }

    render() {
        return (
            <div style={{display: "grid", gridTemplateColumns: "20% 80%", borderBottom: "1px solid lightgrey", padding: "0 20px 0 20px", marginBottom: "10px"}}>
                <div style={{marginTop: "-5px"}}>
                    <b>{this.getProgressPercentage()}%</b>
                </div>
                <div style={{marginBottom: "0", paddingBottom: "0"}}>
                    <Progress percent={this.getProgressPercentage()} inverted color='blue' />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tutorialStep: state.mainState.tutorialStep
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(TutorialProgressBar)