import React, {Component} from 'react'

class FullTutorial extends Component {
    render() {
        return (
            <div>
                <p className="landingJoinExplainDesc">Joining can sometimes be hard to understand. Here are some more resources to help (ignore the SQL code)</p>
                <h5 className="thinHeading">1. <a href="https://simple.wikipedia.org/wiki/Join_(SQL)" target="_blank" rel="noopener noreferrer">Wikipedia (short length)</a></h5>
                <h5 className="thinHeading">2. <a href="https://www.geeksforgeeks.org/sql-join-set-1-inner-left-right-and-full-joins/" target="_blank" rel="noopener noreferrer">Geeks for Geeks Blog (medium length)</a></h5>
                <h5 className="thinHeading">3. <a href="http://www.sql-join.com/sql-join-types" target="_blank" rel="noopener noreferrer">SQL Join Website (long length)</a></h5>
                <br/><br/>
                <p className="landingJoinExplainDesc">Or watch a Full Demo and Explainer from Us</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/fOfZiQSF6So" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        )
    }
}

export default FullTutorial