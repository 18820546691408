import React, {Component} from 'react'
import HelpMenu from "./HelpMenu"
import HelpDisplay from "./HelpDisplay"

class HelpIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            topic: 'upload'
        }

        this.changeHelpMenu = this.changeHelpMenu.bind(this);
    }

    componentDidMount(){
        document.title = "FAQ - Merge Spreadsheets"
    }

    changeHelpMenu(topic){
        this.setState({
            topic: topic
        })
    }

    render() {
        return (
            <div id="helpIndex">
                <div id="aboutHero">
                    <h1>FAQs</h1>
                    <p>If you have any questions not addressed here, you can <a href="mailto:info@mergespreadsheets.com?Subject=Question%20with%20Merge%20Spreadsheets" style={{color: 'lightblue'}}>email us</a></p>
                </div>
                <br/>
                <div className="row">
                    <div className="col-sm-3">
                        <HelpMenu changeHelpMenu={this.changeHelpMenu} topic={this.state.topic} />
                    </div>
                    <div className="col-sm-9">
                        <div style={{paddingLeft: '30px', paddingRight: '30px'}}>
                            <HelpDisplay topic={this.state.topic} />
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}

export default HelpIndex