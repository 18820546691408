import React, {Component} from 'react'
import { Input, Button, Form, Message, TextArea, Dropdown } from 'semantic-ui-react'
import API_Root from "../../Constants/API_Root";

class SupportForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            subject: '',
            softwareType: 'macOS',
            message: '',
            phone: '',
            confirmMessage: null,
            submitting: false
        }

        this.submitContactForm = this.submitContactForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.changeSoftwareType = this.changeSoftwareType.bind(this);
    }

    changeSoftwareType(event, {value}){
        this.setState({
            softwareType: value
        })
    }

    submitContactForm(){
        this.setState({
            submitting: true
        })
        fetch(API_Root + 'api/merge-support/', {
            method: "POST",
            body: JSON.stringify({
                email: this.state.email,
                phone: this.state.phone,
                subject: this.state.subject,
                softwareType: this.state.softwareType,
                message: this.state.message
            }),
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}
        }).then(
            (res) => {
                console.log(res);
                if (res.status === 200){
                    console.log("hello")
                    this.setState({
                        confirmMessage: <Message
                            success
                            header='Thank you for submitting your support request!'
                            content='We will be reaching out to you shortly'
                        />,
                        submitting: false
                    })
                }
                else{
                    this.setState({
                        confirmMessage: <Message
                            error
                            header='There was an error submitting your request :('
                            content='Please try again'
                        />,
                        submitting: false
                    })
                }
            }).catch(err => this.setState({
            confirmMessage: <Message
                error
                header='There was an error submitting your request :('
                content='Please try again'
            />,
            submitting: false
        }))
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "subject"){
            if (value.length <= 140){
                this.setState({
                    [name]: value
                });
            }
        }
        else if (name === "message"){
            if (value.length <= 5000){
                this.setState({
                    [name]: value
                });
            }
        }
        else{
            this.setState({
                [name]: value
            });
        }

    }

    // email = request_body['email']
    // phone = request_body['phone']
    // subject = request_body['subject']
    // software_type = request_body['softwareType']
    // message = request_body['message']

    render() {
        const subjectLeft = (140 - this.state.subject.length).toString();
        const messageLeft = (5000 - this.state.message.length).toString();


        const emailError = this.state.email.trim() === "" ? "Please enter your email" : null;
        const subjectError = this.state.subject.trim() === "" ? "Please enter your issue" : null;

        const softwareOptions = [
            {key: 1, text: 'Mac OS', value: 'macOS'},
            {key: 2, text: 'Windows', value: 'windows'},
            {key: 3, text: 'Linux', value: 'linux'},
            {key: 4, text: 'Website', value: 'web'},
        ];

        const confirmMessage = this.state.confirmMessage;
        return (
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <div style={{margin: '0 auto', width: '500px', textAlign: 'center'}}>
                        <br/>
                        <h3 className="thinHeading">Submit the form below if you need any help</h3>
                        <h5 className="thinHeading">We will get back to you asap</h5>
                        <br/>
                        <Form>
                            <div style={{width: '300px', margin: '0 auto'}}>
                                <Input icon='envelope'
                                       name="email"
                                       iconPosition='left'
                                       placeholder='Enter your email...'
                                       onChange={this.handleInputChange}
                                       value={this.state.email}
                                       fluid
                                />
                                <small style={{float: 'left', color: 'red'}}>{emailError}</small>
                                <br/>
                                <Input icon='phone'
                                       name="phone"
                                       iconPosition='left'
                                       placeholder='Your Phone Number (optional)'
                                       onChange={this.handleInputChange}
                                       value={this.state.phone}
                                       fluid
                                />
                                <br/>
                                <Dropdown
                                    placeholder="Select Software Type"
                                    name="softwareType"
                                    value={this.state.softwareType}
                                    options={softwareOptions}
                                    onChange={this.changeSoftwareType}
                                    selection
                                    fluid
                                />
                                <br/>
                            </div>
                            <Input icon='pencil'
                                   name="subject"
                                   iconPosition='left'
                                   placeholder='Briefly tell us your issue...'
                                   onChange={this.handleInputChange}
                                   value={this.state.subject}
                                   fluid
                            />
                            <small style={{float: 'left', color: 'red'}}>{subjectError}</small>
                            <small style={{float: 'right', color: 'gray'}}>{subjectLeft}</small>
                            <br/>
                            <TextArea
                                rows={10}
                                placeholder='Tell us your issue in more detail'
                                name="message"
                                value={this.state.message}
                                onChange={this.handleInputChange}
                            />
                            <small style={{float: 'right', color: 'gray'}}>{messageLeft}</small>
                            <br/><br/>
                            <Button color="orange"
                                    icon="paper plane outline"
                                    content="Submit"
                                    disabled={this.state.email.trim() === "" || this.state.subject.trim() === ""}
                                    onClick={this.submitContactForm}
                                    loading={this.state.submitting}
                            />
                            <br/><br/>
                        </Form>
                        {confirmMessage}
                        <br/><br/><br/><br/><br/><br/><br/><br/>
                    </div>
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default SupportForm