import React, {Component} from 'react'

class UploadFilesExplain extends Component {
    render() {
        return (
            <small><i>You can select files with any of these extensions: .xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .csv, .txt</i></small>
        )
    }
}

export default UploadFilesExplain