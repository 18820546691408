import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table } from 'semantic-ui-react'
import JoinFileHeader from './JoinFileHeader'
import Loader from 'react-loader-spinner'


class JoinPreview extends Component {
    render() {
        if (this.props.joinPreview === null){
            return (
                null
            )
        }
        else{
            const header = this.props.joinPreview[0].map((x, idx) => <Table.HeaderCell key={idx}>{x}</Table.HeaderCell>)

            const previewValues = this.props.joinPreview.slice(1, this.props.joinPreview.length);

            let tableRows = [];

            for (let row=0; row<previewValues.length; row++){
                let cellValues = [];
                let previewRow = previewValues[row];

                for (let col=0; col<previewRow.length; col++){
                    cellValues.push(<Table.Cell key={row.toString() + col.toString()}>{previewRow[col]}</Table.Cell>)
                }

                tableRows.push(<Table.Row key={row.toString()}>
                    {cellValues}
                </Table.Row>)
            }

            const emptyDesc = previewValues.length === 0 ? <p style={{color: 'red', textAlign: 'center'}}><i>The blank sample below is produced using only the first 25 rows. Download the File to see if the Join actually produces a blank or not. </i><br /></p> : null

            let body =
                <div>
                    <JoinFileHeader />
                    {emptyDesc}
                    <div id="uploadFilesSampleTableContainer">
                        <div id="uploadFilesSampleTable">
                            <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        {header}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {tableRows}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

            if (this.props.isJoining) {
                body =
                    <div style={{textAlign:'center',padding:'10px'}}>
                        <Loader
                        type="TailSpin"
                        color="black"
                        height="100"
                        width="100"
                        />
                    </div>
            }



            return (
                <div style={{
                    backgroundColor: '#F0F0F0',
                    border: '1px solid #F0F0F0',
                    borderRadius: '5px',
                    marginLeft: "2%",
                    marginRight: "2%"}}>

                    {body}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    joinPreview: state.joinState.joinPreview,
    isJoining: state.joinState.isJoining
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(JoinPreview)