import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import {modifyJoinFilePK} from "../../../Actions/Join/ModifyJoinFilePK";
import {modifyJoinFileInformation} from "../../../Actions/Join/ModifyJoinFileInformation";
import {modifyJoinSheetRead} from "../../../Actions/Join/ModifyJoinSheetRead";
import {modifyJoinPkId} from "../../../Actions/Join/ModifyJoinPkId";
import {modifyJoinColumns} from "../../../Actions/Join/ModifyJoinColumns";
import {modifyJoinPreview} from "../../../Actions/Join/ModifyJoinPreview";

class RemoveFile extends Component {
    constructor(props) {
        super(props);

        this.removeFile = this.removeFile.bind(this);
        this.deleteSheetsRead = this.deleteSheetsRead.bind(this);

        this.removeFromJoin = this.removeFromJoin.bind(this);
    }

    removeFile(){
        const pkID = this.props.pkID;
        const pk = this.props.pk;

        const isExcel = this.props.isExcel;

        // Figure out whether to delete the file from the server or not and delete any sheets read
        if (isExcel){
            // Delete from sheets read
            this.deleteSheetsRead(pk, pkID)
        }

        // See if files have been selected for Joins
        if (this.props.joinPkId.includes(pkID)){
            this.removeFromJoin(pkID)
        }

        // Delete the idx of filePK and update
        const removedFilePK = JSON.parse(JSON.stringify(this.props.joinFilePK));
        removedFilePK.splice(this.props.idx, 1);

        this.props.modifyJoinFilePK(removedFilePK);

        // Delete the file information associated to this
        const copiedFileInformation = JSON.parse(JSON.stringify(this.props.joinFileInformation));

        delete copiedFileInformation[pkID];

        this.props.modifyJoinFileInformation(copiedFileInformation);


    }

    removeFromJoin(pkID){
        const joinPkId = JSON.parse(JSON.stringify(this.props.joinPkId));
        let fileName;

        for (let i=0; i<joinPkId.length; i++){
            if (joinPkId[i] === pkID){
                joinPkId[i] = "-" + (i+1).toString()
                fileName = "file" + (i+1).toString()
            }
        }

        this.props.modifyJoinPkId(joinPkId);

        const joinColumns = JSON.parse(JSON.stringify(this.props.joinColumns));
        joinColumns[fileName] = [0]

        this.props.modifyJoinColumns(joinColumns);
        this.props.modifyJoinPreview(null);
    }

    deleteSheetsRead(pk, pkID){
        const removedSheets = JSON.parse(JSON.stringify(this.props.joinSheetRead));
        const sheetName = removedSheets[pkID];

        delete removedSheets[pkID];

        const pkSheets = removedSheets[pk];

        pkSheets.splice(pkSheets.indexOf(sheetName), 1);

        removedSheets[pk] = pkSheets;

        this.props.modifyJoinSheetRead(removedSheets);

    }

    render() {
        return (
            <Popup
                trigger={
                    <Button fluid basic color='red' icon='trash alternate outline' content="Delete File"/>
                }
                content={
                    <div style={{textAlign: 'center'}}>
                        <h5 className="thinHeading">Are you sure you want to delete this file?</h5>
                        <Button color='red' content='Delete File' onClick={this.removeFile} />
                    </div>}
                on='click'
                position='top right'
            />
        )
    }
}

const mapStateToProps = (state) => ({
    joinFileInformation: state.joinState.joinFileInformation,
    joinFilePK: state.joinState.joinFilePK,
    joinSheetRead: state.joinState.joinSheetRead,
    joinPkId: state.joinState.joinPkId,
    joinColumns: state.joinState.joinColumns
})

const mapActionsToProps = {
    modifyJoinFilePK: modifyJoinFilePK,
    modifyJoinFileInformation: modifyJoinFileInformation,
    modifyJoinSheetRead: modifyJoinSheetRead,
    modifyJoinPkId: modifyJoinPkId,
    modifyJoinColumns: modifyJoinColumns,
    modifyJoinPreview: modifyJoinPreview
}

export default connect(mapStateToProps, mapActionsToProps)(RemoveFile)