import React, {Component} from 'react'
import ToggleMergeButton from "./ToggleMergeButton";
import {connect} from 'react-redux'
import ResetState from './ResetState'
import MergeTypeExplainRow from './MergeTypeExplainRow'

class ToggleMerge extends Component {
    render() {
        let appendReset = null;

        if (this.props.filePK.length > 0){
            if (this.props.mergeType === "append"){
                appendReset =
                    <div style={{float: 'left'}}>
                        <ResetState />
                    </div>
            }
        }

        let joinReset = null;

        if (this.props.joinFilePK.length > 0){
            if (this.props.mergeType === "join"){
                joinReset =
                    <div style={{float: 'right'}}>
                        <ResetState />
                    </div>
            }
        }

        return (
            <div style={{marginBottom: '20px'}}>
                <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-5">
                        <div className="row">
                            <div className="col-sm-1" />
                            <div className="col-sm-5">
                                {appendReset}
                            </div>
                            <div className="col-sm-5">
                                <ToggleMergeButton
                                    mergeType="append"
                                />
                            </div>
                            <div className="col-sm-1" />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div>
                        <ToggleMergeButton
                            mergeType="join"
                        />
                        </div>
                    </div>
                    <div className="col-sm-2">
                        {joinReset}
                    </div>
                    <div className="col-sm-1" />
                </div>
                <div style={{marginTop: '4px'}}>
                    <MergeTypeExplainRow />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    mergeType: state.mainState.mergeType,
    filePK: state.mainState.filePK,
    joinFilePK: state.joinState.joinFilePK
})


export default connect(mapStateToProps, )(ToggleMerge)