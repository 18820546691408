import React, {Component} from 'react'
import {connect} from 'react-redux'
import {modifyFinalColumnNames} from "../../Actions/ModifyFinalColumnNames";

class ColumnNameEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnName: this.props.finalColumnNames[this.props.idx]['colName']
        }

        this.modifyColumnName = this.modifyColumnName.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.updateColumnName = this.updateColumnName.bind(this);
    }

    componentWillMount(){
        document.addEventListener('mousedown', this.handleClick, false);
        document.addEventListener('keydown', this.handleEnter, false);
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClick, false);
        document.removeEventListener('keydown', this.handleEnter, false);
    }

    modifyColumnName(e){
        this.setState({
            columnName: e.target.value
        })
    }

    handleClick(e){
        if (!this.node.contains(e.target)){
            this.updateColumnName()
        }
        else{
            this.modifyColumnName(e)
        }
    }

    handleEnter(e){
        if (e.key === "Enter"){
            this.updateColumnName()
        }
    }

    updateColumnName(){
        const copiedFinalNames = JSON.parse(JSON.stringify(this.props.finalColumnNames));
        const columnObj = copiedFinalNames[this.props.idx];
        columnObj['colName'] = this.state.columnName;

        copiedFinalNames[this.props.idx] = columnObj;

        this.props.modifyFinalColumnNames(copiedFinalNames);
        this.props.toggle(null);

    }
    render() {
        return (
            <input ref={node => this.node = node} onChange={this.modifyColumnName} value={this.state.columnName} type="text" />
        )
    }
}

const mapStateToProps = (state) => ({
    finalColumnNames: state.mainState.finalColumnNames
});

const mapActionsToProps = {
    modifyFinalColumnNames: modifyFinalColumnNames
};

export default connect(mapStateToProps, mapActionsToProps)(ColumnNameEdit)