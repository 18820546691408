import React, {Component} from 'react'
import { connect } from 'react-redux'
import { toggleModal } from "../../Actions/ToggleModal";
import constants from "../../Constants/constants";
import FileDrop from 'react-file-drop';
import copyArray from "../../Constants/copyArray";
import copyObject from "../../Constants/copyObject";
import UploadFilesLabel from "../../Append/UploadFiles/UploadFilesLabel";
import {modifyJoinStatus} from "../../Actions/Join/ModifyJoinStatus";
import {modifyJoinFilePK} from "../../Actions/Join/ModifyJoinFilePK";
import {modifyJoinFileInformation} from "../../Actions/Join/ModifyJoinFileInformation";
import {modifyJoinSheetRead} from "../../Actions/Join/ModifyJoinSheetRead";
import {modifyAllJoinInformation} from "../../Actions/Join/ModifyAllJoinInformation";
import UploadFilesExplain from "../../App/ExplainModals/UploadFilesExplain";
import bytesToMB from "../../Constants/bytesToMB";
import API_Root from "../../Constants/API_Root";
import {toggleProBlock} from "../../Actions/ToggleProBlock";
import {isUserPro} from "../../Constants/isUserPro";
import JoinTutorialPopup from "../JoinTutorialSteps/JoinTutorialPopup"
import {toggleShowJoinTutorial} from "../../Actions/Join/ToggleShowJoinTutorial";
import {modifyJoinTutorialStep} from "../../Actions/Join/ModifyJoinTutorialStep"

class UploadFilesIndex extends Component {
    constructor(props) {
        super(props);

        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.getStatusAndResults = this.getStatusAndResults.bind(this);
        this.contactUs = this.contactUs.bind(this);

    }

    contactUs(e){
        this.contactLink.click();
    }

    handleFileUpload(droppedFiles, e){
        // If files are being dropped vs. uploaded
        const files = droppedFiles === null ? e.target.files : droppedFiles;

        if (files.length > 0){
            let fileSize = 0;
            let fileNames = [];

            const formData = new FormData();
            for (let i=0; i<files.length; i++){
                formData.append("file" + i.toString(), files[i]);
                fileSize += bytesToMB(files[i].size)
                fileNames.push(files[i].name)
            }

            formData.append("email", localStorage.getItem("email"));
            formData.append("token", localStorage.getItem("token"));

            //
            //
            // UPPER LIMIT DOES NOT EXIST CURRENTLY
            //
            //

            // if(fileSize < 250) {
            this.props.modifyOneMBMessage(false);

            this.props.modifyJoinStatus("reading");

            fetch(API_Root + 'api/save-merge-files/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: formData
            }).then(res => res.json())
                .then(data => {
                    if (!data.error){
                        // Modify the File Primary Keys
                        let filePK = data.filePK;

                        if (this.props.joinFilePK.length > 0){
                            filePK = copyArray(this.props.joinFilePK, data.filePK);
                        }

                        this.props.modifyJoinStatus("saved");
                        this.props.modifyJoinFilePK(filePK);

                        // Set True or False if User can work with this size of files
                        this.props.toggleProBlock(isUserPro(data.pro));

                        // Open up WebSocket when files are saved
                        this.getStatusAndResults(data.filePK);
                    }
                    else{
                        alert("There was an error reading the file. Please refresh and try again");
                    }
                }).catch(err => alert("There was an error reading the file. Please refresh and try again"));
            // } else {
            //     const profileData = new FormData();
            //
            //     profileData.append("email", localStorage.getItem("email"));
            //     profileData.append("token", localStorage.getItem("token"));
            //
            //     fetch(API_Root + 'api/is-merge-user-pro-route/', {
            //         method: 'POST',
            //         headers: {
            //             'Accept': 'application/json, text/plain, */*',
            //         },
            //         body: profileData
            //     }).then(res => res.json()).then(
            //         data => {
            //             this.props.toggleProBlock(data.pro)
            //             this.props.modifyFileNames(fileNames)
            //             this.props.modifyOneMBMessage(true);
            //         }
            //     ).catch(err => alert("There was an error reading the file. Please refresh and try again"))
            // }
        }
    }


    getStatusAndResults(filePK){
        // Open up WebSockets
        const websocketRoute = API_Root.substring(0, 5) === "https" ? API_Root.replace("https", "wss") : API_Root.replace("http", "ws");
        const socket = new WebSocket(websocketRoute + "ws/api/join-files/");

        // Send the PKs and if any column names
        socket.onopen = (event) => {
            socket.send(JSON.stringify({
                filePK: filePK,
                sheetRead: this.props.sheetRead
            }));
        };

        socket.onmessage = (event) => {
            const reply = JSON.parse(event.data);
            const status = reply['status'];

            this.props.modifyJoinStatus(status);

            if (status === "merged"){
                let fileInformation = JSON.parse(JSON.stringify(reply.fileInformation));
                let sheetRead = JSON.parse(JSON.stringify(reply.sheetRead));
                alert("Your files were successfully uploaded! Scroll down to select which to join. :) ")

                if (typeof this.props.joinFileInformation !== 'undefined'){
                    if (Object.entries(this.props.joinFileInformation).length !== 0 && this.props.joinFileInformation.constructor === Object){
                        fileInformation = copyObject(this.props.joinFileInformation, reply.fileInformation);
                        sheetRead = copyObject(this.props.joinSheetRead, reply.sheetRead);
                    }
                    try{
                        if (this.props.joinFilePK.length > 1){
                            this.props.modifyJoinTutorialStep(1)
                        }
                        this.props.toggleShowJoinTutorial(true)
                    }catch (e) {

                    }
                }

                this.props.modifyAllJoinInformation(fileInformation, sheetRead)
                this.props.scrollDown()
                socket.close()
            }
            else if (status === "error"){
                socket.close()
                alert("There was a problem reading your file. Please try again")
            }
        };

    }

    render() {
        // Hides the Input Button
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        // Valid extensions for the file upload
        const acceptExtensions = constants.extensions.join(",");

        // Components for what status the job is in

        return (
            <div className="row" style={{marginLeft: '1px'}}>
                <input
                    type="file"
                    name="inputFileUpload"
                    id="inputFileUpload"
                    className="form-control-file"
                    onChange={(e) => this.handleFileUpload(null, e)}
                    style={inputFileStyle}
                    multiple={true}
                    accept={acceptExtensions}
                />
                {/*<div className="col-sm-1" />*/}
                <div className="col-sm-10">
                    <div className="uploadOuterSection" style={{backgroundColor: '#1b6ad0'}}>
                        <label id="uploadInnerSection" className="uploadInnerSection" htmlFor="inputFileUpload" style={{backgroundColor: '#3178d4'}}>
                            <FileDrop draggingOverTargetClassName="lightgray" onDrop={this.handleFileUpload}>
                                <UploadFilesLabel status={this.props.status}
                                                  fileType="join"
                                />
                            </FileDrop>
                        </label>
                    </div>
                    <UploadFilesExplain/>
                </div>
                <div className="col-sm-2" />
                <JoinTutorialPopup />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    joinFilePK: state.joinState.joinFilePK,
    joinFileInformation: state.joinState.joinFileInformation,
    joinSheetRead: state.joinState.joinSheetRead,
    status: state.joinState.joinStatus,
    sheetRead: state.joinState.joinSheetRead,
    editOptions: state.joinState.joinEditOptions,
    joinTutorialStep: state.mainState.joinTutorialStep
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyJoinFilePK: modifyJoinFilePK,
    modifyJoinStatus: modifyJoinStatus,
    modifyJoinFileInformation: modifyJoinFileInformation,
    modifyJoinSheetRead: modifyJoinSheetRead,
    modifyAllJoinInformation: modifyAllJoinInformation,
    toggleProBlock: toggleProBlock,
    toggleShowJoinTutorial: toggleShowJoinTutorial,
    modifyJoinTutorialStep: modifyJoinTutorialStep
};

export default connect(mapStateToProps, mapActionsToProps)(UploadFilesIndex)