import React, { Component } from 'react';
import NavbarIndex from './SharedComponents/Navbar/NavbarIndex';
import Routes from './Routes';
import withAuthentication from './Authentication/withAuthentication';
import FooterIndex from './SharedComponents/Footer/FooterIndex'
import FeedbackModal from './SharedComponents/Feedback/FeedbackModal'
import LoadingModal from './SharedComponents/LoadingModal'
import EditModal from './EditFile/EditModal'
import ImportSheetsModal from './ImportSheets/ImportSheetsModal'
import SignUpModal from "./Authentication/UserPages/SignUp/SignUpModal";
import LoginModal from "./Authentication/UserPages/LogIn/LoginModal";
import ForgetPasswordModal from "./Authentication/UserPages/Password/ForgetPasswordModal";
import ProfileModal from "./InfoPages/Profile/ProfileModal";
import MergeExplainModal from "./InfoPages/MergeExplainModal";
import SupportModal from "./SharedComponents/SupportModal";
import AppendExplainModal from './App/ExplainModals/AppendExplainModal'
import JoinExplainModal from './App/ExplainModals/JoinExplainModal'
import JoinImportSheetsModal from './Join/ImportSheets/ImportSheetsModal'
import JoinEditModal from './Join/EditFile/EditModal'
import HelpModal from './Append/HelpModal/HelpModal'
import ImageModal from './InfoPages/Help/HelpImageModal/ImageModal'
import JoinColumnModal from "./App/ExplainModals/JoinColumnModal";
import PreviewModal from "./Join/OpenPreview/PreviewModal";
import NoModal from "./FileDnD/NoModal";
import LovePromoModal from "./SharedComponents/LovePromoModal";
import LovePromoBanner from "./SharedComponents/LovePromoBanner";

class AppAuthentication extends Component {

    render() {
        return (
            <div>
                <FeedbackModal/>
                <LovePromoModal/>
                <ImportSheetsModal/>
                <EditModal />
                <LoadingModal name="loadingModalFile" header="Preparing your Merged File" message="Preparing your Merged File" noClose/>
                <LoadingModal name="loadingModalImportSheets" header="Reading..." message="Reading Your Additional Sheets" noClose/>
                <LoadingModal name="loadingModalEditFile" header="Re-reading your files..." message="Re-Reading Files with Options" noClose/>
                <SignUpModal/>
                <LoginModal/>
                <ForgetPasswordModal/>
                <ProfileModal/>
                <MergeExplainModal/>
                <SupportModal/>
                <AppendExplainModal />
                <JoinExplainModal />
                <JoinImportSheetsModal/>
                <JoinEditModal />
                <NoModal/>
                <HelpModal />
                <ImageModal />
                <JoinColumnModal/>
                <PreviewModal/>
                <div>
                    <LovePromoBanner/>
                    <NavbarIndex/>
                    <Routes/>
                    <FooterIndex/>
                </div>
            </div>
        );
    }
}


export default withAuthentication(AppAuthentication)
