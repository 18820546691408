import React, {Component} from 'react'
import CommentSection from "./CommentSection";

class SQLJoin extends Component {
    render() {
        return (
            <div>
                <h1>What does a SQL Join mean?</h1>
                    <p>A SQL join describes the process of merging rows in two different tables or files together.</p>
                    <img src="https://miro.medium.com/max/700/1*1U4-pT7IbAc6SJjeDbo6Ig.png"
                         alt="SQLJoin"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p>Rows of data are combined based on values in a selected column.</p>
                    <p>In the example above, this is the <b>Item</b> column.</p>
                    <p>An <b>Item,</b> <i>Apple,</i> has both a price and a quantity, but in different tables. </p>
                    <p>Using a SQL join, these values become merged into one row!</p>
                <h1>So why do I need an entire article about this?</h1>
                    <p>In most cases, joining data won’t be as simple as the previous example. Oftentimes, we’ll have rows that can’t be joined because there isn’t the same value in the joining column for both tables.</p>
                    <p>For instance, what if there wasn’t a quantity for apple in the example above? How we handle what we do with rows like this depends on the type of SQL Join we choose.</p>
                    <img src="https://miro.medium.com/max/700/1*DaLyKk3fhD_uz9HRocyWpA.png"
                         alt="SQLJoin"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <p>There are four main types of joins: inner join, left join, right join, and full join. In this article, I’ll explain to you what each type entails.
                    </p>
                    <img src="https://miro.medium.com/max/700/1*ZYCxupdz7nC0bLiwm8_3ZA.png"
                         alt="SQLJoin_Examples"
                         style={{margin: '0 auto', display: 'block'}}
                    />
                    <br/>
                    <div style={{borderLeft: '5px solid', paddingLeft: '15px'}}>
                        <p><i>Before going into everything, I think it’d be nice to mention that <a href="https://www.mergespreadsheets.com/">Merge Spreadsheets</a>  is an easy to use tool that will easily perform all of this joining for you.</i></p>
                        <p><i>You can experiment with your spreadsheets to learn how these joins will look.</i></p>
                    </div>
                    <br/>
                    <h3>What is an inner join?</h3>
                        <p>An inner join results in a table with rows where the values in the joining column are in both tables.</p>
                        <p>To better understand this, let’s take a look at this example being joined on the item column:</p>
                        <img src="https://miro.medium.com/max/700/1*dofRqfiWaKGy27UFaTlBVQ.png"
                             alt="InnerJoinSQL"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p>Both of the starting tables have a lot of different items, but only Apple and Orange are in both. So, the final result will only include Apple and Orange with their price and quantity values merged into the same row.</p>
                    <h3>What is a left join?</h3>
                        <p>A left join will include all of the rows in the <i>left</i> table (file one), regardless of if they’re in the right table.</p>
                        <p>Let’s take a look at the same tables as before, also being merged on the item column:</p>
                        <img src="https://miro.medium.com/max/700/1*dofRqfiWaKGy27UFaTlBVQ.png"
                             alt="Left Join SQL"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p>Like with inner join, the final product of this join includes Apple and Orange. However, it also includes all the other rows from file one, even if they don’t have quantity values (those cells will be left blank)!</p>
                    <h3>What is a right join?</h3>
                        <p>A right join is the exact opposite of a left join; it includes all the values in the <i>right</i> table (file two) regardless of what’s in the first table.</p>
                        <img src="https://miro.medium.com/max/700/1*fpSyH1ZRF2kZUdRM9k_NOA.png"
                             alt="Right Join SQL"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p>So, in the same example as before, this will include Apple and Orange while also including any other rows in file two!</p>
                    <h3>What is a full join?</h3>
                        <p>A full join will combine all the data from both spreadsheets while merging the rows that can be merged.</p>
                        <p>Full join is definitely the way to go if you don’t want to exclude any data in your final result.</p>
                        <p>As always, using the same two tables as before will yield a different result when using a full join.</p>
                        <img src="https://miro.medium.com/max/700/1*C0uedxMhXXU0CkEa8p0vNg.png"
                             alt="Code"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p>All the rows from both tables are included and Apple and Orange become merged (as those are the only common items)!</p>
                <h1>Interesting, so does this get more complicated?</h1>
                    <p>It gets a <i>little</i> more complicated. There are a couple special cases that I’ll go into so that you’ll have a better understanding of how joining works!</p>
                    <h3>What if I have duplicates in my data?</h3>
                        <p>If you have duplicates in one table, they’ll also show up in your joined table depending on the join type.</p>
                        <p>If there is a single row in one file with the same value in the joining column as multiple rows in the second file, those data in that single row will be repeated for each instance.</p>
                        <p>For example, let’s look at the tables below being <b>Inner</b> joined on the <b>Item</b> column. In file one, there are two <b>Orange</b> rows while there’s only one in file two.</p>
                        <img src="https://miro.medium.com/max/700/1*wZe5QOYP05ZQil6TOsSvlg.png"
                             alt="Inner-Join-Duplicates-Example"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p>Joining this will include both of the unique rows from file one while repeating the data for quantity from file two!</p>
                        <div style={{borderLeft: '5px solid', paddingLeft: '15px'}}>
                            <p><i>By the way, a <b>LEFT</b> join would also produce the same result</i></p>
                        </div>
                    <h3>Can I join with multiple columns in common?</h3>
                        <p>Yes! If you want to join a table based on multiple columns, each grouping will be classified as its own unique entity.</p>
                        <p>For instance, in the example below, we’ll be <b>Full</b> joining on the <b>Item</b> and <b>Price</b> columns.</p>
                        <img src="https://miro.medium.com/max/700/1*f3bYFbFdfJSQAknju-Q0Zg.png"
                             alt="FullJoin_SQL"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p>The <b>Apple</b> row is the only one that’s merged because it’s the only one with the same <b>Item</b> and <b>Price</b> in both tables. </p>
                        <p>Instead of both of the <b>Orange</b> rows being merged, they are treated as different rows because their price values are different.</p>
                        <p>So, everything is the same as before but now we look for the same values in two columns rather than just one.</p>
                    <h3>What if I have a blank value in my common column?</h3>
                        <p>Each blank value in the column you’re joining on will be treated like any normal value. In other words, you can join on a blank value as usual.</p>
                        <img src="https://miro.medium.com/max/700/1*x1Fy9Fi8zF4eXhBGIhk-2g.png"
                             alt="SQL Join Blank Values"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p>For example, in the tables above, there are blank values in both files in the item column. These will be treated as the same string and become joined! In the case of the example above, all types of joining will result in the same product.</p>
                        <br/>
                        <div style={{border: '1px solid lightgrey',backgroundColor:'lightGrey', padding: '10px'}}>
                            <p><b>NOTE:</b> While a blank value will be treated as any other string, a NULL value is different. NULL values denote nothing and are typically used in code. Each NULL value is treated as a unique entity and can’t be joined on. For more information, check out <a href="https://www.akadia.com/services/sqlsrv_join_tables.html"> this article.</a></p>
                        </div>
                        <br/>
                        <p>Let’s also take a look at a more comprehensive example with all of these special cases in it.</p>
                        <p>We will be performing a <b>Left</b> join on the <b>Item</b> and <b>Price</b> columns.</p>
                        <img src="https://miro.medium.com/max/700/1*90BRCcPUwVpnRxmb5LhviQ.png"
                             alt="Left Join Special Cases"
                             style={{margin: '0 auto', display: 'block'}}
                        />
                        <br/>
                        <p>When performing a <b>left</b> join, all the rows from the left file will be in the final product.</p>
                        <p>The Apple, $1 row is in both files, so the ID# and quantity for them will be merge.</p>
                        <p>Orange, $2 is only in the left file, so its quantity will remain blank.</p>
                        <p>Finally, we have a blank/blank row in the left file and two blank/blank rows in the second file. This means the the ID # for blank/blank in file one will be repeated twice to go with each quantity value in the second table!</p>
                        <p>And the fully blank row at the bottom will be ignored!</p>
                <h1>So… have we covered everything?</h1>
                <p>Yep! We just went over all the basics of SQL joins so you should be able to join tables with no problems now.</p>
                <p>Feel free to experiment with your data in spreadsheets here on <a href="https://www.mergespreadsheets.com/"> Merge Spreadsheets</a> because there is nothing like trying this out to really understand it.</p>
                <p>If you still have questions, don’t hesitate to reach out to us at <a href="mailto:info@lovespreadsheets.com">info@lovespreadsheets.com!</a></p>
                <p>Want to learn more about how to Merge Spreadsheets? Check out our ultimate guide <a href="/guides/UltimateGuide">here!</a></p>
                <CommentSection commentDiv={<div className="fb-comments" data-href="https://www.mergespreadsheets.com/guides/SQL-Join" data-numposts="5" data-width="" />}/>
            </div>
        )
    }
}
export default SQLJoin