function totalFileSize(fileInformation){
    let totalSize = 0;
    let filePKRead = [];

    for (let fileObj in fileInformation){
        const fileInfo = fileInformation[fileObj]
        if (!filePKRead.includes(fileInfo.pk)){
            totalSize += fileInfo.size;
            filePKRead.push(fileInfo.pk);
        }
    }

    return totalSize
}


export default totalFileSize