import React, {Component} from 'react'
import UploadFilesIndex from '../Append/UploadFiles/UploadFilesIndex'
import FileInformationTable from '../FileDnD/FileInformationTable'
import DownloadFileIndex from '../DownloadFile/DownloadFileIndex'
import DownloadAppMessage from './DownloadApp/DownloadAppMessage'
import { Icon } from 'semantic-ui-react'

class AppendIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            oneMBMessage: false,
            fileNames: [],
            showSampleArrow: false,
            isFileAppended: false
        }

        this.modifyOneMBMessage = this.modifyOneMBMessage.bind(this);
        this.modifyFileNames = this.modifyFileNames.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleFileAppended = this.toggleFileAppended.bind(this);
        this.isTableInViewport = this.isTableInViewport.bind(this);
        this.scrollDown = this.scrollDown.bind(this);
    }

    modifyOneMBMessage(oneMBMessage){
        this.setState({
            oneMBMessage: oneMBMessage
        })
    }

    modifyFileNames(fileNames){
        this.setState({
            fileNames: fileNames
        })
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    toggleFileAppended(isFileAppended){
        this.setState({
            isFileAppended: isFileAppended
        })
    }

    isTableInViewport(){
        const tableExist = document.getElementsByClassName("appendFileSampleTableHeader").length !== 0;

        if (tableExist){
            const tableElement = document.getElementsByClassName("appendFileSampleTableHeader")[0];
            const bounding = tableElement.getBoundingClientRect();

            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            )

        }
        else{
            return false
        }

    }

    handleScroll(){
        const isTableInViewport = this.isTableInViewport()

        if (!isTableInViewport && this.state.isFileAppended){
            this.setState({
                showSampleArrow: true
            })
        }
        else{
            this.setState({
                showSampleArrow: false
            })
        }
    }

    scrollDown(){
        this.node.scrollIntoView({behavior: 'smooth'})
    }

    scrollToDownload = () => {
        this.downloadBox.scrollIntoView({behavior: 'smooth'})
    }


    render() {
        const uploadAdj = !this.state.oneMBMessage  ?
            <div ref={downloadBox => this.downloadBox = downloadBox}>
                <DownloadFileIndex />
            </div> :
            <div ref={downloadBox => this.downloadBox = downloadBox}>
                <DownloadAppMessage fileNames={this.state.fileNames} modifyOneMBMessage={this.modifyOneMBMessage}/>
            </div>
        // const sampleArrow = this.state.showSampleArrow ? <button className="sampleBelowButton" onClick={this.scrollDown}>See Append Sample <Icon name="arrow down" /></button> : null;
        // const isTableInViewport = this.isTableInViewport();

        let sampleArrow = null;
        // if ((!isTableInViewport && this.state.isFileAppended) || (this.state.showSampleArrow && this.state.isFileAppended)){
        //     sampleArrow = <button className="sampleBelowButton" onClick={this.scrollDown}>See Append Sample <Icon name="arrow down" /></button>
        // }

        return (
            <div className="app">
                <br/><br/>
                <div style={{marginBottom: '50px'}}>
                    <div className="row">
                        <div className="col-sm-1" />
                        <div className="col-sm-5">
                            <UploadFilesIndex
                                scrollDown={this.scrollDown}
                                modifyOneMBMessage={this.modifyOneMBMessage}
                                modifyFileNames={this.modifyFileNames}/>
                        </div>
                        <div className="col-sm-5">
                            {uploadAdj}
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div style={{textAlign: 'center', zIndex: '50', marginTop: '-30px'}}>
                        {sampleArrow}
                    </div>
                </div>
                <br/><br/>
                <div style={{marginBottom: '200px'}}>
                    <div ref={node => this.node = node}>
                        <FileInformationTable
                            toggleFileAppended={this.toggleFileAppended}
                            scrollToDownload={this.scrollToDownload} />
                    </div>
                </div>
                <br/><br/>
                <br/><br/>
                <br/><br/>
            </div>
        )
    }
}

export default AppendIndex