import React, {Component, useState} from 'react'
import ModalApp from '../SharedComponents/Modal'
import NoModalBody from "./NoModalBody";


class NoModal extends Component{
    render() {
        return (
            <ModalApp name="noModal"
                      header ="Tips for Appending Correctly"
                      size="large"
                      body={<NoModalBody/>}
            />
        )
    }
}

export default NoModal