import API_Root from "./API_Root";

export const isUserPro = (pricingObject) => {
    console.log(pricingObject)
    let userPro = false;

    if ('pricingPlan' in pricingObject){
        if (pricingObject['pricingPlan'] === "pro"){
            if ('subscription_id' in pricingObject){
                if ('cancel' in pricingObject){
                    if (!pricingObject['cancel']){
                        userPro = true
                    }
                    // else{
                    //     fetch(API_Root + "api/get-stripe-subscription-info/" + pricingObject['subscription_id'] + "/")
                    //         .then(res => {
                    //             if (res.status === 200){
                    //                 res.json().then(
                    //                     data => {
                    //                         console.log(data)
                    //                         if (data['status'] === "active"){
                    //                             userPro = true
                    //                         }
                    //                     }
                    //                 ).catch(err => userPro = true)
                    //             } else {
                    //                 userPro = true
                    //             }
                    //         }).catch(err => userPro = true)
                        // if ("utcDateJoined" in pricingObject){
                        //     // let utcDateJoined = new Date(pricingObject['utcDateJoined'])
                        //     // const currDate = new Date();
                        //     //
                        //     // utcDateJoined.setMonth(currDate.getMonth())
                        //     // utcDateJoined.setMonth(utcDateJoined.getMonth() + 1)
                        //
                        //
                        // } else {
                        //     userPro = true
                        // }
                        //
                        // // const endDateInt = pricingObject.currentPeriodEnd;
                        // // const endDate = new Date(parseInt(endDateInt, 10) * 1000)
                        //
                        //
                        //
                        // // if date
                        // if (endDate > currDate){
                        //     userPro = true
                        // }
                    // }
                } else{
                    userPro = true
                }
            }  else{
                userPro = true
            }
        }
    }

    return userPro
}