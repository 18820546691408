import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'

class SlideCounter extends Component {
    render() {
        let circles = [];

        for (let i=0; i<this.props.totalNumber; i++){
            if (i === this.props.activeFeature){
                circles.push(<Icon name="circle" key={i}/>)
            }
            else{
                circles.push(<Icon name="circle outline" key={i}/>)
            }
        }

        return (
            <div style={{textAlign: 'center', fontSize: '50%', paddingTop: '15px', paddingBottom: '50px'}}>
                {circles}
            </div>
        )
    }
}

export default SlideCounter