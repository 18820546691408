import React, {Component} from 'react'
import { connect } from 'react-redux'
import FinalColumnNames from './FileSamples/FinalColumnNames'
import SampleRow from './FileSamples/SampleRow'
import {Table, Icon, Button} from 'semantic-ui-react'
import {modifyFilePK} from "../Actions/ModifyFilePK";
import {toggleModal} from "../Actions/ToggleModal";
import {modifyEditID} from "../Actions/ModifyEditID";
import getExtension from "../Constants/getExtension";
import {toggleTableView} from "../Actions/ToggleTableView";
import IsSampleCorrect from "./IsSampleCorrect";


class FileInformationTable extends Component {
    constructor(props){
        super(props);

        this.editAll = this.editAll.bind(this);
        this.possibleToEditAll = this.possibleToEditAll.bind(this);
        this.changeTableView = this.changeTableView.bind(this);
        this.helpAppend = this.helpAppend.bind(this);
    }

    helpAppend(){
        this.props.toggleModal("appendHelpModal")
    }

    editAll(e){
        this.props.toggleModal("editModal");
        this.props.modifyEditID("all");
    }

    possibleToEditAll(fileNames){
        let possible = true;

        if (fileNames.length > 1 ){
            let extension = getExtension(fileNames[0]);

            for (let i=1; i<fileNames.length; i++){
                let extensionCurr = getExtension(fileNames[i]);

                if (extension !== extensionCurr){
                    possible = false;
                    break;
                }
            }
        }
        else{
            possible = false;
        }

        return possible
    }

    changeTableView(e, tableView){
        this.props.toggleTableView(tableView);
    }

    scrollToDownload = () =>{
        this.props.scrollToDownload()
    }
    render() {
        let table = null;

        if (isTableReady(this.props.filePK, this.props.fileInformation, this.props.finalColumnNames, this.props.finalColumnPK)){
            // Change file appended to true
            this.props.toggleFileAppended(true);

            let fileDropSources = [];
            // Get file names to check whether all extensions are the same
            let fileNames = [];

            for (let i=0; i<this.props.filePK.length; i++){
                const pkID = this.props.filePK[i]['id'];
                const pk = this.props.filePK[i]['pk'];

                if (pk in this.props.fileInformation || pkID in this.props.fileInformation){
                    fileDropSources.push(
                        <SampleRow  key={pk.toString() + i.toString()}
                                    fileInfoType={this.props.fileInfoType}
                                    pkID={pkID}
                                    pk={pk}
                                    idx={i}
                        />
                    )

                    fileNames.push(this.props.fileInformation[pkID].name);
                }

            }

            const suffix = this.props.finalColumnNames.length > 1 ? "s" : "";
            let tableToggle = null;
            let iconName = null;
            let switchName = null;

            if (this.props.tableView === "general"){
                switchName = "detail";
                iconName = "columns";
                tableToggle = " Detailed View"
            }
            else if (this.props.tableView === "detail"){
                switchName = "general";
                iconName = "table";
                tableToggle = " Sample View"
            }

            const editAllOptions = this.possibleToEditAll(fileNames) ?
                <Button color="facebook" onClick={this.editAll}><Icon name="edit outline" />Read All Files Options</Button> : null;

            let sampleFile;
            let detailedRow;
            let sampleRows;

            if (this.props.tableView === "general"){
                sampleFile = "Sample View of Final Appended File"
                detailedRow = null;
                sampleRows = "only first row"
            }
            else{
                sampleFile = "Detailed View - Move Columns Around"
                detailedRow = null;
                sampleRows = "first 5 rows"
            }

            const header =
                <div>
                    <div className="row" style={{padding:'1%'}}>
                        <div className="col-sm-1"/>
                        <div className="col-sm-4">
                            <div className="appendFileSampleTableHeader"
                                 id="appendFileSampleTableHeader"
                                 class="ui raised segment">
                                <h4 className="thinHeading" style={{textAlign:'center',paddingBottom:'3px'}}> Appended File Sample</h4>
                                <h6 className="thinHeading" style={{marginTop: '-5px', marginBottom: '-1px',textAlign:'center',paddingBottom:'3px'}}>{sampleFile}</h6>
                                <span><p><small style={{backgroundColor:'#EA7186',color:'white',padding:'4px',borderRadius:'2px' }}><b>{this.props.finalColumnNames.length.toString() + " column" + suffix}</b></small>
                                    {detailedRow} <i><b>{" Showing " + sampleRows + " from each file"}</b></i></p>
                            </span>
                            </div>
                        </div>
                        <div className="col-sm-2"/>
                        <div className="col-sm-4">
                            <IsSampleCorrect scrollToDownload={this.scrollToDownload}/>
                        </div>
                        <div className="col-sm-1"/>
                    </div>



                </div>




            const fileTable =
                <div id="uploadFilesSampleTableContainer" style={{padding:'0 1% 1% 1%'}}>
                    <div id="uploadFilesSampleTable">
                        <div style={{textAlign: 'left', width: '40%'}}>
                            <Button color="violet" onClick={(e) => this.changeTableView(e, switchName)}>
                                <Icon name={iconName}/> {tableToggle}
                            </Button>
                            {editAllOptions}
                        </div>
                        <Table definition celled>
                            <Table.Header>
                                <FinalColumnNames edit={false} />
                                <FinalColumnNames edit={true} />
                            </Table.Header>
                            <Table.Body>
                                {fileDropSources}
                            </Table.Body>
                        </Table>
                    </div>
                </div>;


            table =
                <div style={{
                    backgroundColor: '#e9eef2',
                    border: '1px solid #e9eef2',
                    borderRadius: '5px',
                    marginLeft: '2%',
                    marginRight: '2%'
                }}
                >
                    {header}
                    {fileTable}
                </div>
        }
        else{
            this.props.toggleFileAppended(false);
        }


        return (
            <div>
                {table}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filePK: state.mainState.filePK,
    fileInformation: state.mainState.fileInformation,
    finalColumnNames: state.mainState.finalColumnNames,
    finalColumnPK: state.mainState.finalColumnPK,
    tableView: state.mainState.tableView
});

const mapActionsToProps = {
    modifyFilePK: modifyFilePK,
    toggleModal: toggleModal,
    modifyEditID: modifyEditID,
    toggleTableView: toggleTableView
};

function isTableReady(filePK, fileInformation, columnNames, columnPK){
    let isReady = false;

    if (Object.entries(fileInformation).length !== 0
        && fileInformation.constructor === Object) {

        if (columnNames.length === Object.keys(columnPK).length) {
            isReady = true
        }
    }

    return isReady
}
export default connect(mapStateToProps, mapActionsToProps)(FileInformationTable)