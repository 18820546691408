import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Table, Accordion, Icon, Divider, Button } from 'semantic-ui-react'
import MoveFile from './MoveFile'
import ImportSheets from './ImportSheets'
import {toggleModal} from "../../../Actions/ToggleModal";
import {modifyEditID} from "../../../Actions/ModifyEditID";
import RemoveFile from "./RemoveFile";

class FileOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1
        }

        this.handleAccordionClick = this.handleAccordionClick.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
    }

    handleAccordionClick(e, titleProps){
        const { index } = titleProps
        const activeIndex  = this.state.activeIndex;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex })
    }

    openEditModal(){
        this.props.toggleModal("editModal")
        this.props.modifyEditID(this.props.pkID)
        // this.props.toggleModal("directEditModal")
    }

    render() {
        const activeIndex = this.state.activeIndex;
        const fileName = this.props.fileName;

        const isExcel = fileName.split('.').pop() !== "csv" && fileName.split('.').pop() !== "txt";

        const sheetName = isExcel ? <span><br/><small><i>{this.props.sheetRead[this.props.pkID]}</i></small></span> : null;
        const importSheets = isExcel ? <ImportSheets idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} /> : null;
        const importSheetsDivider = isExcel ? <Divider/> : null;

        return (
            <Table.Cell>
                {fileName}
                {sheetName}
                <br/><br/>
                <Accordion fluid styled>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.handleAccordionClick}>
                        <Icon name='dropdown' />
                        File Options
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        {importSheets}
                        {importSheetsDivider}
                        <div className="fileOptions">
                            <Button basic color='blue' icon='edit outline' content="File Options" onClick={this.openEditModal} />
                        </div>
                        <div className="fileOptions">
                            <RemoveFile idx={this.props.idx} pkID={this.props.pkID} pk={this.props.pk} isExcel={isExcel} />
                        </div>
                        <Divider/>
                        <MoveFile idx={this.props.idx}/>
                    </Accordion.Content>
                </Accordion>
            </Table.Cell>
        )
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    sheetRead: state.mainState.sheetRead
})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyEditID: modifyEditID
}

export default connect(mapStateToProps, mapActionsToProps)(FileOptions)